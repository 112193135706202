import React, { useState } from "react";
import { Container, Grid, Box, Typography } from "@mui/material";
import { Auth } from "aws-amplify";
import { useNavigate, Link } from "react-router-dom";
import TitleLabel from "../components/titleLabel";
import Logo from "../components/logo";
import FooterLabel from "../components/footerLabel";
import TextInputField from "../components/inputField";
import ErrorMessage from "../components/errorMessage";
import PrimaryButton from "../components/primaryTextButton";
import genaiLogo from "../assets/genaiLogo.svg";
import emailIcon from "../assets/emailIcon.svg";
import InputAdornment from "@mui/material/InputAdornment";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [cognitoError, setCognitoError] = useState("");

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setEmailError("");
  };

  const forgotPassword = async () => {
    let expression = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    let emailCheck = expression.test(email);

    if (email === "") {
      setEmailError("Please enter your email id");
    } else {
      if (!emailCheck) {
        setEmailError("Enter valid email id");
      } else {
        try {
          await Auth.forgotPassword(email);
          localStorage.setItem("email", email);
          navigate("/resetpassword");
        } catch (error) {
          if (error.code === "UserNotFoundException") {
            setCognitoError(
              "Please enter the registered email id to change the password."
            );
          } else {
            setCognitoError(error.message || "An error occurred");
          }
          console.error(error);
        }
      }
    }
  };

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      sx={{ minHeight: "100vh" }}
    >
      <div style={{ marginTop: "4rem" }}>
        <Logo />
      </div>

      <TitleLabel fontWeight={600} sx={{ mt: "3rem" }}>
        Forgot Password
      </TitleLabel>
      <Typography
        variant="body2"
        style={{
          marginBottom: "1.5rem",
          marginLeft: "12px",
          marginRight: "12px",
          textAlign: "center",
        }}
      >
        Enter your email id to receive an OTP for a new password.
      </Typography>
      <Container maxWidth="xs">
        <TextInputField
          sx={{
            marginTop: "8px",
            marginBottom: emailError || cognitoError ? "0px" : "24px",
          }}
          fullWidth={true}
          placeholder="Enter your email id"
          type="email"
          value={email}
          onChange={handleEmailChange}
          endAdornment={
            <InputAdornment position="end">
              <img
                src={emailIcon}
                alt="Email Icon"
                style={{ width: 20, height: 20 }}
              />
            </InputAdornment>
          }
        />
        {emailError && <ErrorMessage message={emailError} />}
        {cognitoError && <ErrorMessage message={cognitoError} />}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "1.563rem",
          }}
        >
          <Link
            to="/"
            variant="body2"
            style={{
              textDecoration: "none",
              fontSize: "1rem",
              fontWeight: "600",
              color: "#174FB6",
              fontFamily: "Mulish",
            }}
          >
            Back
          </Link>
          <PrimaryButton type="button" onClick={forgotPassword}>
            Send
          </PrimaryButton>
        </div>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          sx={{ mt: "6rem", mb: "3rem" }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <FooterLabel
              fontWeight={400}
              fontSize={16}
              sx={{ display: "inline" }}
            >
              Powered By
            </FooterLabel>
            <Box
              component="img"
              src={genaiLogo}
              width="200px"
              height="40px"
              sx={{ display: "inline", ml: "10px" }}
            />
          </Box>
        </Grid>
      </Container>
    </Grid>
  );
};

export default ForgotPassword;
