import React, { useState } from "react";
import { Container, Grid, Box } from "@mui/material";
import { Auth } from "aws-amplify";
import { useNavigate, Link } from "react-router-dom";
import TitleLabel from "../components/titleLabel";
import Logo from "../components/logo";
import TextLabel from "../components/textLabel";
import FooterLabel from "../components/footerLabel";
import TextInputField from "../components/inputField";
import ErrorMessage from "../components/errorMessage";
import PrimaryButton from "../components/primaryTextButton";
import IconButton from "@mui/material/IconButton";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import InputAdornment from "@mui/material/InputAdornment";
import genaiLogo from "../assets/genaiLogo.svg";
import emailIcon from "../assets/emailIcon.svg";
import {checkUserExistence} from "../api/api";
const SignUpForm = () => {
  const navigate = useNavigate();
  const [userName, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [userNameError, setUserNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [cognitoError, setCognitoError] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleNameChange = (e) => {
    setUserName(e.target.value);
    setUserNameError("");
  };

  const handleEmailChange = (e) => {
    const { value } = e.target;
    setEmail(value);
    if (value !== "") {
      const expression = /^[\w\.-]+@[a-zA-Z\d\.-]+\.[a-zA-Z]{2,}$/;
      const isValidEmail = expression.test(value);
      setEmailError(isValidEmail ? "" : "Please enter a valid email address");
    } else {
      setEmailError("");
    }
  };

  const handlePasswordChange = (e) => {
    const { value } = e.target;
    setPassword(value);
    if (value !== "") {
      const passExp =
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/;
      const isValidPassword = passExp.test(value);
      setPasswordError(
        isValidPassword
          ? ""
          : "Password must contain at least one number, one uppercase letter, one lowercase letter, one special character, and be 8-15 characters long."
      );
    } else {
      setPasswordError("");
    }
  };

  const validateForm = async () => {
    let reg1 = /^[A-Za-z ]+$/;
    let isValid = reg1.test(userName);
    let expression = /^[\w\.-]+@[a-zA-Z\d\.-]+\.[a-zA-Z]{2,}$/;
    let emailCheck = expression.test(email);
    let passExp =
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/;
    let passwordCheck = passExp.test(password);

    if (userName === "") {
      setUserNameError("Please enter your Username");
    } else {
      if (!isValid) {
        setUserNameError("Enter alphabets and numbers ");
      } else {
        setUserNameError("");
      }
    }

    if (email === "") {
      setEmailError("Please enter a valid e-mail address");
    } else {
      if (!emailCheck) {
        setEmailError("Please enter a valid e-mail address");
      } else {
        setEmailError("");
      }
    }

    if (password === "") {
      setPasswordError("Please enter your password");
    } else if (password.length < 8 || password.length > 15) {
      setPasswordError("Password must be 8-15 characters long");
    } else if (!passwordCheck) {
      setPasswordError(
        "Password must contain at least one number, one uppercase letter, one lowercase letter, one special character."
      );
    } else {
      setPasswordError("");
    }

    const mapCognitoError = (error) => {
      if (error.code) {
        switch (error.code) {
          case "UsernameExistsException":
            return "The entered email is already registered. Please enter a new email id.";
          case "InvalidParameterException":
            return "Please provide a valid email address.";
          case "InvalidPasswordException":
            return "";

          default:
            return error.message;
        }
      } else {
        if (error.message === "Username cannot be empty") {
          return "";
        }

        return error.message;
      }
    };

    if (userNameError === "" && emailError === "" && passwordError === "") {
      try {
      const userExists = await checkUserExistence(email, "DoctorInterface");
      if (userExists) {
        setEmailError("Already user exists with this email.");
        return;
      }

        const data = await Auth.signUp({
          username: email,
          password: password,
          attributes: {
            email: email,
            "custom:userName": userName,
          },
        });
        navigate("/conformation", { state: { email: email } });
      } catch (error) {
        const customErrorMessage = mapCognitoError(error);
        setCognitoError(customErrorMessage); 
      }
    }
  };

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      sx={{ minHeight: "100vh" }}
    >
      <div style={{ marginTop: "4rem" }}>
        <Logo />
      </div>
      <TitleLabel fontWeight={600} sx={{ mt: "3rem", mb: "1.25rem" }}>
        Sign Up
      </TitleLabel>
      <Container maxWidth="xs">
        <TextLabel fontWeight={600} fontSize="16px">
          Name
        </TextLabel>
        <TextInputField
          sx={{
            mt: 1,
            mb: userNameError ? 0 : 3,
          }}
          fullWidth={true}
          placeholder="Enter name"
          type="text"
          value={userName}
          onChange={handleNameChange}
        />
        {userNameError && <ErrorMessage message={userNameError} />}
        <TextLabel fontWeight={600} fontSize="16px">
          Email
        </TextLabel>
        <TextInputField
          sx={{
            mt: 1,
            mb: emailError ? 0 : 3,
          }}
          fullWidth={true}
          placeholder="Enter email"
          type="email"
          value={email}
          onChange={handleEmailChange}
          endAdornment={
            <InputAdornment position="end">
              <img
                src={emailIcon}
                alt="Email Icon"
                style={{ width: 20, height: 20 }}
              />
            </InputAdornment>
          }
        />
        {emailError && <ErrorMessage message={emailError} />}
        <TextLabel fontWeight={600} fontSize="16px">
          Password
        </TextLabel>
        <TextInputField
          sx={{
            mt: 1,
            mb: passwordError ? 0 : 3,
          }}
          fullWidth={true}
          placeholder="Password of at least 8 characters"
          type={showPassword ? "text" : "password"}
          value={password}
          onChange={handlePasswordChange}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                edge="end"
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          }
        />
        {passwordError && <ErrorMessage message={passwordError} />}
        {cognitoError && <ErrorMessage message={cognitoError} />}
        <PrimaryButton fullWidth type="button" onClick={validateForm}>
          Sign Up
        </PrimaryButton>
        <div
          style={{
            marginTop: "1rem",
            textAlign: "center",
            fontSize: "0.875rem",
            fontWeight: "500",
            fontFamily: "Mulish",
          }}
        >
          Already have an account?{" "}
          <Link
            to="/"
            variant="body2"
            style={{
              textDecoration: "none",
              marginLeft: "0.5rem",
              fontSize: "0.875rem",
              fontWeight: "700",
              color: "#174FB6",
              fontFamily: "Mulish",
            }}
          >
            Sign In
          </Link>
        </div>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          sx={{ mt: "6rem", mb: "2.5rem" }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <FooterLabel
              fontWeight={400}
              fontSize={16}
              sx={{ display: "inline" }}
            >
              Powered By
            </FooterLabel>
            <Box
              component="img"
              src={genaiLogo}
              width="200px"
              height="40px"
              sx={{ display: "inline", ml: "10px" }}
            />
          </Box>
        </Grid>
      </Container>
    </Grid>
  );
};

export default SignUpForm;
