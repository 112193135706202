
export const validateForm = (formData) => {
  const errors = {};
  if (!formData.selectedCategory) {
    errors.selectedCategory = "Category is required";
  }
  if (!formData.selectedType) {
    errors.selectedType = "Type is required";
  }
  if (!formData.titleValue) {
    errors.titleValue = "Title is required";
  }
  if (!formData.labelValue) {
    errors.labelValue = "Label is required";
  }

  if (!formData.whatValue) {
    errors.whatValue = "What is required";
  }
  if (!formData.whyValue) {
    errors.whyValue = "Why is required";
  }
  return errors;
};
