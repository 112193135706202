import React, { useState, useEffect } from "react";
import { Box, Grid, Paper, TextField, IconButton } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import TitleBar from "../components/Titlebar";
import PrimaryButton from "../components/primaryTextButton";
import SecondaryButton from "../components/secondaryTextButton";
import TextLabel from "../components/textLabel";
import loadingIcon from "../assets/loadingIcon.gif";
import { getAllNodes, deleteNode, deleteNodeWithLink } from "../api/api";
import editIcon from "../assets/editIcon.svg";
import deleteIcon from "../assets/deleteIcon.svg";
import DeleteModal from "../components/deleteModal";
import "../App.css";
const validCategories = [
  "DiagnosticReading",
  "Ailments",
  "Symptoms",
  "Remedies",
  "Nutrition",
  "Lifestyle",
  "Monitors",
  "Characteristics",
  "Heriditory/Genetic",
  "Historic Damage",
  "Habitual",
  "Others",
];
const Homepage = () => {
  const navigate = useNavigate();
  const [nodes, setNodes] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [categorySortDirection, setCategorySortDirection] = useState("asc");
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [nodeToDelete, setNodeToDelete] = useState(null);
  const [deleteWithLink, setDeleteWithLink] = useState(false);
  const [modalText, setModalText] = useState(
    "Are you sure you want to delete the clinical component?"
  );
  const email = useSelector((state) => state.email);
  const username = useSelector((state) => state.username);
  const dispatch = useDispatch();

  useEffect(() => {
    fetchNodes();
  }, []);
  const fetchNodes = async () => {
    setIsLoading(true);
    try {
      const response = await getAllNodes();
      setNodes(response.data.data);
    } catch (error) {
      console.error("Error fetching nodes:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleEditNode = (nodeName, nodeTitle) => {
    navigate("/createnode", {
      state: { nodeName, nodeTitle, isEditing: true },
    });
  };
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleDeleteNode = (category, label) => {
    setNodeToDelete({ category, label });
    setIsModalOpen(true);
  };

  const confirmDeleteNode = async () => {
    if (nodeToDelete) {
      try {
        let response;
        if (deleteWithLink) {
          response = await deleteNodeWithLink(
            nodeToDelete.category,
            nodeToDelete.label
          );
        } else {
          response = await deleteNode(
            nodeToDelete.category,
            nodeToDelete.label
          );
        }

        if (response) {
          setIsModalOpen(false);
          setNodeToDelete(null);
          navigate("/successpage", {
            state: {
              successTitle: "Successfully Deleted!",
              successContent:
                "The clinical component has been deleted successfully.",
            },
          });
          fetchNodes();
        } else {
          setIsModalOpen(true);
          setModalText(
            "Are you sure you want to delete the clinical component along with the clinical relationship?"
          );
          setDeleteWithLink(true);
        }
      } catch (error) {
        console.error("Error deleting node:", error);
      }
    }
  };

  const filteredNodes = nodes.filter((node) => {
    const query = searchQuery.toLowerCase();
    const categoryMatch = node.Category.some((category) =>
      category.toLowerCase().includes(query)
    );
    const isTitleMatch =
      node.title &&
      typeof node.title === "string" &&
      node.title.toLowerCase().includes(query);
    const isNameMatch =
      node.name &&
      typeof node.name === "string" &&
      node.name.toLowerCase().includes(query);
    const isValidCategory = node.Category.some((category) =>
      validCategories.includes(category)
    );
    return isValidCategory && (categoryMatch || isTitleMatch || isNameMatch);
  });
  const sortedNodes = filteredNodes.sort((a, b) => {
    const categoryA = a.Category[0].toLowerCase();
    const categoryB = b.Category[0].toLowerCase();
    if (categoryA < categoryB) {
      return categorySortDirection === "asc" ? -1 : 1;
    }
    if (categoryA > categoryB) {
      return categorySortDirection === "asc" ? 1 : -1;
    }
    return 0;
  });
  const columns = [
    {
      field: "id",
      headerName: "S.No",
      headerAlign: "left",
      align: "left",
      flex: 1,
      sortable: false,
      headerClassName: "super-app-theme--header",
      disableColumnMenu: true,
      renderCell: (params) => (
        <div style={{ marginLeft: "20px" }}>{params.value}</div>
      ),
    },
    {
      field: "category",
      headerName: "Category",
      headerAlign: "left",
      align: "left",
      sortable: true,
      flex: 1,
      headerClassName: "super-app-theme--header",
      disableColumnMenu: true,
    },
    {
      field: "title",
      headerName: "Title",
      headerAlign: "left",
      align: "left",
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <div
          style={{
            whiteSpace: "normal",
            wordWrap: "break-word",
            overflow: "hidden",
            textOverflow: "ellipsis",
            marginTop: "10px",
            marginBottom: "10px",
            display: "-webkit-box",
            WebkitLineClamp: 3,
            WebkitBoxOrient: "vertical",
            lineHeight: "1.1",
          }}
        >
          {params.value}
        </div>
      ),
    },
    {
      field: "label",
      headerName: "Label",
      headerAlign: "left",
      align: "left",
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "edit",
      headerName: "Edit",
      headerAlign: "left",
      disableColumnMenu: true,
      sortable: false,
      flex: 1,
      width: 40,
      renderCell: (params) => (
        <img
          src={editIcon}
          alt="Edit"
          onClick={() => {
            handleEditNode(params.row.label, params.row.title);
          }}
          style={{ cursor: "pointer" }}
        />
      ),
    },
    {
      field: "delete",
      headerName: "Delete",
      headerAlign: "left",
      disableColumnMenu: true,
      sortable: false,
      flex: 1,
      width: 40,
      renderCell: (params) => (
        <img
          src={deleteIcon}
          alt="Delete"
          onClick={() =>
            handleDeleteNode(params.row.category, params.row.label)
          }
          style={{ cursor: "pointer" }}
        />
      ),
    },
  ];
  const rows = sortedNodes.map((node, index) => ({
    id: index + 1,
    category: node.Category.join(", "),
    title: node.title,
    label: node.name,
  }));
  const getEmailName = (email) => {
    return email.split("@")[0];
  };
  const capitalizeFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };
  const displayName = username ? username : getEmailName(email);
  const UserName = capitalizeFirstLetter(displayName);
  return (
    <Grid container direction="column" style={{ minHeight: "100vh" }}>
      <TitleBar />
      <Grid
        container
        sx={{ pt: "7rem", pl: "4.25rem", pr: "4.25rem" }}
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item>
          <TextLabel fontWeight={400} fontSize="1.175rem">
            Hi
            <span
              style={{
                paddingLeft: "0.525rem",
                fontWeight: 700,
                fontSize: "1.175rem",
              }}
            >
              {UserName},
            </span>
          </TextLabel>
        </Grid>
      </Grid>
      <Grid
        justifyContent={{ md: "flex-end" }}
        pr={{ xs: "4.25rem", md: "4.25rem" }}
        pl={{ xs: "4.25rem", md: 0 }}
        pt={{ xs: "1rem" }}
        pb={{ xs: "1rem", md: 0 }}
        display="flex"
        flexDirection={{ xs: "column", md: "row" }}
      >
        <Grid item sx={{ pt: "0.12rem", pr: "1.625rem", position: "relative" }}>
          <TextField
            variant="outlined"
            placeholder="Search here..."
            onChange={handleSearchChange}
            InputProps={{
              startAdornment: (
                <IconButton>
                  <SearchIcon />
                </IconButton>
              ),
            }}
            sx={{
              width: "350px",
              height: "23px",
              borderRadius: "0px",
              cursor: "text",
              fontSize: "18px",
              "& .MuiOutlinedInput-root": {
                height: "46px",
                borderRadius: 0,
                "& fieldset": {
                  borderRadius: 0,
                  border: "2px solid #999999",
                },
                "&:hover fieldset": {
                  borderColor: "#999999",
                  borderRadius: 0,
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#999999",
                  borderRadius: 0,
                },
              },
              "& .MuiOutlinedInput-input": {
                borderColor: "#999999",
                borderRadius: 0,
              },
            }}
          />
        </Grid>
        <Grid item pr={{ xs: "1.625rem" }} pt={{ xs: "1.5rem", md: "0.12rem" }}>
          <PrimaryButton onClick={() => navigate("/createnode")}>
            Create Clinical Component
          </PrimaryButton>
        </Grid>
        <Grid item pt={{ xs: "1.5rem", md: "0.12rem" }}>
          <SecondaryButton onClick={() => navigate("/createlink")}>
            Create Clinical Relationship
          </SecondaryButton>
        </Grid>
      </Grid>
      <Grid
        container
        sx={{ pt: "2rem", pl: "4.25rem", pr: "4.25rem" }}
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item>
          <TextLabel fontSize="1.25rem" fontWeight={600}>
            List of created clinical components
          </TextLabel>
        </Grid>
      </Grid>
      <Box sx={{ mt: "1.5rem", mb: "2.5rem", px: "4.25rem" }}>
        <div
          style={{
            maxHeight: "900px",
            overflowY: "auto",
            borderRadius: "6px",
            border: "1px solid #ccc",
          }}
          className="inner-content-scroll"
        >
          <Paper sx={{ height: 600, width: "100%" }}>
            {isLoading ? (
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                style={{ minHeight: "400px" }}
              >
                <img
                  src={loadingIcon}
                  alt="Loading"
                  style={{ width: "100px", height: "100px" }}
                />
              </Grid>
            ) : (
              <DataGrid
                rows={rows}
                columns={columns}
                pageSize={10}
                rowsPerPageOptions={[10]}
                disableSelectionOnClick
                localeText={{ noRowsLabel: "Data not found." }}
                sx={{
                  "&.MuiDataGrid-root": {
                    border: "none",
                  },
                  "& .MuiDataGrid-columnHeader": {
                    backgroundColor: "#EEEBEB",
                    color: "#333333",
                    fontWeight: 700,
                    fontSize: "18px",
                  },
                  "& ::-webkit-scrollbar": {
                    width: "6px",
                  },
                  "& ::-webkit-scrollbar-track": {
                    backgroundColor: "#f5f5f5",
                  },
                  "& ::-webkit-scrollbar-thumb": {
                    borderRadius: "10px",
                    boxShadow: "inset 0 0 6px rgba(0,0,0,.3)",
                    backgroundColor: "#f5f5f5",
                  },
                  "& .MuiDataGrid-columnHeader:focus": {
                    outline: "none !important",
                  },
                  "& .MuiDataGrid-cell:focus-within": {
                    outline: "none !important",
                  },
                  "& .MuiDataGrid-cell": {
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "left",
                    fontSize: "16px",
                    color: "#333333",
                  },
                  "& .MuiDataGrid-iconButtonContainer": {
                    visibility: "visible",
                  },
                  "& .MuiDataGrid-sortIcon": {
                    opacity: "inherit !important",
                    color: "#333333",
                    fontWeight: "bold",
                  },
                  "& .MuiDataGrid-row:hover": {
                    backgroundColor: "transparent",
                  },
                  "& .MuiDataGrid-row.Mui-selected": {
                    backgroundColor: "transparent",
                  },
                  "& .MuiDataGrid-row.Mui-hovered": {
                    backgroundColor: "transparent",
                  },
                  "& .MuiDataGrid-columnHeaderTitle": {
                    fontWeight: "bold",
                  },
                  "& .MuiDataGrid-overlay": {
                    fontSize: "16px",
                  },
                  "& .MuiDataGrid-columnHeader:focus-within, .MuiDataGrid-cell:focus-within":
                    {
                      outline: "none",
                    },
                }}
              />
            )}
          </Paper>
        </div>
      </Box>
      <DeleteModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onDelete={() => {
          confirmDeleteNode();
          if (deleteWithLink) {
            setDeleteWithLink(false);
          }
        }}
        modalText={modalText}
      />
    </Grid>
  );
};
export default Homepage;
