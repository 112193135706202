import React, { useRef, useEffect, useState } from "react";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

const useStyles = () => ({
  typographyRoot: {
    lineHeight: 1.5,
  },
  inputLabel: {
    fontSize: "smaller",
  },
});

const Dropdown = ({
  options = [],
  value,
  onChange,
  placeholder,
  mb,
  disabled,
}) => {
  const classes = useStyles();
  const [menuWidth, setMenuWidth] = useState("auto");
  const selectRef = useRef(null);

  useEffect(() => {
    if (selectRef.current) {
      const selectWidth = selectRef.current.offsetWidth;
      setMenuWidth(`${selectWidth}px`);
    }
  }, [options]);

  return (
    <Grid item xs={12} md={6}>
      <FormControl fullWidth>
        <Select
          ref={selectRef}
          value={value}
          onChange={onChange}
          displayEmpty
          disabled={disabled}
          style={{
            marginBottom: mb || "1.875rem",
          }}
          MenuProps={{
            PaperProps: {
              style: {
                width: menuWidth,
                maxHeight: "200px",
              },
            },
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          }}
          sx={{
            marginTop: "0.813rem",
            borderRadius: 0,
            "&.Mui-focused, &:hover": {
              "& .MuiOutlinedInput-notchedOutline": {
                border: "0.031rem solid #999999",
                borderRadius: 0,
              },
            },
          }}
          renderValue={(selected) => {
            if (!selected) {
              return (
                <Typography
                  variant="body2"
                  color="textPrimary"
                  sx={classes.typographyRoot}
                >
                  {placeholder}
                </Typography>
              );
            } else {
              return selected;
            }
          }}
          inputProps={{ "aria-label": "Without label" }}
        >
          {Array.isArray(options) &&
            options.length > 0 &&
            options.map((option, index) => (
              <MenuItem key={index} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </Grid>
  );
};

export default Dropdown;
