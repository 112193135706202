import React, { useState, useEffect } from "react";
import { Modal } from "@mui/material";
import Chart from "react-apexcharts";
import TextLabel from "../../../components/textLabel";
import TextInputField from "../../../components/inputField";
import PrimaryButton from "../../../components/primaryTextButton";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import "../../../App.css";
import { getNodeByNomenclature } from "../../../api/api";

const AgeGradientGraphModal = ({
  open,
  onClose,
  title,
  labelValue,
  content1,
  onDataPointsAgeGradientSubmit,
  isEditing,
}) => {
  const [gradientAge, setGradientAge] = useState([]);
  const [gradientDataPoints, setGradientDataPoints] = useState([]);
  const [gradientInputValue, setGradientInputValue] = useState("");
  const [error, setError] = useState("");

  let xAxisMin = 0;
  let xAxisMax = 10;
  let yAxisMin = 0;
  let yAxisMax = 1;

  if (title === "Age") {
    xAxisMin = 12;
    xAxisMax = 75;
  }

  useEffect(() => {
    if (isEditing) {
      getEditGradientNodeDetails();
    }
  }, [isEditing]);

  useEffect(() => {
    if (isEditing || open) {
      handleUpdate();
    }
  }, [isEditing, open, labelValue]);

  const parseDataString = (dataString) => {
    const points = dataString.split("),(");
    return points.map((point) => {
      const [x, y] = point.replace("(", "").replace(")", "").split(",");
      return { x: parseFloat(x), y: parseFloat(y) };
    });
  };

  const getEditGradientNodeDetails = async () => {
    try {
      const data = await getNodeByNomenclature(labelValue);
      if (data && data.length > 0) {
        const node = data[0];
        let parsedGradientAge;
        if (node.properties && node.properties.ageVsImportance) {
          const ageArray = JSON.parse(node.properties.ageVsImportance);
          const cleanedAgeArray = ageArray.map((item) =>
            item.replace(/"/g, "")
          );
          parsedGradientAge = parseDataString(cleanedAgeArray.join(","));
          setGradientAge(parsedGradientAge);
        } else {
          setGradientAge([]);
        }
      }
    } catch (error) {
      console.error("Error fetching node details:", error);
      setGradientAge([]);
    }
  };

  useEffect(() => {
    if (isEditing && Array.isArray(gradientAge)) {
      const gradientAgeString = gradientAge
        .map((point) => `(${point.x},${point.y})`)
        .join(",");
      setGradientInputValue(gradientAgeString);
    }
  }, [gradientAge, isEditing]);

  const validateDataPoints = (gradientdataPoints) => {
    for (const point of gradientdataPoints) {
      if (
        point.x < xAxisMin ||
        point.x > xAxisMax ||
        point.y < yAxisMin ||
        point.y > yAxisMax
      ) {
        return false;
      }
    }
    return true;
  };

  useEffect(() => {
    const gradientPoints = parseGradientDataPoints(gradientInputValue);
    if (!gradientPoints.error) {
      setGradientDataPoints(gradientPoints);
    } else {
      setGradientDataPoints([]);
    }
  }, [gradientInputValue]);

  const handleUpdate = () => {
    const gradientPoints = parseGradientDataPoints(gradientInputValue);
    setGradientDataPoints(gradientPoints);
    onDataPointsAgeGradientSubmit(gradientPoints);
    setError("");
    const modalContent = document.querySelector(".modal-content");
    if (modalContent) {
      modalContent.scrollTop = 0;
    }
  };

  const handleSubmit = () => {
    const points = parseGradientDataPoints(gradientInputValue);

    if (points.error) {
      setError("Invalid format. Please use the format (x,y),(x,y).");
      return;
    }

    if (!validateDataPoints(points)) {
      setError(
        `Data points must be within the range: x axis - (${xAxisMin}-${xAxisMax}), y axis - (${yAxisMin}-${yAxisMax}).`
      );
      return;
    }

    if (points.length === 0) {
      setError("Please enter at least one data point.");
      return;
    }

    setGradientDataPoints(points);
    onDataPointsAgeGradientSubmit(points);
    setError("");
    const modalContent = document.querySelector(".modal-content");
    if (modalContent) {
      modalContent.scrollTop = 0;
    }
  };

  const parseGradientDataPoints = (input) => {
    const regex = /\((\d+(\.\d+)?),(\d+(\.\d+)?)\)/g;
    const parsedPoints = [];
    let match;
    while ((match = regex.exec(input)) !== null) {
      const x = parseFloat(match[1]);
      const y = parseFloat(match[3]);
      if (!isNaN(x) && !isNaN(y)) {
        parsedPoints.push({ x, y });
      }
    }
    if (parsedPoints.length === 0 && input) {
      return { error: true };
    }
    return parsedPoints;
  };

  const chartOptions = {
    colors: ["#092B6B"],
    chart: {
      type: "line",
      height: 400,
      toolbar: { show: false },
      zoom: { enabled: false },
    },
    stroke: {
      curve: "straight",
    },
    markers: {
      size: 3,
      colors: ["#092B6B"],
    },
    annotations: {
      yaxis: [
        {
          y: 0,
          strokeDashArray: 0,
          borderColor: "#092B6B",
          fillColor: "#092B6B",
          borderWidth: 2,
          opacity: 0.3,
        },
      ],
      xaxis: [
        {
          x: xAxisMin,
          strokeDashArray: 0,
          borderColor: "#092B6B",
          fillColor: "#092B6B",
          borderWidth: 2,
          opacity: 0.3,
        },
      ],
    },
    grid: {
      show: false,
      borderColor: "#ff0000",
    },
    xaxis: {
      type: "numeric",
      min: xAxisMin,
      max: xAxisMax,
    },
    yaxis: {
      min: yAxisMin,
      max: yAxisMax,
    },
  };

  const series = [
    {
      name: "Gradient Age Data Points",
      data: Array.isArray(gradientDataPoints) ? gradientDataPoints : [],
    },
    isEditing && {
      name: "Gradient Data Points",
      data: Array.isArray(gradientDataPoints) ? gradientDataPoints : [],
    },
    isEditing &&
      Array.isArray(gradientAge) &&
      gradientAge.length > 0 && { name: "Gradient Age", data: gradientAge },
  ].filter(Boolean);

  return (
    <Modal
      open={open}
      onClose={onClose}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      className="inner-content-scroll"
    >
      <div
        style={{
          backgroundColor: "white",
          padding: "40px",
          width: "80%",
          maxWidth: "700px",
          maxHeight: "500px",
          borderRadius: "2px",
          overflowX: "hidden",
        }}
        className="inner-content-scroll modal-content"
      >
        <div
          style={{
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <CancelOutlinedIcon
            onClick={onClose}
            style={{ color: "#666666", width: 30, height: 30 }}
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "20px",
          }}
        >
          <TextLabel fontWeight={600}>
            {title} - {content1}
          </TextLabel>
        </div>
        <div
          style={{
            width: "100%",
            border: "1px solid #999999",
            marginBottom: "20px",
          }}
        >
          <Chart
            options={chartOptions}
            series={
              isEditing
                ? [
                    {
                      name: "Gradient Age DataPoints",
                      data: gradientDataPoints,
                    },
                  ] || [{ name: "Gradient Age", data: gradientAge }]
                : series
            }
            type="line"
            width="100%"
          />
        </div>
        <div style={{ width: "100%" }}>
          <TextLabel fontSize="15px" fontWeight={500}>Enter Data Points</TextLabel>
          <TextLabel fontSize="14px" fontWeight={500} color="#666666" customStyles={{ marginTop: "10px" }}>
            (1,0.2),(2,0.3)
          </TextLabel>
          <TextInputField
            placeholder="Enter X, Y values"
            variant="outlined"
            value={gradientInputValue}
            onChange={(e) => setGradientInputValue(e.target.value)}
            fullWidth
          />
        </div>
        {error && (
          <div style={{ color: "red", marginTop: "10px" }}>{error}</div>
        )}
        <div
          style={{ display: "flex", justifyContent: "end", marginTop: "20px" }}
        >
          {isEditing ? (
            <PrimaryButton
              onClick={handleUpdate}
              variant="contained"
              color="primary"
            >
              Update
            </PrimaryButton>
          ) : (
            <PrimaryButton
              onClick={handleSubmit}
              variant="contained"
              color="primary"
            >
              Submit
            </PrimaryButton>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default AgeGradientGraphModal;
