import React from "react";
import AutosizeTextarea from "react-textarea-autosize";

function TextareaAutosize(props) {
  const textareaStyle = {
    width: "100%",
    outerHeight: "5.063rem",
    fontSize: "1rem",
    fontWeight: "normal",
    color: "#333333",
    lineHeight: "25px",
    fontFamily: "Mulish",
    letterSpacing: "0em",
    textAlign: "left",
    border: "1px solid #999999",
    paddingTop: "10px",
    paddingBottom: "10px",
    outline: "none",
    "&:focus": {
      border: "1px solid #999999",
    },
  };

  return (
    <AutosizeTextarea
      style={textareaStyle}
      minRows={3}
      maxRows={10}
      {...props}
    />
  );
}

export default TextareaAutosize;
