import React, { useState, useEffect } from "react";
import TitleBar from "../../components/Titlebar";
import PopupModal from "../../components/editModal";
import { validateForm } from "../../components/validationSchema";
import CreateNodeForm from "../clinicalComponent/createNodeForm";
import { useNavigate, useLocation } from "react-router-dom";
import {
  createNode,
  updateNode,
  getNodeByNomenclature,
  getNodeByCategory,
  checkIfNodeExists,
} from "../../api/api";
import { Toolbar, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
const initialFormData = {
  selectedCategory: "",
  selectedType: "",
  titleValue: "",
  labelValue: "",
  whatValue: "",
  whyValue: "",
  unitValue: "",
  maleLowValue: "",
  maleHighValue: "",
  femaleLowValue: "",
  femaleHighValue: "",
  maleUnitValue: "",
  femaleUnitValue: "",
  maleLowImpactData: [],
  femaleLowImpactData: [],
  maleHighImpactData: [],
  femaleHighImpactData: [],
  gradientValues: {
    gradient1: "",
    gradient2: "",
    gradient3: "",
    gradient4: "",
  },
};
const CreateNode = () => {
  const location = useLocation();
  const [formData, setFormData] = useState({ ...initialFormData });
  const [errors, setErrors] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showUnitInput, setShowUnitInput] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [existingTitleError, setExistingTitleError] = useState(false);
  const [existingLabelError, setExistingLabelError] = useState(false);
  const navigate = useNavigate();
  const [impactData, setImpactData] = useState({
    male: { high: [], low: [] },
    female: { high: [], low: [] },
  });
  const [severityData, setSeverityData] = useState({
    male: { high: [], low: [] },
    female: { high: [], low: [] },
  });
  const [ageData, setAgeData] = useState({
    male: { high: [], low: [] },
    female: { high: [], low: [] },
  });
  const [nodeList, setNodeList] = useState([]);
  const [displayGradientValues, setDisplayGradientValues] = useState(false);
  const [impactGradientData, setImpactGradientData] = useState([]);
  const [severityGradientData, setSeverityGradientData] = useState([]);
  const [ageGradientData, setAgeGradientData] = useState([]);
  const [gradientErrors, setGradientErrors] = useState({
    gradient1: "",
    gradient2: "",
    gradient3: "",
    gradient4: "",
  });

  useEffect(() => {
    if (location.state && location.state.isEditing) {
      const { nodeName, nodeTitle } = location.state;
      setFormData({
        ...initialFormData,
        titleValue: nodeTitle,
        labelValue: nodeName,
      });
      setIsEditing(true);
    }
  }, [location.state]);

  useEffect(() => {
    if (isEditing) {
      getNodeDetails();
    }
  }, [formData.titleValue, isEditing]);
  const handleDataPointsSubmit = (points, targetState, targetName) => {
    if (
      points.maleHighPoints ||
      points.maleLowPoints ||
      points.femaleHighPoints ||
      points.femaleLowPoints
    ) {
      const updatedData = {
        male: {
          high: points.maleHighPoints
            ? points.maleHighPoints.map(({ x, y }) => `(${x},${y})`).join(",")
            : "",
          low: points.maleLowPoints
            ? points.maleLowPoints.map(({ x, y }) => `(${x},${y})`).join(",")
            : "",
        },
        female: {
          high: points.femaleHighPoints
            ? points.femaleHighPoints.map(({ x, y }) => `(${x},${y})`).join(",")
            : "",
          low: points.femaleLowPoints
            ? points.femaleLowPoints.map(({ x, y }) => `(${x},${y})`).join(",")
            : "",
        },
      };

      targetState(updatedData, () => {
        console.log(`Updated ${targetName} Data:`, updatedData);
      });
    } else {
      console.error(`Invalid data format for ${targetName} points:`, points);
    }
  };
  const handleDataPointsGradientSubmit = (points, targetState, targetName) => {
    if (points) {
      const updatedData = {
        [targetName]: points.map(({ x, y }) => `(${x},${y})`).join(","),
      };
      targetState(updatedData, () => {
        console.log(`Updated Gradient ${targetName} Data:`, updatedData);
      });
    } else {
      console.error(`Invalid data format for ${targetName} points:`, points);
    }
  };
  const handleImpactDataPointsSubmit = (points) => {
    handleDataPointsSubmit(points, setImpactData, "Impact");
  };
  const handleSeverityDataPointsSubmit = (points) => {
    handleDataPointsSubmit(points, setSeverityData, "Severity");
  };
  const handleAgeDataPointsSubmit = (points) => {
    handleDataPointsSubmit(points, setAgeData, "Age");
  };
  const handleGradientImpactDataPointsSubmit = (points) => {
    handleDataPointsGradientSubmit(points, setImpactGradientData, "Impact");
    setImpactGradientData(points);
  };
  const handleGradientSeverityDataPointsSubmit = (points) => {
    handleDataPointsGradientSubmit(points, setSeverityGradientData, "Severity");
    setSeverityGradientData(points);
  };
  const handleGradientAgeDataPointsSubmit = (points) => {
    handleDataPointsGradientSubmit(points, setAgeGradientData, "Age");
    setAgeGradientData(points);
  };
  const handleGradientValuesChange = (event, fieldName) => {
    const { value } = event.target;
    const isValidFormat = /^\(\w+,\d+\)$/.test(value);
    setFormData((prevValues) => ({
      ...prevValues,
      gradientValues: {
        ...prevValues.gradientValues,
        [fieldName]: value,
      },
    }));
    setGradientErrors((prevErrors) => ({
      ...prevErrors,
      [fieldName]: isValidFormat ? "" : "Value must be in the format (x,y)",
    }));
  };
  const getNodeDetails = async () => {
    try {
      const data = await getNodeByNomenclature(formData.labelValue);
      if (data && data.length > 0) {
        const node = data[0];
        const { male: maleValue, female: femaleValue } = node.properties.value;
        let maleLowValue, femaleLowValue, maleHighValue, femaleHighValue;
        if (formData?.titleValue && formData?.titleValue?.includes("Low")) {
          maleLowValue = maleValue;
          femaleLowValue = femaleValue;
        } else if (
          formData?.titleValue &&
          formData?.titleValue?.includes("High")
        ) {
          maleHighValue = maleValue;
          femaleHighValue = femaleValue;
        }
        const gradientString = node.properties.gradient || "";
        const gradientArray = gradientString
          .slice(1, -1)
          .split("),(")
          .map((item) => `${item}`);
        const gradientValues = {
          gradient1: gradientArray[0] || "",
          gradient2: gradientArray[1] || "",
          gradient3: gradientArray[2] || "",
          gradient4: gradientArray[3] || "",
        };
        setFormData((prevFormData) => ({
          ...prevFormData,
          selectedCategory: node.labels[0],
          selectedType: node.properties.nodeType,
          whatValue: node.properties.what,
          whyValue: node.properties.why,
          maleUnitValue: node.properties.unit,
          femaleUnitValue: node.properties.unit,
          maleLowValue,
          femaleLowValue,
          maleHighValue,
          femaleHighValue,
          gradientValues,
        }));
      }
    } catch (error) {
      console.error("Error fetching node details:", error);
    }
  };
  const resetFormData = () => {
    setFormData({ ...initialFormData });
    setImpactData({
      male: { high: [], low: [] },
      female: { high: [], low: [] },
    });
    setSeverityData({
      male: { high: [], low: [] },
      female: { high: [], low: [] },
    });
    setAgeData({
      male: { high: [], low: [] },
      female: { high: [], low: [] },
    });
  };
  const handleChange = (name, value) => {
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };
  const handleInputChange = async (event, type, fieldName) => {
    const newValue = event.target.value;
    handleChange(fieldName, newValue);
    if (newValue !== "") {
      try {
        const result = await checkIfNodeExists(newValue, type, fieldName);
        if (result === "Node exists.") {
          type === "title"
            ? setExistingTitleError(true)
            : setExistingLabelError(true);
        } else {
          type === "title"
            ? setExistingTitleError(false)
            : setExistingLabelError(false);
        }
      } catch (error) {
        console.error("Error checking if node exists:", error);
      }
    } else {
      type === "title"
        ? setExistingTitleError(false)
        : setExistingLabelError(false);
    }
  };
  const handleValueChange = (fieldName) => (event) => {
    const newValue = event.target.value;
    handleChange(fieldName, newValue);
    setErrors((prevErrors) => ({ ...prevErrors, [fieldName]: "" }));
    if (newValue.trim() !== "") {
      setDisplayGradientValues(false);
    }
  };
  const handlemaleLowValueChange = handleValueChange("maleLowValue");
  const handlefemaleLowValueChange = handleValueChange("femaleLowValue");
  const handlemaleHighValueChange = handleValueChange("maleHighValue");
  const handlefemaleHighValueChange = handleValueChange("femaleHighValue");
  const handlemaleUnitValueChange = (event) => {
    const newValue = event.target.value;
    handleChange("maleUnitValue", newValue);
    setErrors((prevErrors) => ({ ...prevErrors, maleUnitValue: "" }));
    if (newValue.trim() !== "") {
      setDisplayGradientValues(false);
    }
  };
  const handlefemaleUnitValueChange = (event) => {
    const newValue = event.target.value;
    handleChange("femaleUnitValue", newValue);
    setErrors((prevErrors) => ({ ...prevErrors, femaleUnitValue: "" }));
    if (newValue.trim() !== "") {
      setDisplayGradientValues(false);
    }
  };
  const handleCategoryChange = async (event) => {
    const selectedCategory = event.target.value;
    handleChange("selectedCategory", selectedCategory);
    setErrors((prevErrors) => ({ ...prevErrors, selectedCategory: "" }));
    setShowUnitInput(selectedCategory === "DiagnosticReading");
    if (isEditing) {
      const { labelValue } = formData;
      handleChange("labelValue", labelValue);
    }
    try {
      const data = await getNodeByCategory(selectedCategory);
      setNodeList(data);
    } catch (error) {
      console.error("Error fetching nodes by category:", error);
    }
  };
  const handleTitleChange = (event) => {
    handleInputChange(event, "title", "titleValue");
  };
  const handleLabelChange = (event) => {
    handleInputChange(event, "name", "labelValue");
  };
  const createNodes = async (
    titlePrefix,
    value,
    lowValueImpactData,
    highValueImpactData,
    lowValueSeverityData,
    highValueSeverityData,
    lowValueAgeData,
    highValueAgeData,
    impactGradientData,
    severityGradientData,
    ageGradientData
  ) => {
    try {
      await createNode(
        formData.selectedCategory,
        formData.selectedType,
        titlePrefix + " " + formData.titleValue,
        formData.labelValue,
        formData.whatValue,
        formData.whyValue,
        formData.gradientValues,
        impactGradientData,
        severityGradientData,
        ageGradientData,
        formData.maleUnitValue,
        formData.maleHighValue,
        formData.femaleHighValue,
        formData.maleLowValue,
        formData.femaleLowValue,
        lowValueImpactData,
        highValueImpactData,
        lowValueSeverityData,
        highValueSeverityData,
        lowValueAgeData,
        highValueAgeData,
        impactGradientData,
        severityGradientData,
        ageGradientData
      );
    } catch (error) {
      console.error("Error creating node:", error);
      throw error;
    }
  };
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const handleSubmit = async () => {
    try {
      const validationErrors = validateForm(formData, showUnitInput);
      setErrors(validationErrors);
      if (Object.keys(validationErrors).length !== 0) {
        return;
      }
      if (isEditing) {
        const hasChanges = Object.keys(formData).some(
          (key) => formData[key] !== initialFormData[key]
        );
        if (hasChanges) {
          try {
            const highValueImpactData = {
              male: impactData.male.high,
              female: impactData.female.high,
            };
            const lowValueImpactData = {
              male: impactData.male.low,
              female: impactData.female.low,
            };
            const highValueSeverityData = {
              male: severityData.male.high,
              female: severityData.female.high,
            };
            const lowValueSeverityData = {
              male: severityData.male.low,
              female: severityData.female.low,
            };
            const highValueAgeData = {
              male: ageData.male.high,
              female: ageData.female.high,
            };
            const lowValueAgeData = {
              male: ageData.male.low,
              female: ageData.female.low,
            };
            const editedFormData = {
              ...formData,
              lowValueImpactData,
              highValueImpactData,
              lowValueSeverityData,
              highValueSeverityData,
              lowValueAgeData,
              highValueAgeData,
              impactGradientData,
              severityGradientData,
              ageGradientData,
            };
            await updateNode(editedFormData);
            navigate("/successpage", {
              state: {
                successTitle: "Successfully Updated!",
                successContent: "Your changes have been saved successfully.",
              },
            });
          } catch (error) {
            console.error("Error updating node:", error);
          }
        }
      } else {
        try {
          if (formData.selectedCategory !== "DiagnosticReading") {
            await createNode(
              formData.selectedCategory,
              formData.selectedType,
              formData.titleValue,
              formData.labelValue,
              formData.whatValue,
              formData.whyValue
            );
          } else {
            const hasGradientValues =
              formData.gradientValues &&
              Object.values(formData.gradientValues).some(
                (value) => value.trim() !== ""
              );
            if (
              formData.selectedCategory === "DiagnosticReading" &&
              hasGradientValues
            ) {
              await createNode(
                formData.selectedCategory,
                formData.selectedType,
                formData.titleValue,
                formData.labelValue,
                formData.whatValue,
                formData.whyValue,
                formData.gradientValues,
                impactGradientData,
                severityGradientData,
                ageGradientData
              );
            } else {
              const highValueImpactData = {
                male: impactData.male.high,
                female: impactData.female.high,
              };
              const lowValueImpactData = {
                male: impactData.male.low,
                female: impactData.female.low,
              };
              const highValueSeverityData = {
                male: severityData.male.high,
                female: severityData.female.high,
              };
              const lowValueSeverityData = {
                male: severityData.male.low,
                female: severityData.female.low,
              };
              const highValueAgeData = {
                male: ageData.male.high,
                female: ageData.female.high,
              };
              const lowValueAgeData = {
                male: ageData.male.low,
                female: ageData.female.low,
              };
              if (
                formData.maleLowValue &&
                formData.maleHighValue &&
                formData.femaleLowValue &&
                formData.femaleHighValue
              ) {
                const highValue = `{"male":${formData.maleHighValue},"female":${formData.femaleHighValue}}`;
                const lowValue = `{"male":${formData.maleLowValue},"female":${formData.femaleLowValue}}`;
                await createNodes(
                  "High",
                  highValue,
                  null,
                  highValueImpactData,
                  null,
                  highValueSeverityData,
                  null,
                  highValueAgeData,
                  null,
                  null,
                  null
                );
                await createNodes(
                  "Low",
                  lowValue,
                  lowValueImpactData,
                  null,
                  lowValueSeverityData,
                  null,
                  lowValueAgeData,
                  null,
                  null,
                  null,
                  null
                );
              } else {
                console.error(
                  "Either gradient values or both low and high values are required."
                );
              }
            }
          }
          setIsModalOpen(true);
        } catch (error) {
          console.error("Error:", error);
        }
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  return (
    <>
      <TitleBar />
      <Toolbar sx={{ mt: "6rem", mx: "3rem", textDecoration: "none" }}>
        <Typography
          component={Link}
          to="/homepage"
          sx={{
            textDecoration: "none",
            color: "inherit",
            fontSize: 16,
            fontWeight: 400,
          }}
        >
          Home
        </Typography>
        <ArrowForwardIosRoundedIcon sx={{ fontSize: 16, ml: 0.5, mr: 0.5 }} />
        <Typography
          component={Link}
          to="/createnode"
          sx={{
            textDecoration: "none",
            color: "inherit",
            fontSize: 16,
            fontWeight: 400,
          }}
        >
          Create Clinical Component
        </Typography>
      </Toolbar>
      <CreateNodeForm
        formData={formData}
        errors={errors}
        isEditing={isEditing}
        setFormData={setFormData}
        setErrors={setErrors}
        onChange={handleChange}
        onSubmit={handleSubmit}
        handleCategoryChange={handleCategoryChange}
        handleTitleChange={handleTitleChange}
        handleLabelChange={handleLabelChange}
        handlemaleLowValueChange={handlemaleLowValueChange}
        handlemaleHighValueChange={handlemaleHighValueChange}
        handlemaleUnitValueChange={handlemaleUnitValueChange}
        handlefemaleUnitValueChange={handlefemaleUnitValueChange}
        handlefemaleLowValueChange={handlefemaleLowValueChange}
        handlefemaleHighValueChange={handlefemaleHighValueChange}
        handleImpactDataPointsSubmit={handleImpactDataPointsSubmit}
        handleSeverityDataPointsSubmit={handleSeverityDataPointsSubmit}
        handleAgeDataPointsSubmit={handleAgeDataPointsSubmit}
        handleGradientImpactDataPointsSubmit={
          handleGradientImpactDataPointsSubmit
        }
        handleGradientSeverityDataPointsSubmit={
          handleGradientSeverityDataPointsSubmit
        }
        handleGradientAgeDataPointsSubmit={handleGradientAgeDataPointsSubmit}
        handleGradientValuesChange={handleGradientValuesChange}
        handleSubmit={handleSubmit}
        existingTitleError={existingTitleError}
        existingLabelError={existingLabelError}
        showUnitInput={showUnitInput}
        gradientErrors={gradientErrors}
      />
      <PopupModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        resetFormData={resetFormData}
      />
    </>
  );
};
export default CreateNode;
