import React, { useState, useEffect } from "react";
import { Grid, Box } from "@mui/material";
import TextLabel from "../../../components/textLabel";
import AgeGradientGraphModal from "./ageGradientGraphModal";
import Chart from "react-apexcharts";
import { getNodeByNomenclature } from "../../../api/api";
import TextButtonLink from "../../../components/textButtonLink";

const AgeGradientGraphValues = ({
  title,
  titleValue,
  labelValue,
  isEditing,
  content1,
  content2,
  onDataPointsAgeGradientSubmit,
  graphValueStyles,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [dataPoints, setDataPoints] = useState([]);
  const [submitted, setSubmitted] = useState(false);
  const [gradientAge, setGradientAge] = useState([]);
  const [error, setError] = useState(null);

  let xAxisMin = 1;
  let yAxisMin = 0;
  let yAxisMax = 1;

  useEffect(() => {
    if (isEditing) {
      getEditGradientAgeNodeDetails();
    }
  }, [isEditing]);

  useEffect(() => {
    if (isEditing) {
      handleAgeGradientDataPoints(gradientAge);
    }
  }, [isEditing, gradientAge]);

  const [chartWidth, setChartWidth] = useState(
    window.innerWidth <= 600 ? 300 : 600
  );

  useEffect(() => {
    const handleResize = () => {
      setChartWidth(window.innerWidth <= 600 ? 300 : 600);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const parseDataString = (dataString) => {
    const points = dataString.split("),(");
    return points.map((point) => {
      const [x, y] = point.replace("(", "").replace(")", "").split(",");
      return { x: parseFloat(x), y: parseFloat(y) };
    });
  };

  const getEditGradientAgeNodeDetails = async () => {
    try {
      const data = await getNodeByNomenclature(labelValue);
      if (data && data.length > 0) {
        const node = data[0];
        let parsedGradientAge = [];

        if (node.properties && node.properties.ageVsImportance) {
          const ageArray = JSON.parse(node.properties.ageVsImportance);
          const cleanedAgeArray = ageArray.map((item) => {
            if (typeof item === "string") {
              return item.replace(/"/g, "");
            } else if (
              typeof item === "object" &&
              item !== null &&
              "x" in item &&
              "y" in item
            ) {
              return `(${item.x},${item.y})`;
            } else {
              console.error("Unexpected item type:", typeof item, item);
              return ""; 
            }
          });
          parsedGradientAge = parseDataString(cleanedAgeArray.join(","));
        }

        setGradientAge(
          Array.isArray(parsedGradientAge) ? parsedGradientAge : []
        );
      } else {
        setGradientAge([]);
      }
    } catch (error) {
      console.error("Error fetching node details:", error);
      setGradientAge([]);
    }
  };
  const handleModalOpen = () => {
    setIsModalOpen(true);
  };

  const handleAgeGradientDataPoints = (points) => {
    if (!Array.isArray(points)) {
      console.error("Expected points to be an array, but received:", points);
      setError("Invalid data points format");
      return;
    }

    const formattedPoints = points.map(({ x, y }) => `(${x},${y})`);
    setDataPoints(formattedPoints);
    onDataPointsAgeGradientSubmit(formattedPoints);
    setSubmitted(true);
  };

  const gradientAgeDataPoints = () => {
    if (isEditing && Array.isArray(gradientAge) && gradientAge.length > 0) {
      return [{ data: gradientAge }];
    }
    return [{ data: dataPoints }];
  };

  const seriesData = submitted
    ? [
        {
          data: dataPoints.map((point) => {
            const [x, y] = point.replace("(", "").replace(")", "").split(",");
            return { x: parseFloat(x), y: parseFloat(y) };
          }),
        },
      ]
    : gradientAgeDataPoints();
  if (
    !Array.isArray(seriesData) ||
    seriesData.some((series) => !Array.isArray(series.data))
  ) {
    console.error("Invalid series data structure:", seriesData);
    seriesData = [{ data: [] }];
  }

  const chartOptions = {
    colors: ["#092B6B"],
    chart: {
      type: "line",
      height: 350,
      toolbar: { show: false },
      zoom: { enabled: false },
    },
    stroke: {
      curve: "straight",
    },
    markers: {
      size: 3,
      colors: ["#092B6B"],
    },
    annotations: {
      yaxis: [
        {
          y: 0,
          strokeDashArray: 0,
          borderColor: "#092B6B",
          fillColor: "#092B6B",
          borderWidth: 2,
          opacity: 0.3,
        },
      ],
      xaxis: [
        {
          x: xAxisMin,
          strokeDashArray: 0,
          borderColor: "#092B6B",
          fillColor: "#092B6B",
          borderWidth: 2,
          opacity: 0.3,
        },
      ],
    },
    grid: {
      show: false,
      borderColor: "#ff0000",
    },
    xaxis: {
      type: "numeric",
      min: 1,
      max: 75,
    },
    yaxis: {
      min: yAxisMin,
      max: yAxisMax,
    },
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Grid
        item
        xs={12}
        sm={12}
        md={4}
        sx={{
          display: "flex",
          flexDirection: "column",
          border: "0.031rem solid #999999",
          padding: "1.875rem",
          ...graphValueStyles,
        }}
      >
        {(dataPoints && submitted) || isEditing ? (
          <div>
            <TextLabel fontWeight={500} fontSize="1.5rem" color="#174FB6">
              {title}
            </TextLabel>
            <Chart
              options={chartOptions}
              series={seriesData}
              type="line"
              width="300"
              height="250"
            />
             <TextButtonLink onClick={handleModalOpen}>
                Update Graph
              </TextButtonLink>
          </div>
        ) : (
          <>
            <TextLabel fontWeight={500} fontSize="1.5rem" color="#174FB6">
              {title}
            </TextLabel>
            <TextLabel
              fontWeight={400}
              fontSize="1rem"
              customStyles={{
                marginTop: "0.938rem",
                whiteSpace: "normal",
                wordWrap: "break-word",
              }}
            >
              {content1}
            </TextLabel>
            <TextLabel
              fontWeight={400}
              fontSize="0.813rem"
              customStyles={{ marginTop: "0.938rem", marginBottom: "0.938rem" }}
              color="#999999"
            >
              {content2}
            </TextLabel>
            <TextButtonLink onClick={handleModalOpen}>
                Enter Graph Value
              </TextButtonLink>
          </>
        )}
      </Grid>
      <AgeGradientGraphModal
        open={isModalOpen}
        onClose={handleModalClose}
        title={title}
        content1={content1}
        titleValue={titleValue}
        labelValue={labelValue}
        isEditing={isEditing}
        onDataPointsAgeGradientSubmit={handleAgeGradientDataPoints}
      />
    </>
  );
};

const GradientGraphValuesWithSpacing = ({ children }) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: { lg: "flex-start" },
        width: "100%",
        gap: "0.9rem",
        flexWrap: "wrap",
      }}
    >
      {React.Children.map(children, (child, index) => (
        <Box
          key={index}
          sx={{
            width: { xs: "100%", lg: "calc(33.33% - 0.625rem)" },
            marginBottom: { xs: "1rem", md: "3rem" },
          }}
        >
          {child}
        </Box>
      ))}
    </Box>
  );
};

export { AgeGradientGraphValues, GradientGraphValuesWithSpacing };
