import React from "react";
import { Modal } from "@mui/material";
import { Box, Button } from "@mui/material";
import SuccessIcon from "../assets/SuccessIcon.svg";
import TitleLabel from "../components/titleLabel";
import PrimaryButton from "../components/primaryTextButton";
import { useNavigate } from "react-router-dom";

const PopupModal = ({ isOpen, onClose, resetFormData }) => {
  const navigate = useNavigate();

  const handlecreateanother = () => {
    onClose();
    resetFormData();
    navigate("/createnode");
    window.scrollTo(0, 0);
  };

  const handleGoToHomepage = () => {
    navigate("/homepage");
    window.scrollTo(0, 0);
  };

  return (
    <Modal open={isOpen} onClose={onClose}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "90vh",
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            background: "white",
            pt: "2.5rem",
            pb: "1.5rem",
            px: "3.5rem",
            textAlign: "center",
            borderRadius: "1.25rem",
          }}
        >
          <img
            src={SuccessIcon}
            alt="Success Icon"
            sx={{ color: "#F5A623", fontSize: 30 }}
          />
          <TitleLabel
            fontSize={18}
            fontWeight={600}
            sx={{ mt: "1.125rem", mb: "1.563rem" }}
          >
            Clinical Component Created Successfully!
          </TitleLabel>
          <PrimaryButton onClick={handlecreateanother}>
            Create Another
          </PrimaryButton>
          <div>
            <Button
              variant="text"
              style={{
                marginTop: "1.125rem",
                fontSize: "1.25rem",
                fontWeight: "700",
                color: "#174FB6",
                textTransform: "none",
              }}
              onClick={handleGoToHomepage}
            >
              Go to homepage
            </Button>
          </div>
        </Box>
      </Box>
    </Modal>
  );
};

export default PopupModal;
