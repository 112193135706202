import { getAllNodes } from "../api/api";
import React, { useState, useEffect } from "react";

const useNodeOptions = () => {
  const [categories, setCategories] = useState([]);
  const [startNames, setStartNames] = useState([]);
  const [endNames, setEndNames] = useState([]);

  useEffect(() => {
    const fetchNodes = async () => {
      try {
        const response = await getAllNodes();
        const allCategories = response.data.data.map(
          (item) => item.Category[0]
        );
        const uniqueCategories = Array.from(new Set(allCategories));
        const formattedCategories = uniqueCategories.map((cat) => ({
          value: cat,
          label: cat,
        }));
        setCategories(formattedCategories);
      } catch (error) {
        console.error("Error fetching nodes:", error);
      }
    };

    fetchNodes();
  }, []);

  const fetchStartNames = async (selectedCategory) => {
    try {
      const response = await getAllNodes(); 
      const categoryNames = response.data.data
        .filter((item) => item.Category[0] === selectedCategory)
        .map((item) => ({
          value: item.name,
          label: item.name,
        }));
      setStartNames(categoryNames); 
    } catch (error) {
      console.error("Error fetching names:", error);
    }
  };

  const fetchEndNames = async (selectedCategory) => {
    try {
      const response = await getAllNodes(); 
      const categoryNames = response.data.data
        .filter((item) => item.Category[0] === selectedCategory)
        .map((item) => ({
          value: item.name,
          label: item.name,
        }));
      setEndNames(categoryNames); 
    } catch (error) {
      console.error("Error fetching names:", error);
    }
  };

  return { categories, startNames, endNames, fetchStartNames, fetchEndNames };
};

const categoryOptions = [
  { value: "DiagnosticReading", label: "DiagnosticReading" },
  { value: "Ailments", label: "Ailments" },
  { value: "Symptoms", label: "Symptoms" },
  { value: "Remedies", label: "Remedies" },
  { value: "Nutrition", label: "Nutrition" },
  { value: "Lifestyle", label: "Lifestyle" },
  { value: "Monitors", label: "Monitors" },
  { value: "Characteristics", label: "Characteristics" },
  { value: "Heriditory/Genetic", label: "Heriditory/Genetic" },
  { value: "Historic Damage", label: "Historic Damage" },
  { value: "Habitual", label: "Habitual" },
  { value: "Others", label: "Others" },
];

const typeOptions = [
  { value: "Atomic", label: "Basic/Atomic" },
  { value: "Derived", label: "Derived" },
  { value: "Algorithmic", label: "Evaluative/Algorithmic" },
];

export { useNodeOptions, categoryOptions, typeOptions };
