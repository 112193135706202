import React, { useState, useEffect } from "react";
import { Modal } from "@mui/material";
import Chart from "react-apexcharts";
import TextLabel from "../../../components/textLabel";
import TextInputField from "../../../components/inputField";
import PrimaryButton from "../../../components/primaryTextButton";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import "../../../App.css";
import { getNodeByNomenclature } from "../../../api/api";

const GraphModal = ({
  open,
  onClose,
  title,
  content1,
  onDataPointsSubmit,
  titleValue,
  labelValue,
  isEditing,
}) => {
  const [maleLowImpact, setMaleLowImpact] = useState([]);
  const [femaleLowImpact, setFemaleLowImpact] = useState([]);
  const [maleHighImpact, setMaleHighImpact] = useState([]);
  const [femaleHighImpact, setFemaleHighImpact] = useState([]);
  const [maleLowDataPoints, setMaleLowDataPoints] = useState([]);
  const [maleHighDataPoints, setMaleHighDataPoints] = useState([]);
  const [femaleLowDataPoints, setFemaleLowDataPoints] = useState([]);
  const [femaleHighDataPoints, setFemaleHighDataPoints] = useState([]);
  const [maleLowInputValue, setMaleLowInputValue] = useState("");
  const [maleHighInputValue, setMaleHighInputValue] = useState("");
  const [femaleLowInputValue, setFemaleLowInputValue] = useState("");
  const [femaleHighInputValue, setFemaleHighInputValue] = useState("");
  const [error, setError] = useState("");

  let xAxisMin;
  let xAxisMax;
  let yAxisMin = 0;
  let yAxisMax = 1;

  useEffect(() => {
    if (isEditing) {
      getEditNodeDetails();
    }
  }, [titleValue, isEditing]);

  useEffect(() => {
    if (isEditing || open) {
      handleUpdate();
    }
  }, [isEditing, open, titleValue, labelValue]);

  useEffect(() => {
    if (isEditing) {
      const maleLowImpactString = maleLowImpact
        ? maleLowImpact.map((point) => `(${point.x},${point.y})`).join(",")
        : "";
      setMaleLowInputValue(maleLowImpactString);

      const femaleLowImpactString = femaleLowImpact
        ? femaleLowImpact.map((point) => `(${point.x},${point.y})`).join(",")
        : "";
      setFemaleLowInputValue(femaleLowImpactString);

      const maleHighImpactString = maleHighImpact
        ? maleHighImpact.map((point) => `(${point.x},${point.y})`).join(",")
        : "";
      setMaleHighInputValue(maleHighImpactString);

      const femaleHighImpactString = femaleHighImpact
        ? femaleHighImpact.map((point) => `(${point.x},${point.y})`).join(",")
        : "";
      setFemaleHighInputValue(femaleHighImpactString);
    }
  }, [
    maleLowImpact,
    femaleLowImpact,
    maleHighImpact,
    femaleHighImpact,
    isEditing,
  ]);

  if (title === "Impact") {
    xAxisMin = 1;
    xAxisMax = 50;
  }

  const parseDataString = (dataString) => {
    if (typeof dataString !== "string") {
      return [];
    }
    const points = dataString.split("),(");
    return points.map((point) => {
      const [x, y] = point.replace("(", "").replace(")", "").split(",");
      return { x: parseFloat(x), y: parseFloat(y) };
    });
  };

  const getEditNodeDetails = async () => {
    try {
      const data = await getNodeByNomenclature(labelValue);
      if (data && data.length > 0) {
        const node = data[0];
        let parsedMaleLowImpact,
          parsedFemaleLowImpact,
          parsedMaleHighImpact,
          parsedFemaleHighImpact;
        if (titleValue.includes("Low")) {
          parsedMaleLowImpact = parseDataString(node.properties.impact.male);
          parsedFemaleLowImpact = parseDataString(
            node.properties.impact.female
          );
        } else if (titleValue.includes("High")) {
          parsedMaleHighImpact = parseDataString(node.properties.impact.male);
          parsedFemaleHighImpact = parseDataString(
            node.properties.impact.female
          );
        }
        setMaleLowImpact(parsedMaleLowImpact);
        setFemaleLowImpact(parsedFemaleLowImpact);
        setMaleHighImpact(parsedMaleHighImpact);
        setFemaleHighImpact(parsedFemaleHighImpact);
      }
    } catch (error) {
      console.error("Error fetching node details:", error);
    }
  };

  const validateDataPoints = (dataPoints) => {
    for (const point of dataPoints) {
      if (
        point.x < xAxisMin ||
        point.x > xAxisMax ||
        point.y < yAxisMin ||
        point.y > yAxisMax
      ) {
        return false;
      }
    }
    return true;
  };

  const parseDataPoints = (input) => {
    const regex = /\((\d+(\.\d+)?),(\d+(\.\d+)?)\)/g;
    const parsedPoints = [];
    let match;
    while ((match = regex.exec(input)) !== null) {
      const x = parseFloat(match[1]);
      const y = parseFloat(match[3]);
      if (!isNaN(x) && !isNaN(y)) {
        parsedPoints.push({ x, y });
      }
    }
    return parsedPoints;
  };

  useEffect(() => {
    const maleLowPoints = parseDataPoints(maleLowInputValue);
    const maleHighPoints = parseDataPoints(maleHighInputValue);
    const femaleLowPoints = parseDataPoints(femaleLowInputValue);
    const femaleHighPoints = parseDataPoints(femaleHighInputValue);

    setMaleLowDataPoints(maleLowPoints);
    setMaleHighDataPoints(maleHighPoints);
    setFemaleLowDataPoints(femaleLowPoints);
    setFemaleHighDataPoints(femaleHighPoints);
  }, [
    maleLowInputValue,
    maleHighInputValue,
    femaleLowInputValue,
    femaleHighInputValue,
  ]);

  const isValidInput = (input) => {
    const regex =
      /^(\(\d+(\.\d+)?,\d+(\.\d+)?\)(,\(\d+(\.\d+)?,\d+(\.\d+)?\))*)?$/;
    return regex.test(input);
  };

  const handleUpdate = () => {
    if (
      !isValidInput(maleLowInputValue) ||
      !isValidInput(maleHighInputValue) ||
      !isValidInput(femaleLowInputValue) ||
      !isValidInput(femaleHighInputValue)
    ) {
      setError("Please enter valid data points in the format (x,y).");
      return;
    }

    const maleLowPoints = parseDataPoints(maleLowInputValue);
    const maleHighPoints = parseDataPoints(maleHighInputValue);
    const femaleLowPoints = parseDataPoints(femaleLowInputValue);
    const femaleHighPoints = parseDataPoints(femaleHighInputValue);

    setMaleLowDataPoints(maleLowPoints);
    setMaleHighDataPoints(maleHighPoints);
    setFemaleLowDataPoints(femaleLowPoints);
    setFemaleHighDataPoints(femaleHighPoints);

    onDataPointsSubmit({
      maleLowPoints,
      maleHighPoints,
      femaleLowPoints,
      femaleHighPoints,
    });

    setError("");
    const modalContent = document.querySelector(".modal-content");
    if (modalContent) {
      modalContent.scrollTop = 0;
    }
  };

  const handleSubmit = () => {
    if (
      !isValidInput(maleLowInputValue) ||
      !isValidInput(maleHighInputValue) ||
      !isValidInput(femaleLowInputValue) ||
      !isValidInput(femaleHighInputValue)
    ) {
      setError("Please enter valid data points in the format (x,y).");
      return;
    }
    const maleLowPoints = parseDataPoints(maleLowInputValue);
    const maleHighPoints = parseDataPoints(maleHighInputValue);
    const femaleLowPoints = parseDataPoints(femaleLowInputValue);
    const femaleHighPoints = parseDataPoints(femaleHighInputValue);

    if (
      !validateDataPoints(maleLowPoints) ||
      !validateDataPoints(maleHighPoints) ||
      !validateDataPoints(femaleLowPoints) ||
      !validateDataPoints(femaleHighPoints)
    ) {
      setError(
        `Data points must be within the range: x axis - (${xAxisMin}-${xAxisMax}), y axis -(${yAxisMin}-${yAxisMax}).`
      );
      return;
    }

    if (
      maleLowPoints.length === 0 &&
      maleHighPoints.length === 0 &&
      femaleLowPoints.length === 0 &&
      femaleHighPoints.length === 0
    ) {
      setError("Please enter at least one data point.");
      return;
    }
    setMaleLowDataPoints(maleLowPoints);
    setMaleHighDataPoints(maleHighPoints);
    setFemaleLowDataPoints(femaleLowPoints);
    setFemaleHighDataPoints(femaleHighPoints);

    onDataPointsSubmit({
      maleLowPoints,
      maleHighPoints,
      femaleLowPoints,
      femaleHighPoints,
    });

    setError("");
    const modalContent = document.querySelector(".modal-content");
    if (modalContent) {
      modalContent.scrollTop = 0;
    }
  };

  const chartOptions = {
    colors: ["#092B6B"],
    chart: {
      type: "line",
      height: 400,
      toolbar: { show: false },
      zoom: { enabled: false },
    },
    stroke: { curve: "straight" },
    markers: { size: 3, colors: ["#092B6B"] },
    annotations: {
      yaxis: [
        {
          y: 0,
          strokeDashArray: 0,
          borderColor: "#092B6B",
          fillColor: "#092B6B",
          borderWidth: 2,
          opacity: 0.3,
        },
      ],
      xaxis: [
        {
          x: xAxisMin,
          strokeDashArray: 0,
          borderColor: "#092B6B",
          fillColor: "#092B6B",
          borderWidth: 2,
          opacity: 0.3,
        },
      ],
    },
    grid: { show: false, borderColor: "#ff0000" },
    xaxis: { type: "numeric", min: xAxisMin, max: xAxisMax },
    yaxis: { min: 0, max: 1 },
  };

  const maleLowSeries = [
    { name: "Male Low Data Points", data: maleLowDataPoints },
    isEditing && { name: "Male Low Data Points", data: maleLowDataPoints },
    isEditing &&
      Array.isArray(maleLowImpact) &&
      maleLowImpact.length > 0 && {
        name: "Male Low Impact",
        data: maleLowImpact,
      },
  ].filter(Boolean);

  const maleHighSeries = [
    { name: "Male High Data Points", data: maleHighDataPoints },
    isEditing && { name: "Male High Data Points", data: maleHighDataPoints },
    isEditing &&
      Array.isArray(maleHighImpact) &&
      maleHighImpact.length > 0 && {
        name: "Male High Impact",
        data: maleHighImpact,
      },
  ].filter(Boolean);

  const femaleLowSeries = [
    { name: "Female Low Data Points", data: femaleLowDataPoints },
    isEditing && { name: "Female Low Data Points", data: femaleLowDataPoints },
    isEditing &&
      Array.isArray(femaleLowImpact) &&
      femaleLowImpact.length > 0 && {
        name: "Female Low Impact",
        data: femaleLowImpact,
      },
  ].filter(Boolean);

  const femaleHighSeries = [
    { name: "Female High Data Points", data: femaleHighDataPoints },
    isEditing && {
      name: "Female High Data Points",
      data: femaleHighDataPoints,
    },
    isEditing &&
      Array.isArray(femaleHighImpact) &&
      femaleHighImpact.length > 0 && {
        name: "Female High Impact",
        data: femaleHighImpact,
      },
  ].filter(Boolean);

  return (
    <Modal
      open={open}
      onClose={onClose}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      className="inner-content-scroll"
    >
      <div
        style={{
          backgroundColor: "white",
          padding: "40px",
          width: "80%",
          maxWidth: "800px",
          maxHeight: "500px",
          borderRadius: "2px",
          overflowX: "hidden",
        }}
        className="inner-content-scroll modal-content"
      >
        <div
          style={{
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <CancelOutlinedIcon
            onClick={onClose}
            style={{ color: "#666666", width: 30, height: 30 }}
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "20px",
          }}
        >
          <TextLabel fontWeight={600}>
            {title} - {content1}
          </TextLabel>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          {(!isEditing || (titleValue.includes("High") && isEditing)) && (
            <div
              style={{
                width:
                  titleValue.includes("High") && isEditing ? "100%" : "44%",
                border: "1px solid #999999",
                marginBottom: "20px",
              }}
            >
              <Chart
                options={chartOptions}
                series={
                  isEditing
                    ? [
                        {
                          name: "Male High DataPoints",
                          data: maleHighDataPoints,
                        },
                      ] || [{ name: "Male High Impact", data: maleHighImpact }]
                    : maleHighSeries
                }
                type="line"
                width="100%"
              />
            </div>
          )}

          {(!isEditing || (titleValue.includes("Low") && isEditing)) && (
            <div
              style={{
                width: titleValue.includes("Low") && isEditing ? "100%" : "44%",
                border: "1px solid #999999",
                marginBottom: "20px",
              }}
            >
              <Chart
                options={chartOptions}
                series={
                  isEditing
                    ? [
                        {
                          name: "Male Low DataPoints",
                          data: maleLowDataPoints,
                        },
                      ] || [{ name: "Male Low Impact", data: maleLowImpact }]
                    : maleLowSeries
                }
                type="line"
                width="100%"
              />
            </div>
          )}

<div style={{ width: "100%" }}>
            <TextLabel fontSize="18px" fontWeight={600}
              customStyles={{ marginTop: "15px", marginBottom: "15px" }}
            >
              Male
            </TextLabel>
          </div>
          {(!isEditing || (titleValue.includes("High") && isEditing)) && (
            <div
              style={{
                width:
                  titleValue.includes("High") && isEditing ? "100%" : "44%",
              }}
            >
              <TextLabel fontSize="15px" fontWeight={500}>Enter Male High Values</TextLabel>
              <TextLabel fontSize="14px" fontWeight={500} color="#666666" customStyles={{ marginTop: "10px" }}>
                (1,0.2),(2,0.3)
              </TextLabel>
              <TextInputField
                placeholder="Enter Male X, Y values"
                variant="outlined"
                value={maleHighInputValue}
                onChange={(e) => setMaleHighInputValue(e.target.value)}
                fullWidth
              />
            </div>
          )}
          {(!isEditing || (titleValue.includes("Low") && isEditing)) && (
            <div
              style={{
                width: titleValue.includes("Low") && isEditing ? "100%" : "44%",
              }}
            >
              <TextLabel fontSize="15px" fontWeight={500}>Enter Male Low Values</TextLabel>
              <TextLabel fontSize="14px" fontWeight={500} color="#666666" customStyles={{ marginTop: "10px" }}>
                (1,0.2),(2,0.3)
              </TextLabel>
              <TextInputField
                placeholder="Enter Male X, Y values"
                variant="outlined"
                value={maleLowInputValue}
                onChange={(e) => setMaleLowInputValue(e.target.value)}
                fullWidth
              />
            </div>
          )}

          {(!isEditing || (titleValue.includes("High") && isEditing)) && (
            <div
              style={{
                width:
                  titleValue.includes("High") && isEditing ? "100%" : "44%",
                marginTop: "20px",
                border: "1px solid #999999",
                marginBottom: "20px",
              }}
            >
              <Chart
                options={chartOptions}
                series={
                  isEditing
                    ? [
                        {
                          name: "Female High DataPoints",
                          data: femaleHighDataPoints,
                        },
                      ] || [
                        { name: "Female High Impact", data: femaleHighImpact },
                      ]
                    : femaleHighSeries
                }
                type="line"
                width="100%"
              />
            </div>
          )}

          {(!isEditing || (titleValue.includes("Low") && isEditing)) && (
            <div
              style={{
                width: titleValue.includes("Low") && isEditing ? "100%" : "44%",
                marginTop: "20px",
                border: "1px solid #999999",
                marginBottom: "20px",
              }}
            >
              <Chart
                options={chartOptions}
                series={
                  isEditing
                    ? [
                        {
                          name: "Female Low DataPoints",
                          data: femaleLowDataPoints,
                        },
                      ] || [
                        { name: "Female Low Impact", data: femaleLowImpact },
                      ]
                    : femaleLowSeries
                }
                type="line"
                width="100%"
              />
            </div>
          )}

         
          <div style={{ width: "100%" }}>
            <TextLabel
              fontSize="18px" fontWeight={600}
              customStyles={{ marginTop: "15px", marginBottom: "15px" }}
            >
              Female
            </TextLabel>
          </div>

          {(!isEditing || (titleValue.includes("High") && isEditing)) && (
            <div
              style={{
                width:
                  titleValue.includes("High") && isEditing ? "100%" : "44%",
              }}
            >
              <TextLabel fontSize="15px" fontWeight={500}>Enter Female High Values</TextLabel>
              <TextLabel  fontSize="14px" fontWeight={500} color="#666666" customStyles={{ marginTop: "10px" }}>
                (1,0.2),(2,0.3)
              </TextLabel>
              <TextInputField
                placeholder="Enter Female X, Y values"
                variant="outlined"
                value={femaleHighInputValue}
                onChange={(e) => setFemaleHighInputValue(e.target.value)}
                fullWidth
              />
            </div>
          )}
          {(!isEditing || (titleValue.includes("Low") && isEditing)) && (
            <div
              style={{
                width: titleValue.includes("Low") && isEditing ? "100%" : "44%",
              }}
            >
              <TextLabel fontSize="15px" fontWeight={500}>Enter Female Low Values</TextLabel>
              <TextLabel fontSize="14px" fontWeight={500} color="#666666" customStyles={{ marginTop: "10px" }}>
                (1,0.2),(2,0.3)
              </TextLabel>
              <TextInputField
                placeholder="Enter Female X, Y values"
                variant="outlined"
                value={femaleLowInputValue}
                onChange={(e) => setFemaleLowInputValue(e.target.value)}
                fullWidth
              />
            </div>
          )}
        </div>
        {error && (
          <div style={{ color: "red", marginTop: "10px" }}>{error}</div>
        )}
        <div
          style={{ display: "flex", justifyContent: "end", marginTop: "20px" }}
        >
          {isEditing ? (
            <PrimaryButton
              onClick={handleUpdate}
              variant="contained"
              color="primary"
            >
              Update
            </PrimaryButton>
          ) : (
            <PrimaryButton
              onClick={handleSubmit}
              variant="contained"
              color="primary"
            >
              Submit
            </PrimaryButton>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default GraphModal;
