import React from "react";
import { Box, Grid } from "@mui/material";
import TextInputField from "../../components/inputField";
import Dropdown from "../../components/DropDown";
import ErrorMessage from "../../components/errorMessage";
import TextareaAutosize from "../../components/textarea";
import PrimaryButton from "../../components/primaryTextButton";
import TextLabel from "../../components/textLabel";
import { SeverityGraphValues } from "./lowHighValues/severityGraphValues";
import { SeverityGradientGraphValues } from "./gradientValues/severityGradientGraphValues";
import { AgeGradientGraphValues } from "./gradientValues/ageGradientGraphValues";
import { AgeGraphValues } from "./lowHighValues/ageGraphValues";
import {
  GraphValuesWithSpacing,
  GraphValues,
} from "./lowHighValues/impactGraphValues";
import {
  GradientGraphValuesWithSpacing,
  GradientGraphValues,
} from "./gradientValues/gradientGraphValues";
import { categoryOptions, typeOptions } from "../../components/DropdownOptions";
const CreateNodeForm = ({
  formData,
  errors,
  isEditing,
  handleCategoryChange,
  handleTitleChange,
  handleLabelChange,
  handlemaleLowValueChange,
  handlemaleHighValueChange,
  handlemaleUnitValueChange,
  handlefemaleUnitValueChange,
  handlefemaleLowValueChange,
  handlefemaleHighValueChange,
  handleImpactDataPointsSubmit,
  handleSeverityDataPointsSubmit,
  handleAgeDataPointsSubmit,
  handleGradientValuesChange,
  handleGradientImpactDataPointsSubmit,
  handleGradientSeverityDataPointsSubmit,
  handleGradientAgeDataPointsSubmit,
  gradientErrors,
  handleSubmit,
  existingTitleError,
  existingLabelError,
  showUnitInput,
  setFormData,
  setErrors,
}) => {
  const showGradientGraphValues =
    formData.selectedCategory === "DiagnosticReading" &&
    formData.gradientValues &&
    Object.values(formData.gradientValues).some((value) => value.trim() !== "");
  const showHighLowValues =
    formData.maleLowValue !== "" ||
    formData.maleHighValue !== "" ||
    formData.femaleLowValue !== "" ||
    formData.femaleHighValue !== "" ||
    formData.maleUnitValue !== "" ||
    formData.femaleUnitValue !== "";

  const hidehighLowValues = isEditing && showGradientGraphValues;
  
  return (
    <div>
      <Grid sx={{ pt: "1rem", px: "4.25rem" }}>
        <Grid item xs={12}>
          <Box
            border={1}
            borderColor="rgba(153, 153, 153, 0.5)"
            borderRadius={0}
            bgcolor="background.paper"
            p={2}
            sx={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              minHeight: "50px",
            }}
          >
            <Grid sx={{ pt: "1.625rem", pl: "4.25rem", pr: "4.25rem" }}>
              <Grid
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", md: "row" },
                }}
              >
                <Grid
                  item
                  xs={12}
                  md={6}
                  sx={{ mr: "1.125rem", width: "100%" }}
                >
                  <TextLabel fontWeight={600} fontSize="18px">
                    Category
                  </TextLabel>
                  <Dropdown
                    options={categoryOptions}
                    value={formData.selectedCategory}
                    onChange={handleCategoryChange}
                    placeholder="Choose Category"
                    mb={errors.selectedCategory ? "0rem" : "1.875rem"}
                  />
                  {errors.selectedCategory && (
                    <ErrorMessage message={errors.selectedCategory} />
                  )}
                </Grid>
                <Grid item xs={12} md={6} sx={{ width: "100%" }}>
                  <TextLabel fontWeight={600} fontSize="18px">
                    Type
                  </TextLabel>
                  <Dropdown
                    options={typeOptions}
                    value={formData.selectedType}
                    onChange={(event) => {
                      setFormData((prevFormData) => ({
                        ...prevFormData,
                        selectedType: event.target.value,
                      }));
                      setErrors((prevErrors) => ({
                        ...prevErrors,
                        selectedType: "",
                      }));
                    }}
                    placeholder="Choose Type"
                    mb={errors.selectedType ? "0rem" : "1.875rem"}
                  />
                  {errors.selectedType && (
                    <ErrorMessage message={errors.selectedType} />
                  )}
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <TextLabel fontWeight={600} fontSize="18px">
                  Title
                </TextLabel>
                <TextInputField
                  fullWidth
                  height="49px"
                  value={formData.titleValue}
                  onChange={handleTitleChange}
                  sx={{
                    mb:
                      (errors.titleValue && !formData.titleValue) ||
                      existingTitleError
                        ? "0rem"
                        : "1.875rem",
                  }}
                  inputProps={{ maxLength: 255 }}
                />
                {existingTitleError && (
                  <ErrorMessage message="Title already exists. Please type a different title." />
                )}
                {errors.titleValue && !formData.titleValue && (
                  <ErrorMessage message={errors.titleValue} />
                )}
              </Grid>
              <Grid item xs={12}>
                <TextLabel fontWeight={600} fontSize="18px">
                  Label
                </TextLabel>
                <TextInputField
                  fullWidth
                  height="49px"
                  value={formData.labelValue}
                  onChange={handleLabelChange}
                  disabled={isEditing}
                  sx={{
                    mb:
                      (errors.labelValue && !formData.labelValue) ||
                      existingLabelError
                        ? "0rem"
                        : "1.875rem",
                  }}
                />
                {existingLabelError && (
                  <ErrorMessage message="Label already exists. Please type a different Label." />
                )}
                {errors.labelValue && !formData.labelValue && (
                  <ErrorMessage message={errors.labelValue} />
                )}
              </Grid>
              <Grid
                sx={{ display: "flex", flexDirection: "row", mt: "1.25rem" }}
              >
                <Grid
                  item
                  xs={12}
                  md={5}
                  sx={{
                    display: "flex",
                    flexDirection: { xs: "column", md: "row" },
                  }}
                >
                  <Grid
                    item
                    sx={{
                      display: "flex",
                      flexDirection: { xs: "column" },
                    }}
                  >
                    {((isEditing &&
                      formData.selectedCategory === "DiagnosticReading") ||
                      (showUnitInput &&
                        formData.selectedCategory === "DiagnosticReading")) &&
                      !hidehighLowValues && (
                        <>
                          <Grid item xs={12} md={12} sx={{ mb: "1.25rem" }}>
                            <TextLabel fontWeight={600} fontSize="18px">
                              Male Graph Values:
                            </TextLabel>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            md={5}
                            sx={{
                              display: "flex",
                              flexDirection: { xs: "column", md: "row" },
                            }}
                          >
                            <Grid item xs={12} md={2} sx={{ mr: "1.125rem" }}>
                              <TextLabel fontWeight={600} fontSize="18px">
                                Low Value
                              </TextLabel>
                              <TextInputField
                                fullWidth
                                value={formData.maleLowValue}
                                onChange={handlemaleLowValueChange}
                                sx={{
                                  outline: "none",
                                  mt: "0.813rem",
                                  mb: errors.maleLowValue ? "0rem" : "1.875rem",
                                  borderColor: formData.gradientValues.gradient1
                                    ? "grey"
                                    : "inherit",
                                }}
                                disabled={
                                  formData.gradientValues.gradient1 !== "" ||
                                  formData.gradientValues.gradient2 !== "" ||
                                  formData.gradientValues.gradient3 !== "" ||
                                  formData.gradientValues.gradient4 !== ""
                                }
                              />
                              {errors.maleLowValue && (
                                <ErrorMessage message={errors.maleLowValue} />
                              )}
                            </Grid>
                            <Grid item xs={12} md={2} sx={{ mr: "1.125rem" }}>
                              <TextLabel fontWeight={600} fontSize="18px">
                                High Value
                              </TextLabel>
                              <TextInputField
                                fullWidth
                                value={formData.maleHighValue}
                                onChange={handlemaleHighValueChange}
                                sx={{
                                  outline: "none",
                                  mt: "0.813rem",
                                  mb: errors.maleHighValue
                                    ? "0rem"
                                    : "1.875rem",
                                }}
                                disabled={
                                  formData.gradientValues.gradient1 !== "" ||
                                  formData.gradientValues.gradient2 !== "" ||
                                  formData.gradientValues.gradient3 !== "" ||
                                  formData.gradientValues.gradient4 !== ""
                                }
                              />
                              {errors.maleHighValue && (
                                <ErrorMessage message={errors.maleHighValue} />
                              )}
                            </Grid>
                            <Grid item xs={12} md={2}>
                              <TextLabel fontWeight={600} fontSize="18px">
                                Unit
                              </TextLabel>
                              <TextInputField
                                fullWidth
                                value={formData.maleUnitValue}
                                onChange={handlemaleUnitValueChange}
                                sx={{
                                  outline: "none",
                                  mt: "0.813rem",
                                  mb: errors.maleUnitValue
                                    ? "0rem"
                                    : "1.875rem",
                                }}
                                disabled={
                                  formData.gradientValues.gradient1 !== "" ||
                                  formData.gradientValues.gradient2 !== "" ||
                                  formData.gradientValues.gradient3 !== "" ||
                                  formData.gradientValues.gradient4 !== ""
                                }
                              />
                              {errors.maleunitValue && (
                                <ErrorMessage message={errors.maleUnitValue} />
                              )}
                            </Grid>
                          </Grid>
                        </>
                      )}
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={5}
                  sx={{
                    display: "flex",
                    flexDirection: { xs: "column", md: "row" },
                  }}
                >
                  <Grid
                    item
                    sx={{
                      display: "flex",
                      flexDirection: { xs: "column" },
                    }}
                  >
                    {((isEditing &&
                      formData.selectedCategory === "DiagnosticReading") ||
                      (showUnitInput &&
                        formData.selectedCategory === "DiagnosticReading")) &&
                      !hidehighLowValues && (
                        <>
                          <Grid
                            item
                            xs={12}
                            md={12}
                            sx={{ ml: "6rem", mb: "1.25rem" }}
                          >
                            <TextLabel fontWeight={600} fontSize="18px">
                              Female Graph Values:
                            </TextLabel>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            md={5}
                            sx={{
                              display: "flex",
                              flexDirection: { xs: "column", md: "row" },
                            }}
                          >
                            <Grid
                              item
                              xs={12}
                              md={2}
                              sx={{ ml: "6rem", mr: "1.125rem" }}
                            >
                              <TextLabel fontWeight={600} fontSize="18px">
                                Low Value
                              </TextLabel>
                              <TextInputField
                                fullWidth
                                value={formData.femaleLowValue}
                                onChange={handlefemaleLowValueChange}
                                sx={{
                                  outline: "none",
                                  mt: "0.813rem",
                                  mb: errors.femaleLowValue
                                    ? "0rem"
                                    : "1.875rem",
                                }}
                                disabled={
                                  formData.gradientValues.gradient1 !== "" ||
                                  formData.gradientValues.gradient2 !== "" ||
                                  formData.gradientValues.gradient3 !== "" ||
                                  formData.gradientValues.gradient4 !== ""
                                }
                              />
                              {errors.femaleLowValue && (
                                <ErrorMessage message={errors.femaleLowValue} />
                              )}
                            </Grid>
                            <Grid item xs={12} md={2} sx={{ mr: "1.125rem" }}>
                              <TextLabel fontWeight={600} fontSize="18px">
                                High Value
                              </TextLabel>
                              <TextInputField
                                fullWidth
                                value={formData.femaleHighValue}
                                onChange={handlefemaleHighValueChange}
                                sx={{
                                  outline: "none",
                                  mt: "0.813rem",
                                  mb: errors.femaleHighValue
                                    ? "0rem"
                                    : "1.875rem",
                                }}
                                disabled={
                                  formData.gradientValues.gradient1 !== "" ||
                                  formData.gradientValues.gradient2 !== "" ||
                                  formData.gradientValues.gradient3 !== "" ||
                                  formData.gradientValues.gradient4 !== ""
                                }
                              />
                              {errors.femaleHighValue && (
                                <ErrorMessage
                                  message={errors.femaleHighValue}
                                />
                              )}
                            </Grid>
                            <Grid item xs={12} md={2}>
                              <TextLabel fontWeight={600} fontSize="18px">
                                Unit
                              </TextLabel>
                              <TextInputField
                                fullWidth
                                value={formData.femaleUnitValue}
                                onChange={handlefemaleUnitValueChange}
                                sx={{
                                  outline: "none",
                                  mt: "0.813rem",
                                  mb: errors.unitValue ? "0rem" : "1.875rem",
                                }}
                                disabled={
                                  formData.gradientValues.gradient1 !== "" ||
                                  formData.gradientValues.gradient2 !== "" ||
                                  formData.gradientValues.gradient3 !== "" ||
                                  formData.gradientValues.gradient4 !== ""
                                }
                              />
                              {errors.unitValue && (
                                <ErrorMessage message={errors.unitValue} />
                              )}
                            </Grid>
                          </Grid>
                        </>
                      )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                {((isEditing &&
                  formData.selectedCategory === "DiagnosticReading") ||
                  (showUnitInput &&
                    formData.selectedCategory === "DiagnosticReading"))  && (
                  <>
                    <Grid item xs={12} md={2}>
                      <Grid container direction="column">
                        <TextLabel fontWeight={600} fontSize="18px">
                          Gradient Values
                        </TextLabel>
                        <TextInputField
                          fullWidth
                          value={formData.gradientValues.gradient1}
                          onChange={(event) =>
                            handleGradientValuesChange(event, "gradient1")
                          }
                          sx={{ outline: "none", mb: "1.875rem" }}
                          disabled={!isEditing && showHighLowValues}
                        />
                      </Grid>
                      {gradientErrors.gradient1 && (
                        <ErrorMessage message={gradientErrors.gradient1} />
                      )}
                    </Grid>
                    <Grid item xs={12} md={2} sx={{ mt: "1rem" }}>
                      <Grid container direction="column">
                        <TextInputField
                          fullWidth
                          value={formData.gradientValues.gradient2}
                          onChange={(event) =>
                            handleGradientValuesChange(event, "gradient2")
                          }
                          sx={{ outline: "none", mb: "1.875rem" }}
                          disabled={!isEditing && showHighLowValues}
                        />
                      </Grid>
                      {gradientErrors.gradient2 && (
                        <ErrorMessage message={gradientErrors.gradient2} />
                      )}
                    </Grid>
                    <Grid item xs={12} md={2} sx={{ mt: "1rem" }}>
                      <Grid container direction="column">
                        <TextInputField
                          fullWidth
                          value={formData.gradientValues.gradient3}
                          onChange={(event) =>
                            handleGradientValuesChange(event, "gradient3")
                          }
                          sx={{ outline: "none", mb: "1.875rem" }}
                          disabled={!isEditing && showHighLowValues}
                        />
                      </Grid>
                      {gradientErrors.gradient3 && (
                        <ErrorMessage message={gradientErrors.gradient3} />
                      )}
                    </Grid>
                    <Grid item xs={12} md={2} sx={{ mt: "1rem" }}>
                      <Grid container direction="column">
                        <TextInputField
                          fullWidth
                          value={formData.gradientValues.gradient4}
                          onChange={(event) =>
                            handleGradientValuesChange(event, "gradient4")
                          }
                          sx={{ outline: "none", mb: "1.875rem" }}
                          disabled={!isEditing && showHighLowValues}
                        />
                      </Grid>
                      {gradientErrors.gradient4 && (
                        <ErrorMessage message={gradientErrors.gradient4} />
                      )}
                    </Grid>
                  </>
                )}
              </Grid>
              <Grid item xs={12}>
                <TextLabel fontWeight={600} fontSize="18px">
                  What
                </TextLabel>
                <div
                  style={{
                    marginTop: "1rem",
                    marginBottom: errors.whatValue ? "0rem" : "1.875rem",
                  }}
                >
                  <TextareaAutosize
                    value={formData.whatValue}
                    onChange={(event) => {
                      setFormData((prevFormData) => ({
                        ...prevFormData,
                        whatValue: event.target.value,
                      }));
                      setErrors((prevErrors) => ({
                        ...prevErrors,
                        whatValue: "",
                      }));
                    }}
                  />
                </div>
                {errors.whatValue && (
                  <ErrorMessage message={errors.whatValue} />
                )}
              </Grid>
              <Grid item xs={12}>
                <TextLabel fontWeight={600} fontSize="18px">
                  Why
                </TextLabel>
                <div
                  style={{
                    marginTop: "1rem",
                    marginBottom: errors.whyValue ? "0rem" : "1.875rem",
                  }}
                >
                  <TextareaAutosize
                    value={formData.whyValue}
                    onChange={(event) => {
                      setFormData((prevFormData) => ({
                        ...prevFormData,
                        whyValue: event.target.value,
                      }));
                      setErrors((prevErrors) => ({
                        ...prevErrors,
                        whyValue: "",
                      }));
                    }}
                  />
                </div>
                {errors.whyValue && <ErrorMessage message={errors.whyValue} />}
              </Grid>
              {showGradientGraphValues ? (
                <GradientGraphValuesWithSpacing>
                  <GradientGraphValues
                    title="Impact"
                    content1="Impact based on the changes in the value"
                    content2="Eg. RBC Value vs Importance"
                    titleValue={formData.titleValue}
                    labelValue={formData.labelValue}
                    isEditing={isEditing}
                    onDataPointsGradientSubmit={
                      handleGradientImpactDataPointsSubmit
                    }
                  >
                    <PrimaryButton>Enter Graph Value</PrimaryButton>
                  </GradientGraphValues>
                  <SeverityGradientGraphValues
                    title="Severity"
                    content1="Severity based on the history of values."
                    content2="Eg. RBC Value vs Importance"
                    titleValue={formData.titleValue}
                    labelValue={formData.labelValue}
                    isEditing={isEditing}
                    onDataPointsSeverityGradientSubmit={
                      handleGradientSeverityDataPointsSubmit
                    }
                  >
                    <PrimaryButton>Enter Graph Value</PrimaryButton>
                  </SeverityGradientGraphValues>
                  <AgeGradientGraphValues
                    title="Age"
                    content1="The change in importance based on age."
                    content2="Eg. RBC Value vs Importance"
                    titleValue={formData.titleValue}
                    labelValue={formData.labelValue}
                    isEditing={isEditing}
                    onDataPointsAgeGradientSubmit={
                      handleGradientAgeDataPointsSubmit
                    }
                  >
                    <PrimaryButton>Enter Graph Value</PrimaryButton>
                  </AgeGradientGraphValues>
                </GradientGraphValuesWithSpacing>
              ) : (
                (formData.selectedCategory === "DiagnosticReading" ||
                  (showUnitInput &&
                    formData.selectedCategory === "DiagnosticReading")) && (
                  <Grid item xs={12}>
                    <TextLabel fontWeight={600} fontSize="18px">
                      Check/ Enter graph values
                    </TextLabel>
                    <Grid
                      sx={{
                        display: "flex",
                        flexDirection: { xs: "column", lg: "row" },
                        mt: "1.25rem",
                      }}
                    >
                      <GraphValuesWithSpacing>
                        <GraphValues
                          title="Impact"
                          content1="Impact based on the changes in the value"
                          content2="Eg. RBC Value vs Importance"
                          maleLowValue={formData.maleLowValue}
                          titleValue={formData.titleValue}
                          labelValue={formData.labelValue}
                          maleHighValue={formData.maleHighValue}
                          femaleLowValue={formData.femaleLowValue}
                          femaleHighValue={formData.femaleHighValue}
                          onDataPointsSubmit={handleImpactDataPointsSubmit}
                          isEditing={isEditing}
                        >
                          <PrimaryButton>Enter Graph Value</PrimaryButton>
                        </GraphValues>
                        <SeverityGraphValues
                          title="Severity"
                          content1="Severity based on the history of values."
                          content2="Eg. RBC Value vs Importance"
                          onDataPointsSeveritySubmit={
                            handleSeverityDataPointsSubmit
                          }
                          maleLowValue={formData.maleLowValue}
                          titleValue={formData.titleValue}
                          labelValue={formData.labelValue}
                          maleHighValue={formData.maleHighValue}
                          femaleLowValue={formData.femaleLowValue}
                          femaleHighValue={formData.femaleHighValue}
                          isEditing={isEditing}
                        >
                          <PrimaryButton>Enter Graph Value</PrimaryButton>
                        </SeverityGraphValues>
                        <AgeGraphValues
                          title="Age"
                          content1="The change in importance based on age."
                          content2="Eg. RBC Value vs Importance"
                          onDataPointsAgeSubmit={handleAgeDataPointsSubmit}
                          titleValue={formData.titleValue}
                          labelValue={formData.labelValue}
                          isEditing={isEditing}
                          maleHighValue={formData.maleHighValue}
                          femaleLowValue={formData.femaleLowValue}
                          femaleHighValue={formData.femaleHighValue}
                        >
                          <PrimaryButton>Enter Graph Value</PrimaryButton>
                        </AgeGraphValues>
                      </GraphValuesWithSpacing>
                    </Grid>
                  </Grid>
                )
              )}
              <Grid item xs={12} sx={{ textAlign: "right", mb: "3.125rem" }}>
                <PrimaryButton onClick={handleSubmit}>
                  {isEditing ? "Save" : "Submit"}
                </PrimaryButton>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};
export default CreateNodeForm;
