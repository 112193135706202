import React from "react";
import { Link } from "@mui/material";

const TextButtonLink = ({onClick,children,fontSize,...sx}) => {
  return (
  <div>
<Link
  component="button"
  variant="body1"
  onClick={onClick}
  style={{
    color: "#174FB6",
    fontWeight: "600",
    fontSize: fontSize || "20px" ,
    cursor: "pointer",
    textDecoration: "underline",
    border: "none",
    background: "none",
    textTransform: "capitalize",
  }}
  {...sx}
>
{children}
</Link>
</div>
  );
};

export default TextButtonLink;
