import React from "react";
import { useNavigate } from "react-router-dom";
import PrimaryButton from "../components/primaryTextButton";
import { Box, Container, Grid } from "@mui/material";
import SuccessIcon from "../assets/SuccessIcon.svg";
import TextLabel from "../components/textLabel";
import TitleLabel from "../components/titleLabel";

export default function PasswordSuccess() {
  const navigate = useNavigate();

  const passwordSuccess = () => {
    navigate("/");
  };

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      sx={{ minHeight: "100vh" }}
    >
      <Box sx={{ textAlign: "center" }}>
        <img
          src={SuccessIcon}
          alt="Success Icon"
          sx={{ color: "#F5A623", fontSize: 30 }}
        />
        <TitleLabel
          fontSize="1.625rem"
          fontWeight={600}
          sx={{ textAlign: "center" }}
        >
          Password Changed!
        </TitleLabel>
        <Container maxWidth="xs">
          <TextLabel
            fontWeight={600}
            fontSize="16px"
            lineHeight="1.25rem"
            sx={{ marginTop: "10px" }}
          >
            Your password has been changed successfully. <br />
            Use your new password to log in.
          </TextLabel>
        </Container>
      </Box>
      <Box sx={{ textAlign: "center", marginTop: 2 }}>
        <PrimaryButton onClick={passwordSuccess}>Continue</PrimaryButton>
      </Box>
    </Grid>
  );
}
