const initialState = {
  email: "",
  username: "",
  accessToken: "",
  header: {},
};

const sessionReducer = (state = initialState, action) => {
  switch (action.type) {
    case "LOGIN":
      return {
        ...state,
        email: action.email,
        username: action.username,
        accessToken: action.accessToken,
        header: {
          Authorization: "Bearer " + action.accessToken,
        },
      };
    case "REFRESH_ACCESS_TOKEN":
      return {
        ...state,
        accessToken: action.accessToken,
      };
    case "UPDATE_USERNAME":
      return {
        ...state,
        username: action.newUserName,
      };
    case "LOGOUT":
      return {
        email: "",
        username: "",
        accessToken: "",
        header: {},
      };
    default:
      return state;
  }
};
export default sessionReducer;
