import React, { useState } from "react";
import {
  Container,
  Grid,
  Box,
  Typography,
  Button,
  TextField,
} from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { Auth } from "aws-amplify";
import Logo from "../components/logo";
import TitleLabel from "../components/titleLabel";
import ErrorMessage from "../components/errorMessage";
import PrimaryButton from "../components/primaryTextButton";
import FooterLabel from "../components/footerLabel";
import genaiLogo from "../assets/genaiLogo.svg";

export default function Conformation() {
  const navigate = useNavigate();
  const location = useLocation();
  const [authCode, setAuthCode] = useState("");
  const [authCodeError, setAuthCodeError] = useState("");
  const [confirmationError, setConfirmationError] = useState("");

  const handleCodeChange = (event) => {
    const inputCode = event.target.value;
    const numericRegex = /^[0-9]*$/;
    if (numericRegex.test(inputCode)) {
      setAuthCode(inputCode);
      setAuthCodeError("");
    }
  };

  const confirmCode = async () => {
    if (authCode.length === 0) {
      setAuthCodeError("Please enter the code");
    } else {
      try {
        const email = location.state.email;
        await Auth.confirmSignUp(email, authCode);
        navigate("/");
      } catch (error) {
        setConfirmationError(error.message);
      }
    }
  };

  const resendCode = async () => {
    try {
      await Auth.resendSignUp(location.state.email);
    } catch (error) {
      setConfirmationError(error.message);
    }
  };

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      sx={{ minHeight: "100vh" }}
    >
      <div style={{ marginTop: "4rem" }}>
        <Logo />
      </div>
      <TitleLabel fontWeight={600} sx={{ mt: "3rem" }}>
        Enter Verification Code
      </TitleLabel>
      <Container>
        <Typography
          variant="body2"
          style={{
            marginBottom: "1.5rem",
            marginLeft: "0.75rem",
            marginRight: "0.75rem",
            textAlign: "center",
          }}
        >
          Enter your verification code that we sent through your email address
        </Typography>
      </Container>
      <Container maxWidth="xs">
        <TextField
          fullWidth
          placeholder="Enter Verification Code"
          value={authCode}
          onChange={handleCodeChange}
          inputProps={{ maxLength: 6, inputMode: "numeric" }}
          sx={{
            marginTop: "0.5rem",
            marginBottom: authCodeError ? 0 : 3,
            "& .MuiOutlinedInput-root": {
              "&.Mui-focused fieldset": {
                border: "0.031rem solid #999999",
              },
              "&:hover fieldset": {
                border: "0.031rem solid #999999",
              },
            },
          }}
        />
        {authCodeError && <ErrorMessage message={authCodeError} />}
        {confirmationError && <ErrorMessage message={confirmationError} />}
        <PrimaryButton
          fullWidth
          type="button"
          variant="contained"
          onClick={confirmCode}
        >
          Submit
        </PrimaryButton>
        <div
          style={{
            marginTop: "1rem",
            textAlign: "center",
            fontSize: "0.875rem",
            fontWeight: "500",
            fontFamily: "Mulish",
          }}
        >
          Didn't you receive the code?
          <Button
            onClick={resendCode}
            variant="text"
            style={{
              marginLeft: "0.5rem",
              fontSize: "0.875rem",
              fontWeight: "600",
              color: "#174FB6",
              textTransform: "none",
            }}
          >
            Resend Code
          </Button>
        </div>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          sx={{ mt: "6rem", mb: "2.5rem" }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <FooterLabel
              fontWeight={400}
              fontSize={16}
              sx={{ display: "inline" }}
            >
              Powered By
            </FooterLabel>
            <Box
              component="img"
              src={genaiLogo}
              width="200px"
              height="40px"
              sx={{ display: "inline", ml: "10px" }}
            />
          </Box>
        </Grid>
      </Container>
    </Grid>
  );
}
