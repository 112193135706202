import React, { useState, useEffect } from "react";
import { Modal } from "@mui/material";
import Chart from "react-apexcharts";
import TextLabel from "../../components/textLabel";
import TextInputField from "../../components/inputField";
import PrimaryButton from "../../components/primaryTextButton";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import "../../App.css";
import { getLinksBetweenNodes } from "../../api/api";

const LinkAgeGraphModal = ({
  open,
  onClose,
  title,
  reset,
  isEditing,
  content1,
  onDataPointsLinkAgeSubmit,
  selectedStartNode,
  selectedEndNode,
}) => {
  const [ageLink, setAgeLink] = useState([]);
  const [dataPoints, setDataPoints] = useState([]);
  const [ageInputValue, setAgeInputValue] = useState("");
  const [error, setError] = useState("");

  let xAxisMin = 0;
  let xAxisMax = 10;
  let yAxisMin = 0;
  let yAxisMax = 1;

  if (title === "Age") {
    xAxisMin = 12;
    xAxisMax = 75;
  }

  useEffect(() => {
    if (isEditing) {
      getAgeLinkDetails();
    }
  }, [isEditing]);

  const parseDataString = (dataString) => {
    const points = dataString.split("),(");
    return points.map((point) => {
      const [x, y] = point.replace("(", "").replace(")", "").split(",");
      return { x: parseFloat(x), y: parseFloat(y) };
    });
  };

  useEffect(() => {
    if (reset) {
      setDataPoints([]);
    }
  }, [reset]);

  const getAgeLinkDetails = async () => {
    try {
      const data = await getLinksBetweenNodes(
        selectedStartNode,
        selectedEndNode
      );
      if (data && data.links && data.links.length > 0) {
        const link = data.links[0];
        const parsedAgeData = link.properties.ageVsImportance.map((item) => {
          const [x, y] = item.replace(/[()]/g, "").split(",");
          return { x: parseFloat(x), y: parseFloat(y) };
        });
        setAgeLink(parsedAgeData);
      } else {
        setAgeLink([]);
      }
    } catch (error) {
      console.error("Error fetching link details:", error);
    }
  };

  useEffect(() => {
    if (isEditing && Array.isArray(ageLink)) {
      const ageLinkString = ageLink
        .map((point) => `(${point.x},${point.y})`)
        .join(",");
      setAgeInputValue(ageLinkString);
    }
  }, [ageLink, isEditing]);

  const validateDataPoints = (dataPoints) => {
    for (const point of dataPoints) {
      if (
        point.x < xAxisMin ||
        point.x > xAxisMax ||
        point.y < yAxisMin ||
        point.y > yAxisMax
      ) {
        return false;
      }
    }
    return true;
  };

  useEffect(() => {
    const agePoints = parseDataPoints(ageInputValue);
    if (!agePoints.error) {
      setDataPoints(agePoints);
    } else {
      setDataPoints([]);
    }
  }, [ageInputValue]);

  const handleSubmit = () => {
    const points = parseDataPoints(ageInputValue);

    if (points.error) {
      setError("Invalid format. Please use the format (x,y),(x,y).");
      return;
    }

    if (!validateDataPoints(points)) {
      setError(
        `Data points must be within the range: x axis - (${xAxisMin}-${xAxisMax}), y axis - (${yAxisMin}-${yAxisMax}).`
      );
      return;
    }

    if (points.length === 0) {
      setError("Please enter at least one data point.");
      return;
    }

    setDataPoints(points);
    onDataPointsLinkAgeSubmit(points);
    setError("");
    const modalContent = document.querySelector(".modal-content");
    if (modalContent) {
      modalContent.scrollTop = 0;
    }
  };

  const parseDataPoints = (input) => {
    const regex = /\((\d+(\.\d+)?),(\d+(\.\d+)?)\)/g;
    const parsedPoints = [];
    let match;
    while ((match = regex.exec(input)) !== null) {
      const x = parseFloat(match[1]);
      const y = parseFloat(match[3]);
      if (!isNaN(x) && !isNaN(y)) {
        parsedPoints.push({ x, y });
      }
    }
    if (parsedPoints.length === 0 && input) {
      return { error: true };
    }
    return parsedPoints;
  };

  const chartOptions = {
    colors: ["#092B6B"],
    chart: {
      type: "line",
      height: 400,
      toolbar: { show: false },
      zoom: { enabled: false },
    },
    stroke: {
      curve: "straight",
    },
    markers: {
      size: 3,
      colors: ["#092B6B"],
    },
    annotations: {
      yaxis: [
        {
          y: 0,
          strokeDashArray: 0,
          borderColor: "#092B6B",
          fillColor: "#092B6B",
          borderWidth: 2,
          opacity: 0.3,
        },
      ],
      xaxis: [
        {
          x: xAxisMin,
          strokeDashArray: 0,
          borderColor: "#092B6B",
          fillColor: "#092B6B",
          borderWidth: 2,
          opacity: 0.3,
        },
      ],
    },
    grid: {
      show: false,
      borderColor: "#ff0000",
    },
    xaxis: {
      type: "numeric",
      min: xAxisMin,
      max: xAxisMax,
    },
    yaxis: {
      min: yAxisMin,
      max: yAxisMax,
    },
  };

  const series = [
    {
      name: "Age Data Points",
      data: Array.isArray(dataPoints) ? dataPoints : [],
    },
    isEditing && {
      name: "Age Data Points",
      data: Array.isArray(dataPoints) ? dataPoints : [],
    },
    isEditing &&
      Array.isArray(ageLink) &&
      ageLink.length > 0 && { name: " Age", data: ageLink },
  ].filter(Boolean);

  return (
    <Modal
      open={open}
      onClose={onClose}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      className="inner-content-scroll"
    >
      <div
        style={{
          backgroundColor: "white",
          padding: "40px",
          width: "80%",
          maxWidth: "700px",
          maxHeight: "500px",
          borderRadius: "2px",
          overflowX: "hidden",
        }}
        className="inner-content-scroll modal-content"
      >
        <div
          style={{
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <CancelOutlinedIcon
            onClick={onClose}
            style={{ color: "#666666", width: 30, height: 30 }}
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "20px",
          }}
        >
          <TextLabel fontWeight={600}>
            {title} - {content1}
          </TextLabel>
        </div>
        <div
          style={{
            width: "100%",
            border: "1px solid #999999",
            marginBottom: "20px",
          }}
        >
          <Chart
            options={chartOptions}
            series={
              isEditing
                ? [
                    {
                      name: " Age DataPoints",
                      data: dataPoints,
                    },
                  ] || [{ name: " Age", data: ageLink }]
                : series
            }
            type="line"
            width="100%"
          />
        </div>
        <div style={{ width: "100%" }}>
          <TextLabel fontSize="15px" fontWeight={500}>Enter Data Points</TextLabel>
          <TextLabel fontSize="14px" fontWeight={500} color="#666666" customStyles={{ marginTop: "10px" }}>
            (1,0.2),(2,0.3)
          </TextLabel>
          <TextInputField
            placeholder="Enter X, Y values"
            variant="outlined"
            value={ageInputValue}
            onChange={(e) => setAgeInputValue(e.target.value)}
            fullWidth
          />
        </div>
        {error && (
          <div style={{ color: "red", marginTop: "10px" }}>{error}</div>
        )}
        <div
          style={{ display: "flex", justifyContent: "end", marginTop: "20px" }}
        >
          <PrimaryButton
            onClick={handleSubmit}
            variant="contained"
            color="primary"
          >
            {isEditing ? "Update" : "Submit"}
          </PrimaryButton>
        </div>
      </div>
    </Modal>
  );
};

export default LinkAgeGraphModal;
