import React, { useState, useEffect } from "react";
import { Grid, Box } from "@mui/material";
import TextLabel from "../../../components/textLabel";
import SeverityGraphModal from "./severityGraphModal";
import Chart from "react-apexcharts";
import { getNodeByNomenclature } from "../../../api/api";
import TextButtonLink from "../../../components/textButtonLink";

const SeverityGraphValues = ({
  title,
  titleValue,
  labelValue,
  content1,
  content2,
  maleLowSeverityValue,
  maleHighSeverityValue,
  femaleLowSeverityValue,
  femaleHighSeverityValue,
  onDataPointsSeveritySubmit,
  graphValueStyles,
  isEditing,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [dataPoints, setDataPoints] = useState([]);
  const [submitted, setSubmitted] = useState(false);
  const [maleLowSeverity, setMaleLowSeverity] = useState([]);
  const [femaleLowSeverity, setFemaleLowSeverity] = useState([]);
  const [maleHighSeverity, setMaleHighSeverity] = useState([]);
  const [femaleHighSeverity, setFemaleHighSeverity] = useState([]);

  useEffect(() => {
    if (isEditing) {
      getNodeSeverityDetails();
    }
  }, [titleValue, isEditing]);

  useEffect(() => {
    if (isEditing) {
      handleGenderSeverityDataPoints({
        maleLowPoints: maleLowSeverity,
        maleHighPoints: maleHighSeverity,
        femaleLowPoints: femaleLowSeverity,
        femaleHighPoints: femaleHighSeverity,
      });
    }
  }, [
    isEditing,
    maleLowSeverity,
    maleHighSeverity,
    femaleLowSeverity,
    femaleHighSeverity,
  ]);

  const parseDataString = (dataString) => {
    if (typeof dataString !== "string") {
      return [];
    }
    const points = dataString.split("),(");

    return points.map((point) => {
      const [x, y] = point.replace("(", "").replace(")", "").split(",");
      return { x: parseFloat(x), y: parseFloat(y) };
    });
  };

  const getNodeSeverityDetails = async () => {
    try {
      const data = await getNodeByNomenclature(labelValue);
      if (data && data.length > 0) {
        const node = data[0];
        let parsedMaleLowSeverity;
        let parsedFemaleLowSeverity;
        let parsedMaleHighSeverity;
        let parsedFemaleHighSeverity;
        if (titleValue.includes("Low")) {
          parsedMaleLowSeverity = parseDataString(
            node.properties.severity.male
          );
          parsedFemaleLowSeverity = parseDataString(
            node.properties.severity.female
          );
        }
        if (titleValue.includes("High")) {
          parsedMaleHighSeverity = parseDataString(
            node.properties.severity.male
          );
          parsedFemaleHighSeverity = parseDataString(
            node.properties.severity.female
          );
        }
        setMaleLowSeverity(parsedMaleLowSeverity);
        setFemaleLowSeverity(parsedFemaleLowSeverity);
        setMaleHighSeverity(parsedMaleHighSeverity);
        setFemaleHighSeverity(parsedFemaleHighSeverity);
      }
    } catch (error) {
      console.error("Error fetching node details:", error);
    }
  };
  const handleModalOpen = () => {
    setIsModalOpen(true);
  };
  const handleModalClose = () => {
    setIsModalOpen(false);
  };
  const handleGenderSeverityDataPoints = (points) => {
    const {
      maleLowPoints = [],
      maleHighPoints = [],
      femaleHighPoints = [],
      femaleLowPoints = [],
    } = points;
    if (
      maleLowPoints.length === 0 &&
      maleHighPoints.length === 0 &&
      femaleHighPoints.length > 0
    ) {
      setDataPoints(femaleHighPoints);
    } else if (
      maleLowPoints.length === 0 &&
      maleHighPoints.length === 0 &&
      femaleHighPoints.length === 0 &&
      femaleLowPoints.length > 0
    ) {
      setDataPoints(femaleLowPoints);
    } else {
      setDataPoints(
        maleHighPoints?.length > 0 ? maleHighPoints : maleLowPoints
      );
    }
    if (typeof onDataPointsSeveritySubmit === "function") {
      onDataPointsSeveritySubmit(points);
    } else {
      console.error(
        "onDataPointsSeveritySubmit is not a function:",
        onDataPointsSeveritySubmit
      );
    }
    setSubmitted(true);
  };
  const severityDataPoints = () => {
    if (isEditing) {
      if (maleHighSeverity) {
        return [{ data: maleHighSeverity }];
      } else if (maleLowSeverity) {
        return [{ data: maleLowSeverity }];
      } else if (femaleHighSeverity) {
        return [{ data: femaleHighSeverity }];
      } else if (femaleLowSeverity) {
        return [{ data: femaleLowSeverity }];
      }
    } else {
      return [{ data: dataPoints }];
    }
  };
  const seriesData = submitted ? [{ data: dataPoints }] : severityDataPoints();
  const chartOptions = {
    colors: ["#092B6B"],
    chart: {
      type: "line",
      height: 350,
      toolbar: { show: false },
      zoom: { enabled: false },
    },
    stroke: { curve: "straight" },
    markers: { size: 3, colors: ["#092B6B"] },
    annotations: {
      yaxis: [
        {
          y: 0,
          strokeDashArray: 0,
          borderColor: "#092B6B",
          fillColor: "#092B6B",
          borderWidth: 2,
          opacity: 0.3,
        },
      ],
      xaxis: [
        {
          x: 1,
          strokeDashArray: 0,
          borderColor: "#092B6B",
          fillColor: "#092B6B",
          borderWidth: 2,
          opacity: 0.3,
        },
      ],
    },
    grid: { show: false, borderColor: "#ff0000" },
    xaxis: { type: "numeric", min: 1, max: 50 },
    yaxis: { min: 0, max: 1 },
  };
  return (
    <>
      <Grid
        item
        xs={12}
        sm={12}
        md={4}
        sx={{
          display: "flex",
          flexDirection: "column",
          border: "0.031rem solid #999999",
          padding: "1.875rem",
          ...graphValueStyles,
        }}
      >
        {(dataPoints && submitted) || isEditing ? (
          <div>
            <TextLabel fontWeight={500} fontSize="1.5rem" color="#174FB6">
              {title}
            </TextLabel>
            <Chart
              options={chartOptions}
              series={seriesData}
              type="line"
              width="300"
              height="250"
            />

             <TextButtonLink onClick={handleModalOpen}>
                Update Graph
              </TextButtonLink>
          </div>
        ) : (
          <>
            <TextLabel fontWeight={500} fontSize="1.5rem" color="#174FB6">
              {title}
            </TextLabel>
            <TextLabel
              fontWeight={400}
              fontSize="1rem"
              customStyles={{
                marginTop: "0.938rem",
                whiteSpace: "normal",
                wordWrap: "break-word",
              }}
            >
              {content1}
            </TextLabel>
            <TextLabel
              fontWeight={400}
              fontSize="0.813rem"
              customStyles={{ marginTop: "0.938rem", marginBottom: "0.938rem" }}
              color="#999999"
            >
              {content2}
            </TextLabel>
            <TextButtonLink onClick={handleModalOpen}>
                Enter Graph Value
              </TextButtonLink>
          </>
        )}
      </Grid>
      <SeverityGraphModal
        open={isModalOpen}
        onClose={handleModalClose}
        title={title}
        titleValue={titleValue}
        labelValue={labelValue}
        content1={content1}
        onDataPointsSeveritySubmit={handleGenderSeverityDataPoints}
        maleLowSeverityValue={maleLowSeverityValue}
        maleHighSeverityValue={maleHighSeverityValue}
        femaleLowSeverityValue={femaleLowSeverityValue}
        femaleHighSeverityValue={femaleHighSeverityValue}
        isEditing={isEditing}
      />
    </>
  );
};
const GraphValuesWithSpacing = ({ children }) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: { lg: "flex-start" },
        width: "100%",
        gap: "0.9rem",
        flexWrap: "wrap",
      }}
    >
      {React.Children.map(children, (child, index) => (
        <Box
          key={index}
          sx={{
            width: { xs: "100%", lg: "calc(33.33% - 0.625rem)" },
            marginBottom: { xs: "1rem", md: "3rem" },
          }}
        >
          {child}
        </Box>
      ))}
    </Box>
  );
};
export { SeverityGraphValues, GraphValuesWithSpacing };
