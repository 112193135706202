import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Titlebar from "../components/Titlebar";
import { Grid, Typography } from "@mui/material";
import TextLabel from "../components/textLabel";
import { Link } from "react-router-dom";
import { Toolbar } from "@mui/material";
import PrimaryButton from "../components/primaryTextButton";
import TextInputField from "../components/inputField";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import SecondaryButton from "../components/secondaryTextButton";
import { Auth } from "aws-amplify";
import ErrorMessage from "../components/errorMessage";
import IconButton from "@mui/material/IconButton";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import InputAdornment from "@mui/material/InputAdornment";
import ToastModal from "../components/toastModal";
import TextButtonLink from "../components/textButtonLink";

const Profile = () => {
  const [showNameChange, setShowNameChange] = useState(false);
  const [showPasswordChange, setShowPasswordChange] = useState(false);
  const [newUserName, setNewUserName] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [newUserNameError, setNewUserNameError] = useState("");
  const [oldPasswordError, setOldPasswordError] = useState("");
  const [newPasswordError, setNewPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [passwordErrorMessage, setPasswordErrorMessage] = useState("");
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");

  const email = useSelector((state) => state.email);
  const username = useSelector((state) => state.username);
  const dispatch = useDispatch();

  const updateUsername = async (newUserName) => {
    try {
      const currentUser = await Auth.currentAuthenticatedUser();
      const attributes = {
        "custom:userName": newUserName,
      };

      await Auth.updateUserAttributes(currentUser, attributes);
      setNewUserName(newUserName);
      dispatch({ type: "UPDATE_USERNAME", newUserName });
    } catch (error) {
      console.error("Error updating username:", error);
    } finally {
      setShowNameChange(false);
    }
  };

  const handlePasswordSubmit = async (e) => {
    e.preventDefault();
    passwordValidation();
  };

  const passwordValidation = async () => {
    const passExp =
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/;
    const passwordCheck = passExp.test(newPassword);

    if (oldPassword === "") {
      setOldPasswordError("Please enter your old Password");
    } else {
      setOldPasswordError("");
    }

    if (newPassword === "") {
      setNewPasswordError("Please enter your new password");
    } else {
      if (newPassword.length < 8) {
        setNewPasswordError("Password must contain 8 characters");
      } else if (!passwordCheck) {
        setNewPasswordError(
          "A password should contain at least one number and one special character."
        );
      } else {
        setNewPasswordError("");
      }
    }

    if (confirmPassword === "") {
      setConfirmPasswordError("Please confirm your password");
    } else {
      if (newPassword !== confirmPassword) {
        setConfirmPasswordError("Passwords do not match");
      } else {
        setConfirmPasswordError("");
      }
    }

    if (
      oldPasswordError === "" &&
      newPasswordError === "" &&
      confirmPasswordError === "" &&
      newPassword === confirmPassword
    ) {
      try {
        await Auth.signIn(Auth.user.username, oldPassword);
        const user = await Auth.currentAuthenticatedUser();
        await Auth.changePassword(user, oldPassword, newPassword);
        setModalMessage("Password Changed successfully");
        setShowModal(true);
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
        setOldPassword("");
        setNewPassword("");
        setConfirmPassword("");
        setOldPasswordError("");
        setNewPasswordError("");
        setConfirmPasswordError("");
        setShowPasswordChange(false);
      } catch (error) {
        if (error.code === "NotAuthorizedException") {
          setOldPasswordError("Incorrect old password. Please try again.");
        } else if (error.code === "LimitExceededException") {
          setConfirmPasswordError(
            "Attempts limit exceeded. Please try again later."
          );
        }

        console.error("Error changing password:", error);
      }
    }
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const handleNameChangeClick = () => {
    setShowNameChange(true);
  };

  const handlePasswordChangeClick = () => {
    setShowPasswordChange(true);
  };

  const handleCancelClick = () => {
    setShowNameChange(false);
    setNewUserName("");
  };

  const handlePasswordCancelClick = () => {
    setShowPasswordChange(false);
    setOldPassword("");
    setNewPassword("");
    setConfirmPassword("");
    setOldPasswordError("");
    setNewPasswordError("");
    setConfirmPasswordError("");
    setPasswordErrorMessage("");
  };

  const handleConfirmClick = async () => {
    let reg1 = /^[A-Za-z ]+$/;
    let isValid = reg1.test(newUserName);

    if (newUserName === "") {
      setNewUserNameError("Please enter your new name");
    } else {
      if (!isValid) {
        setNewUserNameError("Enter alphabets and numbers ");
      } else {
        setNewUserNameError("");
      }
    }

    if (newUserName.trim()) {
      await updateUsername(newUserName);
    } else {
      console.error("Please enter a new username.");
    }
  };

  const handleNameChange = (event) => {
    setNewUserName(event.target.value);
  };
  const handleOldPasswordChange = (e) => {
    setOldPassword(e.target.value);
    setOldPasswordError("");
    setPasswordErrorMessage("");
  };
  const handlePasswordChange = (e) => {
    setNewPassword(e.target.value);
    setNewPasswordError("");
    setPasswordErrorMessage("");
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
    setConfirmPasswordError("");
    setPasswordErrorMessage("");
  };

  useEffect(() => {
    const fetchUsername = async () => {
      try {
        const currentUser = await Auth.currentAuthenticatedUser();
        const attributes = currentUser.attributes;
        const customUsername = attributes["custom:userName"];
        setNewUserName(customUsername);
      } catch (error) {
        console.error("Error fetching username:", error);
      }
    };
    fetchUsername();
  }, []);

  const getEmailName = (email) => {
    return email.split("@")[0];
  };

  return (
    <>
      <Titlebar />
      <ToastModal
        show={showModal}
        message={modalMessage}
        onClose={closeModal}
      />
      <Toolbar sx={{ mt: "6rem", mx: "3rem", textDecoration: "none" }}>
        <Typography
          component={Link}
          to="/homepage"
          sx={{
            textDecoration: "none",
            color: "inherit",
            fontSize: 16,
            fontWeight: 400,
          }}
        >
          Home
        </Typography>
        <ArrowForwardIosRoundedIcon sx={{ fontSize: 16, ml: 0.5, mr: 0.5 }} />
        <Typography
          component={Link}
          to="/createnode"
          sx={{
            textDecoration: "none",
            color: "inherit",
            fontSize: 16,
            fontWeight: 400,
          }}
        >
          Profile
        </Typography>
      </Toolbar>
      <Grid sx={{ pt: "1.625rem", pl: "4.25rem", pr: "4.25rem" }}>
        <TextLabel fontWeight={600} fontSize="18px">
          Email
        </TextLabel>
        <Grid
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            mr: "1.125rem",
            mt: "1.625rem",
            width: "100%",
          }}
        >
          <TextLabel fontWeight={600} fontSize="16px">
            Current Email:
          </TextLabel>
          <TextLabel
            fontWeight={500}
            fontSize="16px"
            customStyles={{ marginLeft: "20px" }}
          >
            {email}
          </TextLabel>
        </Grid>
      </Grid>
      <Grid
        sx={{
          pb: "1.625rem",
          ml: "4.25rem",
          mr: "4.25rem",
          borderBottom: { xs: "1px solid #ddd" },
        }}
      ></Grid>

      <Grid sx={{ pt: "1.625rem", pl: "4.25rem", pr: "4.25rem" }}>
        <TextLabel fontWeight={600} fontSize="18px">
          Username
        </TextLabel>
        <Grid
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            mr: "1.125rem",
            my: "1.625rem",
            width: "100%",
          }}
        >
          <TextLabel fontWeight={600} fontSize="16px">
            Username:
          </TextLabel>
          <TextLabel
            fontWeight={500}
            fontSize="16px"
            customStyles={{ marginLeft: "20px" }}
          >
            {username ? username : getEmailName(email)}
          </TextLabel>

          {!showNameChange && (
            <TextButtonLink sx={{marginLeft:"20px"}} fontSize="18px"
              onClick={handleNameChangeClick}
            >
              Change Username
            </TextButtonLink>
          )}
        </Grid>
        {showNameChange && (
          <>
            <Grid
              container
              sx={{
                display: "flex",
                flexDirection: { xs: "column" },
              }}
            >
              <TextLabel fontWeight={500} fontSize="16px">
                New Username:
              </TextLabel>
              <Grid item xs={12} sm={6}>
                <TextInputField
                  fullWidth
                  sx={{ mt: 1 }}
                  placeholder="Enter your new name"
                  type="text"
                  value={newUserName}
                  onChange={handleNameChange}
                />
              </Grid>
            </Grid>
            <ErrorMessage message={newUserNameError} />
            <Grid
              container
              sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
              }}
            >
              <Grid item xs={12} sm={6} container justifyContent="flex-end">
                <PrimaryButton
                  onClick={handleConfirmClick}
                  style={{ marginRight: "16px" }}
                >
                  Confirm
                </PrimaryButton>

                <SecondaryButton onClick={handleCancelClick}>
                  Cancel
                </SecondaryButton>
              </Grid>
            </Grid>
          </>
        )}
      </Grid>

      <Grid
        sx={{
          pb: "1.625rem",
          ml: "4.25rem",
          mr: "4.25rem",
          borderBottom: { xs: "1px solid #ddd" },
        }}
      ></Grid>

      <Grid sx={{ pt: "1.625rem", pl: "4.25rem", pr: "4.25rem" }}>
        <TextLabel fontWeight={600} fontSize="18px">
          Password
        </TextLabel>
        <Grid
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            mr: "1.125rem",
            mt: "1.625rem",
            width: "100%",
          }}
        >
          <TextLabel fontWeight={600} fontSize="16px">
            Current Password:
          </TextLabel>
          <TextLabel
            fontWeight={600}
            fontSize="25px"
            customStyles={{ marginLeft: "20px", paddingBottom: "12px" }}
          >
            ...........
          </TextLabel>

          {!showPasswordChange && (
                        <TextButtonLink sx={{marginLeft:"20px"}} fontSize="18px" onClick={handlePasswordChangeClick}>
              Change Password
            </TextButtonLink>
          )}
        </Grid>
        {showPasswordChange && (
          <>
            <Grid
              container
              sx={{
                display: "flex",
                flexDirection: { xs: "column" },
                paddingTop: "5px",
              }}
            >
              <TextLabel fontWeight={500} fontSize="16px">
                Current Password:
              </TextLabel>
              <Grid item xs={12} sm={6}>
                <TextInputField
                  fullWidth
                  sx={{ mt: 1 }}
                  placeholder="Enter your current password"
                  type={showOldPassword ? "text" : "password"}
                  value={oldPassword}
                  onChange={handleOldPasswordChange}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowOldPassword(!showOldPassword)}
                        edge="end"
                      >
                        {showOldPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </Grid>
              <ErrorMessage message={oldPasswordError} />
              <TextLabel fontWeight={500} fontSize="16px">
                New Password:
              </TextLabel>
              <Grid item xs={12} sm={6}>
                <TextInputField
                  fullWidth
                  sx={{ mt: 1 }}
                  placeholder="Enter your new password"
                  type={showNewPassword ? "text" : "password"}
                  value={newPassword}
                  onChange={handlePasswordChange}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowNewPassword(!showNewPassword)}
                        edge="end"
                      >
                        {showNewPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </Grid>
              <ErrorMessage message={newPasswordError} />
              <TextLabel fontWeight={500} fontSize="16px">
                Confirm New Password:
              </TextLabel>
              <Grid item xs={12} sm={6}>
                <TextInputField
                  fullWidth
                  sx={{ mt: 1 }}
                  placeholder="Confirm your new password"
                  type={showConfirmPassword ? "text" : "password"}
                  value={confirmPassword}
                  onChange={handleConfirmPasswordChange}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() =>
                          setShowConfirmPassword(!showConfirmPassword)
                        }
                        edge="end"
                      >
                        {showConfirmPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </Grid>
              <ErrorMessage message={confirmPasswordError} />
            </Grid>
            <Grid
              sx={{
                display: "flex",
                flexDirection: { xs: "column", md: "row" },
              }}
            >
              <Grid item xs={12} sm={6} container justifyContent="flex-end">
                <PrimaryButton
                  onClick={handlePasswordSubmit}
                  style={{ marginRight: "16px" }}
                >
                  Confirm
                </PrimaryButton>
                <SecondaryButton onClick={handlePasswordCancelClick}>
                  Cancel
                </SecondaryButton>
              </Grid>
            </Grid>
          </>
        )}
      </Grid>

      <Grid
        sx={{
          pb: "1.625rem",
          ml: "4.25rem",
          mr: "4.25rem",
          borderBottom: { xs: "1px solid #ddd" },
        }}
      ></Grid>
    </>
  );
};

export default Profile;
