import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  IconButton,
  Tab,
  Tabs,
  Menu,
  MenuItem,
  Box,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import homeBlack from "../assets/homeBlack.svg";
import nexcuraLogo from "../assets/nexcuraLogo.png";

const TitleBar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const email = useSelector((state) => state.email);
  const username = useSelector((state) => state.username);

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedTab, setSelectedTab] = useState(0);

  const firstLetter = username
    ? username.charAt(0).toUpperCase()
    : email.charAt(0).toUpperCase();

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (anchorEl && !anchorEl.contains(event.target)) {
        setAnchorEl(null);
      }
    };
    window.addEventListener("click", handleOutsideClick);

    return () => {
      window.removeEventListener("click", handleOutsideClick);
    };
  }, [anchorEl]);

  useEffect(() => {
    if (location.pathname === "/homepage") {
      setSelectedTab(0);
    } else if (location.pathname === "/homepagelink") {
      setSelectedTab(1);
    }
  }, [location.pathname]);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);

    if (newValue === 0) {
      navigate("/homepage");
    } else if (newValue === 1) {
      navigate("/homepagelink");
    }
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuProfile = () => {
    navigate("/profile");
  };

  const handleMenuClose = () => {
    dispatch({ type: "LOGOUT" });
    navigate("/");
  };

  const isIndicatorVisible =
    location.pathname === "/homepage" || location.pathname === "/homepagelink";

  return (
    <AppBar position="fixed" sx={{ backgroundColor: "#ffffff" }}>
      <Toolbar>
        <img
          src={nexcuraLogo}
          alt="Logo"
          style={{ width: "180px", marginRight: "10px" }}
        />
        <Box sx={{ flexGrow: 1 }} />
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          textColor="#333333"
          TabIndicatorProps={
            isIndicatorVisible
              ? {
                  style: {
                    backgroundColor: "#B5229F",
                    height: "4px",
                    bottom: 0,
                    position: "absolute",
                  },
                }
              : {
                  style: {
                    backgroundColor: "#ffffff",
                    bottom: 0,
                    position: "absolute",
                  },
                }
          }
          sx={{
            position: "relative",
            minHeight: "64px",
          }}
        >
          <Tab
            label="Clinical Components"
            sx={{
              textTransform: "none",
              fontSize: 18,
              color: "#333333",
              fontWeight: "bold",
              paddingRight: "20px",
              marginRight: "23px",
              marginTop: "10px",
            }}
          />
          <Tab
            label="Clinical Relationship"
            sx={{
              textTransform: "none",
              fontSize: 18,
              color: "#333333",
              fontWeight: "bold",
              paddingRight: "20px",
              marginLeft: "10px",
              marginRight: "23px",
              marginTop: "10px",
            }}
          />
        </Tabs>

        <div style={{ paddingTop: "5px", marginRight: "20px" }}>
          <img
            src={homeBlack}
            alt="Home"
            onClick={() => navigate("/homepage")}
            style={{
              cursor: "pointer",
              width: "40px",
              height: "40px",
              fill: "#333333",
            }}
          />
        </div>

        <div>
          <IconButton
            size="large"
            color="inherit"
            paddingTop="1px"
            aria-controls="profile-menu"
            aria-haspopup="true"
            onClick={handleMenuOpen}
            edge="end"
            sx={{
              "&:hover": {
                backgroundColor: "#FFFFFF",
              },
            }}
          >
            <Box
              sx={{
                width: 40,
                height: 40,
                borderRadius: "50%",
                backgroundColor: "#B5229F",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "20px",
                fontWeight: "700",
                color: "#FFFFFF",
                marginTop: "1px",
                fontFamily: "Mulish",
              }}
            >
              {firstLetter}
            </Box>
            <ExpandMoreIcon
              sx={{ fontSize: "36px", marginTop: "1px", fill: "#333333" }}
            />
          </IconButton>
          <Menu
            id="profile-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            sx={{
              py: "10px",
              px: "15px",
            }}
          >
            <MenuItem
              sx={{
                py: "1px",
                px: "30px",
                color: "#333333",
                fontWeight: "500",

                "&:hover": {
                  backgroundColor: "#ffffff",
                },
              }}
              onClick={handleMenuProfile}
            >
              Profile
            </MenuItem>
            <MenuItem
              sx={{
                pt: "1px",
                pb: "1px",
                px: "30px",
                fontWeight: "500",
                color: "#333333",
                "&:hover": {
                  backgroundColor: "#ffffff",
                },
              }}
              onClick={handleMenuClose}
            >
              Logout
            </MenuItem>
          </Menu>
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default TitleBar;
