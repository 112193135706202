import React, { useState, useEffect } from "react";
import { Container, Grid, Box } from "@mui/material";
import { Auth } from "aws-amplify";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, Link, useLocation } from "react-router-dom";
import TitleLabel from "../components/titleLabel";
import FooterLabel from "../components/footerLabel";
import Logo from "../components/logo";
import TextLabel from "../components/textLabel";
import TextInputField from "../components/inputField";
import ErrorMessage from "../components/errorMessage";
import PrimaryButton from "../components/primaryTextButton";
import { refreshAccessToken, createOtherUser, getOtherUserByEmail } from "../api/api";
import IconButton from "@mui/material/IconButton";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import InputAdornment from "@mui/material/InputAdornment";
import genaiLogo from "../assets/genaiLogo.svg";
import emailIcon from "../assets/emailIcon.svg";

function Signin() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const emailState = useSelector((state) => state.email);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [cognitoError, setCognitoError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  useEffect(() => {
    if (showErrorMessage) {
      const timer = setTimeout(() => {
        setShowErrorMessage(false);
      }, 30000);
      return () => clearTimeout(timer);
    }
  }, [showErrorMessage, email, navigate]);

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const mapCognitoError = async (error) => {
    if (error.code) {
      switch (error.code) {
        case "UserNotFoundException":
          return "Please enter the registered email id.";
        case "NotAuthorizedException":
          return "Incorrect email id or password.";
        case "UserNotConfirmedException":
          setShowErrorMessage(true);
          setTimeout(() => {
            setShowErrorMessage(false);
            setTimeout(async () => {
              try {
                await Auth.resendSignUp(email);
                navigate("/conformation", { state: { email: email } });
              } catch (confirmationError) {
                console.error("Confirmation error:", confirmationError);
                setCognitoError(confirmationError.message);
              }
            }, 1000);
          }, 5000);
          return "";
        default:
          return error.message;
      }
    } else {
      if (error.message === "Username cannot be empty") {
        return "";
      }
      return error.message;
    }
  };

  const onSubmit = async () => {
    let expression = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
    let emailCheck = expression.test(email);
    let passExp =
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/;
    let passwordCheck = passExp.test(password);
  
    if (email === "") {
      setEmailError("Please enter your email id");
    } else {
      if (!emailCheck) {
        setEmailError("Enter valid email id");
      } else {
        setEmailError("");
      }
    }
  
    if (password === "") {
      setPasswordError("Please enter your password");
    }
    setCognitoError("");
    if (emailError === "" && passwordError === "") {
      try {
        const data = await Auth.signIn(email, password);
        dispatch({
          type: "LOGIN",
          email: data.attributes.email,
          username: data.attributes["custom:userName"],
          accessToken: data.signInUserSession.idToken.jwtToken,
        });
        await refreshAccessToken({ email: data.attributes.email });
      
        const currentUser = await Auth.currentAuthenticatedUser();
        const attributes = await Auth.userAttributes(currentUser);
        const guid = attributes.find(attr => attr.Name === 'sub').Value;
  
        try {
          const groupName = "DoctorInterface";
          const userDetailsResponse = await getOtherUserByEmail(email, groupName);
          const { userDetails: userInfo } = userDetailsResponse;
  
          if (!userInfo) {
            const groupName = "DoctorInterface";
            await createOtherUser(email, guid, groupName);
          } else if (userInfo.userGroup !== "DoctorInterface") {
            setCognitoError("User does not have the required permissions.");
            return;
          }
  
          navigate("/homepage");
        } catch (error) {
          if (error.response && error.response.status === 404 && error.response.data.userDetails === "User Not found") {
            const groupName = "DoctorInterface";
            await createOtherUser(email, guid, groupName);
            
            navigate("/homepage");
          } else {
            throw error;
          }
        }
      } catch (error) {
        const customErrorMessage = await mapCognitoError(error);
        setCognitoError(customErrorMessage);
      }
    }
  };
  

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      style={{ minHeight: "100vh" }}
    >
      <div style={{ marginTop: "4rem" }}>
        <Logo />
      </div>
      <TitleLabel fontWeight={600} sx={{ mt: "3rem", mb: "1.25rem" }}>
        Welcome Back
      </TitleLabel>
      <Container maxWidth="xs">
        <TextLabel fontWeight={600} fontSize="16px">
          Email
        </TextLabel>
        <TextInputField
          sx={{
            mt: 1,
            mb: emailError ? 0 : 3,
          }}
          fullWidth={true}
          placeholder="Enter email"
          type="email"
          value={email}
          onKeyDown={handleKeyDown}
          onChange={(e) => {
            setEmail(e.target.value);
            setCognitoError("");
            setEmailError("");
          }}
          endAdornment={
            <InputAdornment position="end">
              <img
                src={emailIcon}
                alt="Email Icon"
                style={{ width: 20, height: 20 }}
              />
            </InputAdornment>
          }
        />
        {emailError && <ErrorMessage message={emailError} />}
        <TextLabel fontWeight={600} fontSize="16px">
          Password
        </TextLabel>
        <TextInputField
          sx={{
            mt: 1,
            mb: passwordError ? 0 : 1,
          }}
          fullWidth={true}
          placeholder="Password of at least 8 characters"
          type={showPassword ? "text" : "password"}
          value={password}
          onKeyDown={handleKeyDown}
          onChange={(e) => {
            setPassword(e.target.value);
            setCognitoError("");
            setPasswordError("");
          }}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                edge="end"
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          }
        />
        {passwordError && <ErrorMessage message={passwordError} />}
        {cognitoError && <ErrorMessage message={cognitoError} />}
        {showErrorMessage && (
          <ErrorMessage message="Entered email is not confirmed. Please check your email for a confirmation code." />
        )}
        <div
          style={{
            marginBottom: "1.563rem",
            textAlign: "right",
          }}
        >
          <Link
            to="/forgotpassword"
            variant="body2"
            style={{
              textDecoration: "none",
              fontSize: "0.875rem",
              fontWeight: "700",
              fontFamily: "Mulish",
              color: "#174FB6",
              marginTop: passwordError || cognitoError ? 0 : "10px",
            }}
          >
            Forgot Password?
          </Link>
        </div>
        <PrimaryButton fullWidth type="button" onClick={onSubmit}>
          Sign In
        </PrimaryButton>
        <div
          style={{
            marginTop: "1rem",
            textAlign: "center",
            fontSize: "0.875rem",
            fontWeight: "500",
            fontFamily: "Mulish",
          }}
        >
          Don't have an account?
          <Link
            to="/signup"
            variant="body2"
            style={{
              textDecoration: "none",
              marginLeft: "0.5rem",
              fontFamily: "Mulish",
              fontSize: "0.875rem",
              fontWeight: "700",
              color: "#174FB6",
            }}
          >
            Sign Up
          </Link>
        </div>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          sx={{ mt: "6rem", mb: "2.5rem" }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <FooterLabel
              fontWeight={400}
              fontSize={16}
              sx={{ display: "inline" }}
            >
              Powered By
            </FooterLabel>
            <Box
              component="img"
              src={genaiLogo}
              width="200px"
              height="40px"
              sx={{ display: "inline", ml: "10px" }}
            />
          </Box>
        </Grid>
      </Container>
    </Grid>
  );
}

export default Signin;
