import React from "react";
import Box from "@mui/material/Box";
import TitleLabel from "../components/titleLabel";

const Footer = () => {
  return (
    <Box
      component="footer"
      sx={{
        textAlign: "center",
        padding: "10px 0",
        bottom: 0,
        width: "100%",
        fontFamily: "Mulish",
        fontSize: 10,
        marginBottom: "60px",
      }}
    >
      <TitleLabel sx={{ color: "#333333" ,marginTop:"10px"}} fontSize="1rem" fontWeight={500}>
       
        Copyright &copy; {new Date().getFullYear()} GenAI Healthcare, All Rights
        Reserved.
        </TitleLabel>
    </Box>
  );
};

export default Footer;
