import React from "react";
import { Modal, Box, Button } from "@mui/material";
import TitleLabel from "./titleLabel";
import PrimaryButton from "./primaryTextButton";

const DeleteModal = ({ isOpen, onClose, onDelete, modalText }) => {
  return (
    <Modal open={isOpen} onClose={onClose}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "90vh",
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            background: "white",
            pt: "2.5rem",
            pb: "2.5rem",
            px: "3.5rem",
            textAlign: "center",
            borderRadius: "1.25rem",
            width: "320px",
          }}
        >
          <TitleLabel fontSize={18} fontWeight={600} sx={{ mb: "1.563rem" }}>
            {modalText}
          </TitleLabel>
          <Button
            variant="text"
            style={{
              marginRight: "2rem",
              fontSize: "1.25rem",
              fontWeight: "700",
              color: "#174FB6",
              textTransform: "none",
            }}
            onClick={onClose}
          >
            No
          </Button>
          <PrimaryButton onClick={onDelete}>Yes</PrimaryButton>
        </Box>
      </Box>
    </Modal>
  );
};
export default DeleteModal;
