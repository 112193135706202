import React, { useState, useEffect } from "react";
import { Box, Grid } from "@mui/material";
import TitleBar from "../../components/Titlebar";
import Dropdown from "../../components/DropDown";
import PrimaryButton from "../../components/primaryTextButton";
import TextLabel from "../../components/textLabel";
import { Toolbar } from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Typography } from "@mui/material";
import { getLinksBetweenNodes, checkIfLinkExists } from "../../api/api";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import { useNodeOptions } from "../../components/DropdownOptions";
import TextInputField from "../../components/inputField";
import ErrorMessage from "../../components/errorMessage";
import {
  LinkAgeGraphValues,
  LinkGraphValuesWithSpacing,
} from "./linkAgeGraphValues";
import { createLink, updateLink } from "../../api/api";
import PopupLinkModal from "../../components/editLinkModal";
import SuccessPage from "../../components/SuccessPage";

const CreateLink = (props) => {
  const { categories, startNames, endNames, fetchStartNames, fetchEndNames } =
    useNodeOptions();
  const location = useLocation();
  const navigate = useNavigate();

  const [selectedStartCategory, setSelectedStartCategory] = useState("");
  const [selectedEndCategory, setSelectedEndCategory] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [weightage, setWeightage] = useState("");
  const [startNodeCategoryError, setstartNodeCategoryError] = useState("");
  const [endNodeCategoryError, setendNodeCategoryError] = useState("");
  const [selectedStartNode, setSelectedStartNode] = useState("");
  const [selectedEndNode, setSelectedEndNode] = useState("");
  const [startNodeError, setstartNodeError] = useState("");
  const [endNodeError, setendNodeError] = useState("");
  const [weightageError, setWeightageError] = useState("");
  const [ageData, setAgeData] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [gradientInput1, setGradientInput1] = useState("");
  const [gradientInput2, setGradientInput2] = useState("");
  const [gradientInput3, setGradientInput3] = useState("");
  const [gradientInput4, setGradientInput4] = useState("");
  const [gradientError, setGradientError] = useState("");
  const [resetGraph, setResetGraph] = useState(false);
  const [showGraph, setShowGraph] = useState(false);
  const [linkExistsError, setLinkExistsError] = useState("");

  useEffect(() => {
    if (selectedStartCategory) {
      fetchStartNames(selectedStartCategory);
    }
  }, [selectedStartCategory]);

  useEffect(() => {
    if (selectedEndCategory) {
      fetchEndNames(selectedEndCategory);
    }
  }, [selectedEndCategory]);

  useEffect(() => {
    if (location.state && location.state.isEditing) {
      const { startNodeName, endNodeName } = location.state;
      setSelectedStartNode(startNodeName || "");
      setSelectedEndNode(endNodeName || "");
      setIsEditing(true);
      setShowGraph(true);
    }
  }, [location.state]);
  useEffect(() => {
    if (isEditing) {
      getLinkDetails();
    }
  }, [selectedStartNode, selectedEndNode, isEditing]);

  const getLinkDetails = async () => {
    try {
      const data = await getLinksBetweenNodes(
        selectedStartNode,
        selectedEndNode
      );
      if (data && data.links && data.links.length > 0) {
        const link = data.links[0];
        setSelectedStartCategory(link.startNodeCategory);
        setSelectedEndCategory(link.endNodeCategory);
        setWeightage(link.properties.Weightage);

        const gradientString = link.properties.gradient.replace(/^\[|\]$/g, "");
        const gradients = gradientString
          .split("),(")
          .map((item) => item.replace(/[()]/g, ""));
        if (gradients.length > 0) setGradientInput1(`(${gradients[0]})`);
        if (gradients.length > 1) setGradientInput2(`(${gradients[1]})`);
        if (gradients.length > 2) setGradientInput3(`(${gradients[2]})`);
        if (gradients.length > 3) setGradientInput4(`(${gradients[3]})`);
      }
    } catch (error) {
      console.error("Error fetching link details:", error);
    }
  };

  const handleDataPointsLinkAgeSubmit = (points, targetState, targetName) => {
    if (points) {
      const updatedData = {
        [targetName]: points.map(({ x, y }) => `(${x},${y})`).join(","),
      };
      targetState(updatedData, () => {
        console.log(`Updated  ${targetName} Data:`, updatedData);
      });
    } else {
      console.error(`Invalid data format for ${targetName} points:`, points);
    }
  };
  const handleLinkAgeDataPointsSubmit = (points) => {
    handleDataPointsLinkAgeSubmit(points, setAgeData, "Age");
    setAgeData(points);
  };
  const handleStartNodeChange = (event) => {
    setSelectedStartCategory(event.target.value);
    setSelectedEndCategory("");
    setSelectedStartNode("");
    setSelectedEndNode("");
  };
  const handleEndNodeChange = (event) => {
    setSelectedEndCategory(event.target.value);
  };
  const filteredEndNodeOptions = categories.filter(
    (category) =>
      category.value === "Ailments" || category.value !== selectedStartCategory
  );

  const handleStartNodeSelection = async (event) => {
    const selectedStartNode = event.target.value;
    setSelectedStartNode(selectedStartNode);
    setLinkExistsError("");

    if (selectedStartNode && selectedEndNode) {
      try {
        const linkExists = await checkIfLinkExists(
          selectedStartCategory,
          selectedEndCategory,
          selectedStartNode,
          selectedEndNode
        );
        if (linkExists) {
          setLinkExistsError(
            "A link already exists between the selected start and end nodes."
          );
        }
      } catch (error) {
        console.error("Error checking link existence:", error);
      }
    }
  };

  const handleEndNodeSelection = async (event) => {
    const selectedEndNode = event.target.value;
    setSelectedEndNode(selectedEndNode);
    setLinkExistsError("");

    if (selectedStartNode && selectedEndNode) {
      try {
        const linkExists = await checkIfLinkExists(
          selectedStartCategory,
          selectedEndCategory,
          selectedStartNode,
          selectedEndNode
        );
        if (linkExists) {
          setLinkExistsError(
            "A clinical relationship already exists. Please choose different start and end node."
          );
        }
      } catch (error) {
        console.error("Error checking link existence:", error);
      }
    }
  };

  const handleWeightageChange = (event) => {
    setWeightage(event.target.value);
    setWeightageError("");
  };
  const handleGradientInputChange = (setGradientInput, value) => {
    setGradientInput(value);
    setGradientError("");
  };
  const handleGradientInput1Change = (event) => {
    handleGradientInputChange(setGradientInput1, event.target.value);
  };
  const handleGradientInput2Change = (event) => {
    handleGradientInputChange(setGradientInput2, event.target.value);
  };
  const handleGradientInput3Change = (event) => {
    handleGradientInputChange(setGradientInput3, event.target.value);
  };
  const handleGradientInput4Change = (event) => {
    handleGradientInputChange(setGradientInput4, event.target.value);
  };
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const resetFields = () => {
    setSelectedStartCategory("");
    setSelectedEndCategory("");
    setWeightage("");
    setSelectedStartNode("");
    setSelectedEndNode("");
    setGradientInput1("");
    setGradientInput2("");
    setGradientInput3("");
    setGradientInput4("");
    setAgeData([]);
    setstartNodeCategoryError("");
    setendNodeCategoryError("");
    setstartNodeError("");
    setendNodeError("");
    setWeightageError("");
    setGradientError("");
    setResetGraph((prev) => !prev);
  };
  const isValidGradient = (value) => {
    const regex = /^\(\w+,\d+\)$/;
    return regex.test(value);
  };
  const formatGradientValues = () => {
    const gradientValues = [
      gradientInput1,
      gradientInput2,
      gradientInput3,
      gradientInput4,
    ]
      .filter((value) => value !== "")
      .map((value) => {
        const [key, val] = value.split(",");
        return `${key},${val}`;
      });
    return `[${gradientValues.join(",")}]`;
  };
  const handleSubmit = async () => {
    let hasError = false;
    if (!selectedStartCategory) {
      setstartNodeCategoryError("Please select a start node category.");
      hasError = true;
    }
    if (!selectedEndCategory) {
      setendNodeCategoryError("Please select an end node category.");
      hasError = true;
    }
    if (!selectedStartNode) {
      setstartNodeError("Please select a start node.");
      hasError = true;
    }
    if (!selectedEndNode) {
      setendNodeError("Please select an end node.");
      hasError = true;
    }
    if (!weightage) {
      setWeightageError("Please enter a weightage.");
      hasError = true;
    }
    const gradientValues = [
      gradientInput1,
      gradientInput2,
      gradientInput3,
      gradientInput4,
    ];
    if (
      (selectedStartCategory === "DiagnosticReading" ||
        selectedEndCategory === "DiagnosticReading") &&
      gradientValues.some((value) => value && !isValidGradient(value))
    ) {
      setGradientError("Gradient values must be in the format (word,number).");
      hasError = true;
    } else {
      setGradientError("");
    }

    if (!hasError) {
      const gradient = formatGradientValues();
      const linkData = {
        startNodeCategory: selectedStartCategory,
        endNodeCategory: selectedEndCategory,
        startNode: selectedStartNode,
        endNode: selectedEndNode,
        properties: {
          Weightage: weightage,
          ageVsImportance: ageData,
          gradient: gradient,
        },
      };
      try {
        let result;
        if (isEditing) {
          result = await updateLink(
            linkData.startNodeCategory,
            linkData.endNodeCategory,
            linkData.startNode,
            linkData.endNode,
            linkData.properties
          );
          navigate("/successpage", {
            state: {
              successTitle: "Successfully Updated!",
              successContent: "Your changes have been saved successfully.",
            },
          });
        } else {
          result = await createLink(
            linkData.startNodeCategory,
            linkData.endNodeCategory,
            linkData.startNode,
            linkData.endNode,
            linkData.properties
          );
          setIsModalOpen(true);
        }

        resetFields();
      } catch (error) {
        console.error("Error creating link:", error);
      }
    }
  };
  return (
    <div>
      <TitleBar />
      <Toolbar sx={{ mt: "6rem", mx: "3rem", textDecoration: "none" }}>
        <Typography
          component={Link}
          to="/homepage"
          sx={{
            textDecoration: "none",
            color: "inherit",
            fontSize: 16,
            fontWeight: 400,
          }}
        >
          Home
        </Typography>
        <ArrowForwardIosRoundedIcon sx={{ fontSize: 16, ml: 0.5, mr: 0.5 }} />
        <Typography
          component={Link}
          to="/createlink"
          sx={{
            textDecoration: "none",
            color: "inherit",
            fontSize: 16,
            fontWeight: 400,
          }}
        >
          Create Clinical Relationship
        </Typography>
      </Toolbar>
      <Grid sx={{ pt: "1rem", px: "4.25rem" }}>
        <Grid item xs={12}>
          <Box
            border={1}
            borderColor="rgba(153, 153, 153, 0.5)"
            borderRadius={0}
            bgcolor="background.paper"
            p={2}
            sx={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              minHeight: "50px",
            }}
          >
            <Grid sx={{ pt: "1.5rem", px: "4.25rem" }}>
              <Grid
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", md: "row" },
                }}
              >
                <Grid
                  item
                  xs={12}
                  md={6}
                  sx={{ mr: "1.125rem", width: "100%" }}
                >
                  <TextLabel fontWeight={600} fontSize="18px">
                    Select Category for Start Node
                  </TextLabel>
                  <Dropdown
                    options={categories}
                    value={selectedStartCategory}
                    onChange={handleStartNodeChange}
                    disabled={isEditing}
                    placeholder="Select Category for Start Node"
                    mb={startNodeCategoryError ? "0rem" : "1.875rem"}
                  />
                  {startNodeCategoryError && (
                    <ErrorMessage message={startNodeCategoryError} />
                  )}
                </Grid>
                <Grid item xs={12} md={6} sx={{ width: "100%" }}>
                  <TextLabel fontWeight={600} fontSize="18px">
                    Select Category for End Node
                  </TextLabel>
                  <Dropdown
                    options={filteredEndNodeOptions}
                    value={selectedEndCategory}
                    disabled={isEditing}
                    onChange={handleEndNodeChange}
                    placeholder="Select Category for End Node"
                    mb={endNodeCategoryError ? "0rem" : "1.875rem"}
                  />
                  {endNodeCategoryError && (
                    <ErrorMessage message={endNodeCategoryError} />
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid sx={{ pl: "4.25rem", pr: "4.25rem" }}>
              <Grid
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", md: "row" },
                }}
              >
                <Grid
                  item
                  xs={12}
                  md={6}
                  sx={{ mr: "1.125rem", width: "100%" }}
                >
                  <TextLabel fontWeight={600} fontSize="18px">
                    Start Node
                  </TextLabel>
                  <Dropdown
                    options={startNames}
                    value={selectedStartNode}
                    onChange={handleStartNodeSelection}
                    placeholder="Select Start Node"
                    disabled={!selectedStartCategory || isEditing}
                    mb={startNodeError ? "0rem" : "1.875rem"}
                  />
                  {startNodeError && <ErrorMessage message={startNodeError} />}
                </Grid>
                <Grid item xs={12} md={6} sx={{ width: "100%" }}>
                  <TextLabel fontWeight={600} fontSize="18px">
                    End Node
                  </TextLabel>
                  <Dropdown
                    options={endNames}
                    value={selectedEndNode}
                    onChange={handleEndNodeSelection}
                    placeholder="Select End Node"
                    disabled={!selectedEndCategory || isEditing}
                    mb={endNodeError ? "0rem" : "1.875rem"}
                  />
                  {endNodeError && <ErrorMessage message={endNodeError} />}
                </Grid>
              </Grid>
              <Grid xs={12}>
                {linkExistsError && <ErrorMessage message={linkExistsError} />}
              </Grid>
            </Grid>
            <Grid item xs={12} sx={{ pl: "4.25rem", pr: "4.25rem" }}>
              <TextLabel fontWeight={600} fontSize="18px">
                Weightage
              </TextLabel>
              <TextInputField
                fullWidth
                height="49px"
                value={weightage}
                onChange={handleWeightageChange}
                sx={{
                  mb: weightageError ? "0rem" : "1.875rem",
                }}
                inputProps={{ maxLength: 255 }}
              />
              {weightageError && (
                <ErrorMessage message="Please type a weightage" />
              )}
            </Grid>
            {(selectedStartCategory === "DiagnosticReading" ||
              selectedEndCategory === "DiagnosticReading") && (
              <Grid container spacing={2} sx={{ pl: "4.25rem", pr: "4.25rem" }}>
                <Grid item xs={12} md={2}>
                  <Grid direction="column">
                    <TextLabel fontWeight={600} fontSize="18px">
                      Gradient Values
                    </TextLabel>
                    <TextInputField
                      value={gradientInput1}
                      onChange={handleGradientInput1Change}
                      fullWidth
                      sx={{ outline: "none", mb: "1.875rem" }}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} md={2} sx={{ mt: "1rem" }}>
                  <Grid direction="column">
                    <TextInputField
                      fullWidth
                      value={gradientInput2}
                      onChange={handleGradientInput2Change}
                      sx={{ outline: "none", mb: "1.875rem" }}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} md={2} sx={{ mt: "1rem" }}>
                  <Grid direction="column">
                    <TextInputField
                      value={gradientInput3}
                      onChange={handleGradientInput3Change}
                      fullWidth
                      sx={{ outline: "none", mb: "1.875rem" }}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} md={2} sx={{ mt: "1rem" }}>
                  <Grid direction="column">
                    <TextInputField
                      value={gradientInput4}
                      onChange={handleGradientInput4Change}
                      fullWidth
                      sx={{ outline: "none", mb: "1.875rem" }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            )}
            {gradientError && (
              <Grid item xs={12} sx={{ pl: "4.25rem", pr: "4.25rem" }}>
                <ErrorMessage message={gradientError} />
              </Grid>
            )}
            <Grid sx={{ pl: "4.25rem", pr: "4.25rem" }}>
              <TextLabel
                fontWeight={600}
                fontSize="18px"
                customStyles={{ mb: "1rem" }}
              >
                Check/ Enter graph values
              </TextLabel>
              <LinkGraphValuesWithSpacing>
                <LinkAgeGraphValues
                  title="Age"
                  content1="Age based on the changes in the value"
                  content2="Eg. RBC Value vs Importance"
                  onDataPointsLinkAgeSubmit={handleLinkAgeDataPointsSubmit}
                  reset={resetGraph}
                  isEditing={isEditing}
                  selectedEndNode={selectedEndNode}
                  selectedStartNode={selectedStartNode}
                >
                  <PrimaryButton>Enter Graph Value</PrimaryButton>
                </LinkAgeGraphValues>
              </LinkGraphValuesWithSpacing>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{ textAlign: "right", mb: "3.125rem", pr: "4.25rem" }}
            >
              <PrimaryButton onClick={handleSubmit}>
                {isEditing ? "Save" : "Submit"}
              </PrimaryButton>
            </Grid>
          </Box>
        </Grid>
      </Grid>
      <PopupLinkModal isOpen={isModalOpen} onClose={handleCloseModal} />
    </div>
  );
};
export default CreateLink;
