import React, { useState, useEffect } from "react";
import { Modal } from "@mui/material";
import Chart from "react-apexcharts";
import TextLabel from "../../../components/textLabel";
import TextInputField from "../../../components/inputField";
import PrimaryButton from "../../../components/primaryTextButton";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import "../../../App.css";
import { getNodeByNomenclature } from "../../../api/api";

const SeverityGraphModal = ({
  open,
  onClose,
  title,
  content1,
  onDataPointsSeveritySubmit,
  
  titleValue,
  labelValue,
  isEditing,
}) => {
  const [maleLowSeverity, setMaleLowSeverity] = useState([]);
  const [femaleLowSeverity, setFemaleLowSeverity] = useState([]);
  const [maleHighSeverity, setMaleHighSeverity] = useState([]);
  const [femaleHighSeverity, setFemaleHighSeverity] = useState([]);
  const [maleLowSeverityDataPoints, setMaleLowSeverityDataPoints] = useState(
    []
  );
  const [maleHighSeverityDataPoints, setMaleHighSeverityDataPoints] = useState(
    []
  );
  const [femaleLowSeverityDataPoints, setFemaleLowSeverityDataPoints] =
    useState([]);
  const [femaleHighSeverityDataPoints, setFemaleHighSeverityDataPoints] =
    useState([]);
  const [maleLowSeverityInputValue, setMaleLowSeverityInputValue] =
    useState("");
  const [maleHighSeverityInputValue, setMaleHighSeverityInputValue] =
    useState("");
  const [femaleLowSeverityInputValue, setFemaleLowSeverityInputValue] =
    useState("");
  const [femaleHighSeverityInputValue, setFemaleHighSeverityInputValue] =
    useState("");
  const [error, setError] = useState("");

  let xAxisMin = 0;
  let xAxisMax = 10;
  let yAxisMin = 0;
  let yAxisMax = 1;

  useEffect(() => {
    if (isEditing) {
      getEditSeverityNodeDetails();
    }
  }, [titleValue, isEditing]);

  useEffect(() => {
    if (isEditing || open) {
      handleSeverityUpdate();
    }
  }, [isEditing, open, titleValue, labelValue]);

  useEffect(() => {
    if (isEditing) {
      const maleLowSeverityString = maleLowSeverity
        ? maleLowSeverity?.map((point) => `(${point.x},${point.y})`).join(",")
        : "";
      setMaleLowSeverityInputValue(maleLowSeverityString);

      const femaleLowSeverityString = femaleLowSeverity
        ? femaleLowSeverity?.map((point) => `(${point.x},${point.y})`).join(",")
        : "";
      setFemaleLowSeverityInputValue(femaleLowSeverityString);

      const maleHighSeverityString = maleHighSeverity
        ? maleHighSeverity?.map((point) => `(${point.x},${point.y})`).join(",")
        : "";
      setMaleHighSeverityInputValue(maleHighSeverityString);

      const femaleHighSeverityString = femaleHighSeverity
        ? femaleHighSeverity
            ?.map((point) => `(${point.x},${point.y})`)
            .join(",")
        : "";
      setFemaleHighSeverityInputValue(femaleHighSeverityString);
    }
  }, [
    maleLowSeverity,
    femaleLowSeverity,
    maleHighSeverity,
    femaleHighSeverity,
    isEditing,
  ]);

  useEffect(() => {
    const maleLowPoints = parseDataPoints(maleLowSeverityInputValue);
    const maleHighPoints = parseDataPoints(maleHighSeverityInputValue);
    const femaleLowPoints = parseDataPoints(femaleLowSeverityInputValue);
    const femaleHighPoints = parseDataPoints(femaleHighSeverityInputValue);

    setMaleLowSeverityDataPoints(maleLowPoints);
    setMaleHighSeverityDataPoints(maleHighPoints);
    setFemaleLowSeverityDataPoints(femaleLowPoints);
    setFemaleHighSeverityDataPoints(femaleHighPoints);
  }, [
    maleLowSeverityInputValue,
    maleHighSeverityInputValue,
    femaleLowSeverityInputValue,
    femaleHighSeverityInputValue,
  ]);

  if (title === "Severity") {
    xAxisMin = 0;
    xAxisMax = 50;
  }

  const parseDataString = (dataString) => {
    if (typeof dataString !== "string") {
      return [];
    }

    const points = dataString.split("),(");
    return points.map((point) => {
      const [x, y] = point.replace("(", "").replace(")", "").split(",");
      return { x: parseFloat(x), y: parseFloat(y) };
    });
  };

  const getEditSeverityNodeDetails = async () => {
    try {
      const data = await getNodeByNomenclature(labelValue);
      if (data && data.length > 0) {
        const node = data[0];
        let parsedMaleLowSeverity;
        let parsedFemaleLowSeverity;
        let parsedMaleHighSeverity;
        let parsedFemaleHighSeverity;

        if (titleValue.includes("Low")) {
          parsedMaleLowSeverity = parseDataString(
            node.properties.severity.male
          );
          parsedFemaleLowSeverity = parseDataString(
            node.properties.severity.female
          );
        } else if (titleValue.includes("High")) {
          parsedMaleHighSeverity = parseDataString(
            node.properties.severity.male
          );
          parsedFemaleHighSeverity = parseDataString(
            node.properties.severity.female
          );
        }
        setMaleLowSeverity(parsedMaleLowSeverity);
        setFemaleLowSeverity(parsedFemaleLowSeverity);
        setMaleHighSeverity(parsedMaleHighSeverity);
        setFemaleHighSeverity(parsedFemaleHighSeverity);
      }
    } catch (error) {
      console.error("Error fetching node details:", error);
    }
  };

  const validateDataPoints = (dataPoints) => {
    for (const point of dataPoints) {
      if (
        point.x < xAxisMin ||
        point.x > xAxisMax ||
        point.y < yAxisMin ||
        point.y > yAxisMax
      ) {
        return false;
      }
    }
    return true;
  };

  const parseDataPoints = (input) => {
    const regex = /\((\d+(\.\d+)?),(\d+(\.\d+)?)\)/g;
    const parsedPoints = [];
    let match;
    while ((match = regex.exec(input)) !== null) {
      const x = parseFloat(match[1]);
      const y = parseFloat(match[3]);
      if (!isNaN(x) && !isNaN(y)) {
        parsedPoints.push({ x, y });
      }
    }
    return parsedPoints;
  };

  const isValidInput = (input) => {
    const regex =
      /^(\(\d+(\.\d+)?,\d+(\.\d+)?\)(,\(\d+(\.\d+)?,\d+(\.\d+)?\))*)?$/;
    return regex.test(input);
  };

  const handleSeverityUpdate = () => {
    if (
      !isValidInput(maleLowSeverityInputValue) ||
      !isValidInput(maleHighSeverityInputValue) ||
      !isValidInput(femaleLowSeverityInputValue) ||
      !isValidInput(femaleHighSeverityInputValue)
    ) {
      setError("Please enter valid data points in the format (x,y).");
      return;
    }

    const maleLowPoints = parseDataPoints(maleLowSeverityInputValue);
    const maleHighPoints = parseDataPoints(maleHighSeverityInputValue);
    const femaleLowPoints = parseDataPoints(femaleLowSeverityInputValue);
    const femaleHighPoints = parseDataPoints(femaleHighSeverityInputValue);

    setMaleLowSeverityDataPoints(maleLowPoints);
    setMaleHighSeverityDataPoints(maleHighPoints);
    setFemaleLowSeverityDataPoints(femaleLowPoints);
    setFemaleHighSeverityDataPoints(femaleHighPoints);

    onDataPointsSeveritySubmit({
      maleLowPoints,
      maleHighPoints,
      femaleLowPoints,
      femaleHighPoints,
    });

    setError("");
    const modalContent = document.querySelector(".modal-content");
    if (modalContent) {
      modalContent.scrollTop = 0;
    }
  };

  const handleSubmit = () => {
    if (
      !isValidInput(maleLowSeverityInputValue) ||
      !isValidInput(maleHighSeverityInputValue) ||
      !isValidInput(femaleLowSeverityInputValue) ||
      !isValidInput(femaleHighSeverityInputValue)
    ) {
      setError("Please enter valid data points in the format (x,y).");
      return;
    }

    const maleLowPoints = parseDataPoints(maleLowSeverityInputValue);
    const maleHighPoints = parseDataPoints(maleHighSeverityInputValue);
    const femaleLowPoints = parseDataPoints(femaleLowSeverityInputValue);
    const femaleHighPoints = parseDataPoints(femaleHighSeverityInputValue);

    if (
      !validateDataPoints(maleLowPoints) ||
      !validateDataPoints(maleHighPoints) ||
      !validateDataPoints(femaleLowPoints) ||
      !validateDataPoints(femaleHighPoints)
    ) {
      setError(
        `Data points must be within the range: x axis - (${xAxisMin}-${xAxisMax}), y axis -(${yAxisMin}-${yAxisMax}).`
      );
      return;
    }

    if (
      maleLowPoints.length === 0 &&
      maleHighPoints.length === 0 &&
      femaleLowPoints.length === 0 &&
      femaleHighPoints.length === 0
    ) {
      setError("Please enter at least one data point.");
      return;
    }
    setMaleLowSeverityDataPoints(maleLowPoints);
    setMaleHighSeverityDataPoints(maleHighPoints);
    setFemaleLowSeverityDataPoints(femaleLowPoints);
    setFemaleHighSeverityDataPoints(femaleHighPoints);

    onDataPointsSeveritySubmit({
      maleLowPoints,
      maleHighPoints,
      femaleLowPoints,
      femaleHighPoints,
    });

    setError("");
    const modalContent = document.querySelector(".modal-content");
    if (modalContent) {
      modalContent.scrollTop = 0;
    }
  };

  const chartSeverityOptions = {
    colors: ["#092B6B"],
    chart: {
      type: "line",
      height: 400,
      toolbar: { show: false },
      zoom: { enabled: false },
    },
    stroke: { curve: "straight" },
    markers: { size: 3, colors: ["#092B6B"] },
    annotations: {
      yaxis: [
        {
          y: 0,
          strokeDashArray: 0,
          borderColor: "#092B6B",
          fillColor: "#092B6B",
          borderWidth: 2,
          opacity: 0.3,
        },
      ],
      xaxis: [
        {
          x: xAxisMin,
          strokeDashArray: 0,
          borderColor: "#092B6B",
          fillColor: "#092B6B",
          borderWidth: 2,
          opacity: 0.3,
        },
      ],
    },
    grid: { show: false, borderColor: "#ff0000" },
    xaxis: { type: "numeric", min: xAxisMin, max: xAxisMax },
    yaxis: { min: 0, max: 1 },
  };

  const maleLowSeveritySeries = [
    { name: "Male Low Data Points", data: maleLowSeverityDataPoints },
    isEditing && {
      name: "Male Low Data Points",
      data: maleLowSeverityDataPoints,
    },
    isEditing &&
      Array?.isArray(maleLowSeverity) && {
        name: "Male Low Severity",
        data: maleLowSeverity,
      },
  ].filter(Boolean);

  const maleHighSeveritySeries = [
    { name: "Male High Data Points", data: maleHighSeverityDataPoints },
    isEditing && {
      name: "Male High Data Points",
      data: maleHighSeverityDataPoints,
    },
    isEditing &&
      Array?.isArray(maleHighSeverity) && {
        name: "Male High Severity",
        data: maleHighSeverity,
      },
  ].filter(Boolean);

  const femaleLowSeveritySeries = [
    { name: "Female Low Data Points", data: femaleLowSeverityDataPoints },
    isEditing && {
      name: "Female Low Data Points",
      data: femaleLowSeverityDataPoints,
    },
    isEditing &&
      Array?.isArray(femaleLowSeverity) && {
        name: "Female Low Severity",
        data: femaleLowSeverity,
      },
  ].filter(Boolean);

  const femaleHighSeveritySeries = [
    { name: "Female High Data Points", data: femaleHighSeverityDataPoints },
    isEditing && {
      name: "Female High Data Points",
      data: femaleHighSeverityDataPoints,
    },
    isEditing &&
      Array?.isArray(femaleHighSeverity) && {
        name: "Female High Severity",
        data: femaleHighSeverity,
      },
  ].filter(Boolean);

  return (
    <Modal
      open={open}
      onClose={onClose}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      className="inner-content-scroll"
    >
      <div
        style={{
          backgroundColor: "white",
          padding: "40px",
          width: "80%",
          maxWidth: "800px",
          maxHeight: "500px",
          borderRadius: "2px",
          overflowX: "hidden",
        }}
        className="inner-content-scroll modal-content"
      >
        <div
          style={{
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <CancelOutlinedIcon
            onClick={onClose}
            style={{ color: "#666666", width: 30, height: 30 }}
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "20px",
          }}
        >
          <TextLabel fontWeight={600}>
            {title} - {content1}
          </TextLabel>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          {(!isEditing || (titleValue.includes("High") && isEditing)) && (
            <div
              style={{
                width:
                  titleValue.includes("High") && isEditing ? "100%" : "44%",
                border: "1px solid #999999",
                marginBottom: "20px",
              }}
            >
              <Chart
                options={chartSeverityOptions}
                series={
                  isEditing
                    ? [
                        {
                          name: "Male High Severity DataPoints",
                          data: maleHighSeverityDataPoints,
                        },
                      ] || [
                        { name: "Male High Severity", data: maleHighSeverity },
                      ]
                    : maleHighSeveritySeries
                }
                type="line"
                width="100%"
              />
            </div>
          )}
          {(!isEditing || (titleValue.includes("Low") && isEditing)) && (
            <div
              style={{
                width: titleValue.includes("Low") && isEditing ? "100%" : "44%",
                border: "1px solid #999999",
                marginBottom: "20px",
              }}
            >
              <Chart
                options={chartSeverityOptions}
                series={
                  isEditing
                    ? [
                        {
                          name: "Male Low Severity DataPoints",
                          data: maleLowSeverityDataPoints,
                        },
                      ] || [
                        { name: "Male Low Severity", data: maleLowSeverity },
                      ]
                    : maleLowSeveritySeries
                }
                type="line"
                width="100%"
              />
            </div>
          )}

<div style={{ width: "100%" }}>
            <TextLabel
              fontSize="18px" fontWeight={600}
              customStyles={{ marginTop: "15px", marginBottom: "15px" }}
            >
              Male
            </TextLabel>
          </div>
          {(!isEditing || (titleValue.includes("High") && isEditing)) && (
            <div
              style={{
                width:
                  titleValue.includes("High") && isEditing ? "100%" : "44%",
              }}
            >
              <TextLabel fontSize="15px" fontWeight={500}>Enter Male High Values</TextLabel>
              <TextLabel fontSize="14px" fontWeight={500} color="#666666" customStyles={{ marginTop: "10px" }}>
                (1,0.2),(2,0.3)
              </TextLabel>
              <TextInputField
                placeholder="Enter Male X, Y values"
                variant="outlined"
                value={maleHighSeverityInputValue}
                onChange={(e) => setMaleHighSeverityInputValue(e.target.value)}
                fullWidth
              />
            </div>
          )}
          {(!isEditing || (titleValue.includes("Low") && isEditing)) && (
            <div
              style={{
                width: titleValue.includes("Low") && isEditing ? "100%" : "44%",
              }}
            >
              <TextLabel fontSize="15px" fontWeight={500}>Enter Male Low Values</TextLabel>
              <TextLabel fontSize="14px" fontWeight={500} color="#666666" customStyles={{ marginTop: "10px" }}>
                (1,0.2),(2,0.3)
              </TextLabel>
              <TextInputField
                placeholder="Enter Male X, Y values"
                variant="outlined"
                value={maleLowSeverityInputValue}
                onChange={(e) => setMaleLowSeverityInputValue(e.target.value)}
                fullWidth
              />
            </div>
          )}

          {(!isEditing || (titleValue.includes("High") && isEditing)) && (
            <div
              style={{
                width:
                  titleValue.includes("High") && isEditing ? "100%" : "44%",
                marginTop: "20px",
                border: "1px solid #999999",
                marginBottom: "20px",
              }}
            >
              <Chart
                options={chartSeverityOptions}
                series={
                  isEditing
                    ? [
                        {
                          name: "Female High Severity DataPoints",
                          data: femaleHighSeverityDataPoints,
                        },
                      ] || [
                        {
                          name: "Female High Severity",
                          data: femaleHighSeverity,
                        },
                      ]
                    : femaleHighSeveritySeries
                }
                type="line"
                width="100%"
              />
            </div>
          )}
          {(!isEditing || (titleValue.includes("Low") && isEditing)) && (
            <div
              style={{
                width: titleValue.includes("Low") && isEditing ? "100%" : "44%",
                marginTop: "20px",
                border: "1px solid #999999",
                marginBottom: "20px",
              }}
            >
              <Chart
                options={chartSeverityOptions}
                series={
                  isEditing
                    ? [
                        {
                          name: "Female Low Severity DataPoints",
                          data: femaleLowSeverityDataPoints,
                        },
                      ] || [
                        {
                          name: "Female Low Severity",
                          data: femaleLowSeverity,
                        },
                      ]
                    : femaleLowSeveritySeries
                }
                type="line"
                width="100%"
              />
            </div>
          )}
        

          <div style={{ width: "100%" }}>
            <TextLabel
             fontSize="18px" fontWeight={600}
              customStyles={{ marginTop: "15px", marginBottom: "15px" }}
            >
              Female
            </TextLabel>
          </div>
          {(!isEditing || (titleValue.includes("High") && isEditing)) && (
            <div
              style={{
                width:
                  titleValue.includes("High") && isEditing ? "100%" : "44%",
              }}
            >
              <TextLabel fontSize="15px" fontWeight={500}>Enter Female High Values</TextLabel>
              <TextLabel fontSize="14px" fontWeight={500} color="#666666" customStyles={{ marginTop: "10px" }}>
                (1,0.2),(2,0.3)
              </TextLabel>
              <TextInputField
                placeholder="Enter Female X, Y values"
                variant="outlined"
                value={femaleHighSeverityInputValue}
                onChange={(e) =>
                  setFemaleHighSeverityInputValue(e.target.value)
                }
                fullWidth
              />
            </div>
          )}
          {(!isEditing || (titleValue.includes("Low") && isEditing)) && (
            <div
              style={{
                width: titleValue.includes("Low") && isEditing ? "100%" : "44%",
              }}
            >
              <TextLabel fontSize="15px" fontWeight={500}>Enter Female Low Values</TextLabel>
              <TextLabel fontSize="14px" fontWeight={500} color="#666666" customStyles={{ marginTop: "10px" }}>
                (1,0.2),(2,0.3)
              </TextLabel>
              <TextInputField
                placeholder="Enter Female X, Y values"
                variant="outlined"
                value={femaleLowSeverityInputValue}
                onChange={(e) => setFemaleLowSeverityInputValue(e.target.value)}
                fullWidth
              />
            </div>
          )}
        </div>
        {error && (
          <div style={{ color: "red", marginTop: "10px" }}>{error}</div>
        )}
        <div
          style={{ display: "flex", justifyContent: "end", marginTop: "20px" }}
        >
          {isEditing ? (
            <PrimaryButton
              onClick={handleSeverityUpdate}
              variant="contained"
              color="primary"
            >
              Update
            </PrimaryButton>
          ) : (
            <PrimaryButton
              onClick={handleSubmit}
              variant="contained"
              color="primary"
            >
              Submit
            </PrimaryButton>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default SeverityGraphModal;
