import React from "react";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";

const InputFieldLabel = ({
  children,
  fontWeight,
  fontSize,
  lineHeight,
  customStyles,
  color,
}) => {
  return (
    <Box display="flex" alignItems="center">
      <Typography
        variant="body2"
        sx={{
          fontWeight: fontWeight || 700,
          fontSize: fontSize || "1.125rem",
          color: color || "#333333",
          lineHeight: lineHeight || "16px",
          ...customStyles,
        }}
      >
        {children}
      </Typography>
    </Box>
  );
};

export default InputFieldLabel;
