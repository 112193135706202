import React, { useEffect } from "react";
import { Modal as MuiModal, Box, Typography, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const ToastModal = ({ show, message, onClose }) => {
  useEffect(() => {
    if (show) {
      const timeoutId = setTimeout(() => {
        onClose();
      }, 5000);
      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [show, onClose]);

  return (
    <MuiModal
      open={show}
      onClose={onClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "10%",
          left: "50%",
          transform: "translateX(-50%)",
          width: { xs: "18.75rem", md: "35rem" },
          p: 2,
          borderRadius: 2,
          backgroundColor: "#ffffff",
          color: "#333333",
          border: "2px solid #174FB6",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Box sx={{ flexGrow: 1, px: 2 }}>
          <Typography
            id="modal-title"
            variant="h6"
            component="h2"
            sx={{ fontWeight: 600, fontSize: "1.125rem", color: "#333333" }}
          >
            {message}
          </Typography>
        </Box>
        <IconButton onClick={onClose} sx={{ color: "#333333", flexShrink: 0 }}>
          <CloseIcon />
        </IconButton>
      </Box>
    </MuiModal>
  );
};

export default ToastModal;
