import React from "react";
import { Box } from "@mui/material";
import LogoImage from "../assets/LogoImage.png";

const Logo = ({ alt }) => {
  return (
    <Box
      component="img"
      alt={alt}
      src={LogoImage}
      width="240px"
      height="35px"
    />
  );
};

export default Logo;
