import { Typography } from "@mui/material";

const ErrorMessage = ({ message, mt, mb, pt, pb }) => {
  return (
    <div style={{ marginBottom: mb, marginTop: mt }}>
      <Typography
        variant="body2"
        sx={{
          fontWeight: "500",
          fontSize: "14px",
          lineHeight: "1.25rem",
          color: "#EF4444",
          pt: pt || "1rem",
          pl: "1rem",
          pb: pb || "1rem",
        }}
      >
        {message}
      </Typography>
    </div>
  );
};

export default ErrorMessage;
