import React from "react";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";

const TextInputField = ({
  onBlur,
  value,
  placeholder,
  endAdornment,
  onChange,
  disabled,
  type,
  sx,
}) => {
  const inputStyle = {
    "& .MuiOutlinedInput-root": {
      borderRadius: 0,
      "&.Mui-focused fieldset": {
        border: "0.031rem solid #999999",
        borderRadius: 0,
        color: "#333333",
      },
      "&:hover fieldset": {
        borderRadius: 0,
        border: "0.031rem solid #999999",
        color: "#333333",
      },
    },
    input: { color: "#333333", fontSize: "16px" },
    ...sx,
  };

  return (
    <div style={{ opacity: disabled ? 0.3 : 1 }}>
      <TextField
        fullWidth
        height="49px"
        margin="normal"
        onBlur={onBlur}
        placeholder={placeholder}
        type={type}
        value={value}
        disabled={disabled}
        onChange={onChange}
        variant="outlined"
        style={{ ...sx }}
        sx={inputStyle}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">{endAdornment}</InputAdornment>
          ),
          sx: { borderColor: "#999999" },
        }}
      />
    </div>
  );
};

export default TextInputField;
