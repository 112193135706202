import React, { useState, useEffect } from "react";
import { Grid, Box } from "@mui/material";
import TextLabel from "../../../components/textLabel";
import AgeGraphModal from "./ageGraphModal";
import Chart from "react-apexcharts";
import { getNodeByNomenclature } from "../../../api/api";
import TextButtonLink from "../../../components/textButtonLink";

const AgeGraphValues = ({
  title,
  titleValue,
  labelValue,
  content1,
  content2,
  maleLowAgeValue,
  maleHighAgeValue,
  femaleLowAgeValue,
  femaleHighAgeValue,
  onDataPointsAgeSubmit,
  graphValueStyles,
  isEditing,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [dataPoints, setDataPoints] = useState([]);
  const [submitted, setSubmitted] = useState(false);
  const [maleLowAge, setMaleLowAge] = useState([]);
  const [femaleLowAge, setFemaleLowAge] = useState([]);
  const [maleHighAge, setMaleHighAge] = useState([]);
  const [femaleHighAge, setFemaleHighAge] = useState([]);

  useEffect(() => {
    if (isEditing) {
      getNodeAgeDetails();
    }
  }, [titleValue, isEditing]);

  useEffect(() => {
    if (isEditing) {
      handleGenderDataPoints({
        maleLowPoints: maleLowAge,
        maleHighPoints: maleHighAge,
        femaleLowPoints: femaleLowAge,
        femaleHighPoints: femaleHighAge,
      });
    }
  }, [isEditing, maleLowAge, maleHighAge, femaleLowAge, femaleHighAge]);

  const parseDataString = (dataString) => {
    if (typeof dataString !== "string") {
      return [];
    }
    const points = dataString.split("),(");
    return points.map((point) => {
      const [x, y] = point.replace("(", "").replace(")", "").split(",");
      return { x: parseFloat(x), y: parseFloat(y) };
    });
  };

  const getNodeAgeDetails = async () => {
    try {
      const data = await getNodeByNomenclature(labelValue);
      if (data && data.length > 0) {
        const node = data[0];
        let parsedMaleLowAge;
        let parsedFemaleLowAge;
        let parsedMaleHighAge;
        let parsedFemaleHighAge;
        if (titleValue.includes("Low")) {
          parsedMaleLowAge = parseDataString(
            node.properties.ageVsImportance.male
          );
          parsedFemaleLowAge = parseDataString(
            node.properties.ageVsImportance.female
          );
        }
        if (titleValue.includes("High")) {
          parsedMaleHighAge = parseDataString(
            node.properties.ageVsImportance.male
          );
          parsedFemaleHighAge = parseDataString(
            node.properties.ageVsImportance.female
          );
        }
        setMaleLowAge(parsedMaleLowAge);
        setFemaleLowAge(parsedFemaleLowAge);
        setMaleHighAge(parsedMaleHighAge);
        setFemaleHighAge(parsedFemaleHighAge);
      }
    } catch (error) {
      console.error("Error fetching node details:", error);
    }
  };
  const handleModalOpen = () => {
    setIsModalOpen(true);
  };
  const handleModalClose = () => {
    setIsModalOpen(false);
  };
  const handleGenderDataPoints = (points) => {
    const {
      maleLowPoints = [],
      maleHighPoints = [],
      femaleHighPoints = [],
      femaleLowPoints = [],
    } = points;

    if (
      maleLowPoints.length === 0 &&
      maleHighPoints.length === 0 &&
      femaleHighPoints.length > 0
    ) {
      setDataPoints(femaleHighPoints);
    } else if (
      maleLowPoints.length === 0 &&
      maleHighPoints.length === 0 &&
      femaleHighPoints.length === 0 &&
      femaleLowPoints.length > 0
    ) {
      setDataPoints(femaleLowPoints);
    } else {
      setDataPoints(
        maleHighPoints?.length > 0 ? maleHighPoints : maleLowPoints
      );
    }
    if (typeof onDataPointsAgeSubmit === "function") {
      onDataPointsAgeSubmit(points);
    } else {
      console.error(
        "onDataPointsAgeSubmit is not a function:",
        onDataPointsAgeSubmit
      );
    }
    setSubmitted(true);
  };
  const ageDataPoints = () => {
    if (isEditing) {
      if (maleHighAge) {
        return [{ data: maleHighAge }];
      } else if (maleLowAge) {
        return [{ data: maleLowAge }];
      } else if (femaleHighAge) {
        return [{ data: femaleHighAge }];
      } else if (femaleLowAge) {
        return [{ data: femaleLowAge }];
      }
    } else {
      return [{ data: dataPoints }];
    }
  };
  const seriesData = submitted ? [{ data: dataPoints }] : ageDataPoints();
  const chartOptions = {
    colors: ["#092B6B"],
    chart: {
      type: "line",
      height: 350,
      toolbar: { show: false },
      zoom: { enabled: false },
    },
    stroke: { curve: "straight" },
    markers: { size: 3, colors: ["#092B6B"] },
    annotations: {
      yaxis: [
        {
          y: 0,
          strokeDashArray: 0,
          borderColor: "#092B6B",
          fillColor: "#092B6B",
          borderWidth: 2,
          opacity: 0.3,
        },
      ],
      xaxis: [
        {
          x: 1,
          strokeDashArray: 0,
          borderColor: "#092B6B",
          fillColor: "#092B6B",
          borderWidth: 2,
          opacity: 0.3,
        },
      ],
    },
    grid: { show: false, borderColor: "#ff0000" },
    xaxis: { type: "numeric", min: 1, max: 75 },
    yaxis: { min: 0, max: 1 },
  };
  return (
    <>
      <Grid
        item
        xs={12}
        sm={12}
        md={4}
        sx={{
          display: "flex",
          flexDirection: "column",
          border: "0.031rem solid #999999",
          padding: "1.875rem",
          ...graphValueStyles,
        }}
      >
        {(dataPoints && submitted) || isEditing ? (
          <div>
            <TextLabel fontWeight={500} fontSize="1.5rem" color="#174FB6">
              {title}
            </TextLabel>
            <Chart
              options={chartOptions}
              series={seriesData}
              type="line"
              width="300"
              height="250"
            />

             <TextButtonLink onClick={handleModalOpen}>
                Update Graph
              </TextButtonLink>
          </div>
        ) : (
          <>
            <TextLabel fontWeight={500} fontSize="1.5rem" color="#174FB6">
              {title}
            </TextLabel>
            <TextLabel
              fontWeight={400}
              fontSize="1rem"
              customStyles={{
                marginTop: "0.938rem",
                whiteSpace: "normal",
                wordWrap: "break-word",
              }}
            >
              {content1}
            </TextLabel>
            <TextLabel
              fontWeight={400}
              fontSize="0.813rem"
              customStyles={{ marginTop: "0.938rem", marginBottom: "0.938rem" }}
              color="#999999"
            >
              {content2}
            </TextLabel>
            <TextButtonLink onClick={handleModalOpen}>
                Enter Graph Value
              </TextButtonLink>
          </>
        )}
      </Grid>
      <AgeGraphModal
        open={isModalOpen}
        onClose={handleModalClose}
        title={title}
        titleValue={titleValue}
        labelValue={labelValue}
        content1={content1}
        onDataPointsAgeSubmit={handleGenderDataPoints}
        maleLowAgeValue={maleLowAgeValue}
        maleHighAgeValue={maleHighAgeValue}
        femaleLowAgeValue={femaleLowAgeValue}
        femaleHighAgeValue={femaleHighAgeValue}
        isEditing={isEditing}
      />
    </>
  );
};
const GraphValuesWithSpacing = ({ children }) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: { lg: "flex-start" },
        width: "100%",
        gap: "0.9rem",
        flexWrap: "wrap",
      }}
    >
      {React.Children.map(children, (child, index) => (
        <Box
          key={index}
          sx={{
            width: { xs: "100%", lg: "calc(33.33% - 0.625rem)" },
            marginBottom: { xs: "1rem", md: "3rem" },
          }}
        >
          {child}
        </Box>
      ))}
    </Box>
  );
};
export { AgeGraphValues, GraphValuesWithSpacing };
