import "./App.css";
import { Amplify } from "aws-amplify";
import SignUpForm from "./pages/signup";
import Signin from "./pages/signin";
import Conformation from "./pages/conformation";
import ForgotPassword from "./pages/forgotPassword";
import ResetPassword from "./pages/resetPassword";
import Homepage from "./pages/HomePage";
import HomePageLink from "./pages/HomePageLink";
import CreateLink from "./pages/clinicalRelationship/createLink";
import SuccessPage from "./components/SuccessPage";
import CreateNode from "./pages/clinicalComponent/createNode";
import PasswordSuccess from "./pages/resetPasswordSuccess";
import { Provider } from "react-redux";
import store from "./store/store";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import awsExports from "./aws-exports";
import Profile from "./pages/profilePage";

Amplify.configure(awsExports);

function App() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Signin />} />
          <Route path="/conformation" element={<Conformation />} />
          <Route path="/signup" element={<SignUpForm />} />
          <Route path="/forgotpassword" element={<ForgotPassword />} />
          <Route path="/resetpassword" element={<ResetPassword />} />
          <Route path="/homepage" element={<Homepage />} />
          <Route path="/homepagelink" element={<HomePageLink />} />
          <Route path="/createlink" element={<CreateLink />} />
          <Route path="/createnode" element={<CreateNode />} />
          <Route path="/successpage" element={<SuccessPage />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/resetpasswordsuccess" element={<PasswordSuccess />} />
        </Routes>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
