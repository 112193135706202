import { Auth } from "aws-amplify";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Logo from "../components/logo";
import {
  Container,
  Grid,
  Box,
  TextField,
  Typography,
  IconButton,
  InputAdornment,
} from "@mui/material";
import PrimaryButton from "../components/primaryTextButton";
import TextInputField from "../components/inputField";
import ErrorMessage from "../components/errorMessage";
import FooterLabel from "../components/footerLabel";
import TitleLabel from "../components/titleLabel";
import genaiLogo from "../assets/genaiLogo.svg";
import { Visibility, VisibilityOff } from "@mui/icons-material";

export default function ResetPassword() {
  const navigate = useNavigate();
  const [authCode, setAuthCode] = useState("");
  const [authCodeError, setAuthCodeError] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [passwordFlag, setPasswordFlag] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleCodeChange = (event) => {
    const inputCode = event.target.value;
    const numericRegex = /^[0-9]*$/;
    if (numericRegex.test(inputCode)) {
      setAuthCode(inputCode);
      setAuthCodeError("");
    }
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    setPasswordError("");
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
    setPasswordError("");
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const onSubmit = async () => {
    const passExp =
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/;
    const passwordCheck = passExp.test(password);

    if (authCode === "") {
      setAuthCodeError("Please enter the code");
    } else {
      setAuthCodeError("");
    }

    if (password === "") {
      setPasswordError("Please enter your password");
    } else if (password.length < 6) {
      setPasswordError("Password must contain 6 characters");
    } else if (!passwordCheck) {
      setPasswordError(
        "A password should contain at least one number and one special character."
      );
    } else {
      setPasswordError("");
    }

    if (confirmPassword === "") {
      setPasswordError("Please confirm your password");
    } else if (password !== confirmPassword) {
      setPasswordError("Passwords do not match");
    } else {
      setPasswordError("");
      setPasswordFlag(true);
    }

    if (passwordFlag) {
      try {
        const email = localStorage.getItem("email");

        await Auth.forgotPasswordSubmit(email, authCode, password);
        navigate("/resetpasswordsuccess");
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      sx={{ minHeight: "100vh" }}
    >
      <div style={{ marginTop: "4rem" }}>
        <Logo />
      </div>
      <TitleLabel fontWeight={600} sx={{ mt: "3rem" }}>
        Update Password
      </TitleLabel>
      <Container>
        <Typography
          variant="body2"
          sx={{
            marginBottom: "1.5rem",
            marginLeft: "0.75rem",
            marginRight: "0.75rem",
            textAlign: "center",
          }}
        >
          Enter your verification code that we sent through your email address.
        </Typography>
      </Container>

      <Container maxWidth="xs">
        <TextField
          fullWidth
          placeholder="Enter Verification Code"
          value={authCode}
          onChange={handleCodeChange}
          inputProps={{ maxLength: 6, inputMode: "numeric" }}
          sx={{
            mb: authCodeError ? 0 : 3,
            mt: 1,
            "& .MuiOutlinedInput-root": {
              "&.Mui-focused fieldset": {
                border: "0.031rem solid #999999",
              },
              "&:hover fieldset": {
                border: "0.031rem solid #999999",
              },
            },
          }}
        />
        {authCodeError && <ErrorMessage message={authCodeError} />}
        <TextInputField
          sx={{
            mt: 1,
            mb: passwordError ? 0 : 3,
          }}
          fullWidth={true}
          placeholder="New Password"
          type={showPassword ? "text" : "password"}
          value={password}
          onChange={handlePasswordChange}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                edge="end"
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          }
        />
        {passwordError && <ErrorMessage message={passwordError} />}
        <TextInputField
          sx={{
            mt: 1,
            mb: passwordError ? 0 : 3,
          }}
          fullWidth={true}
          placeholder="Confirm New Password"
          type={showConfirmPassword ? "text" : "password"}
          value={confirmPassword}
          onChange={handleConfirmPasswordChange}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowConfirmPassword}
                edge="end"
              >
                {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          }
        />
        {passwordError && <ErrorMessage message={passwordError} />}
        <PrimaryButton
          fullWidth
          type="button"
          variant="contained"
          onClick={onSubmit}
        >
          Submit
        </PrimaryButton>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          sx={{ mt: "6rem", mb: "2.5rem" }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <FooterLabel
              fontWeight={400}
              fontSize={16}
              sx={{ display: "inline" }}
            >
              Powered By
            </FooterLabel>
            <Box
              component="img"
              src={genaiLogo}
              width="200px"
              height="40px"
              sx={{ display: "inline", ml: "10px" }}
            />
          </Box>
        </Grid>
      </Container>
    </Grid>
  );
}
