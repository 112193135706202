import React from "react";
import Box from "@mui/material/Box";
import PrimaryButton from "../components/primaryTextButton";
import TitleBar from "../components/Titlebar";
import { useNavigate, useLocation } from "react-router-dom";
import SuccessIcon from "../assets/SuccessIcon.svg";
import TitleLabel from "../components/titleLabel";
import Footer from "../components/footerText";

const SuccessPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { successTitle, successContent } = location.state || {};

  const handleSubmit = () => {
    navigate("/homepage");
  };

  return (
    <>
      <TitleBar />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "90vh",
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            textAlign: "center",
          }}
        >
          <img
            src={SuccessIcon}
            alt="Success Icon"
            sx={{ color: "#F5A623", fontSize: 30 }}
          />
          <TitleLabel
            sx={{ color: "#333333", marginTop: "20px" }}
            fontSize="1.875rem"
            fontWeight={300}
          >
            {successTitle}
          </TitleLabel>
          <TitleLabel
            fontSize="1.15rem"
            fontWeight={400}
            sx={{ marginBottom: "20px", color: "#333333", marginTop: "10px" }}
          >
            {successContent}
          </TitleLabel>
        </Box>
        <PrimaryButton onClick={handleSubmit}>Continue</PrimaryButton>
      </Box>
      <Footer />
    </>
  );
};

export default SuccessPage;
