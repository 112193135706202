import React from "react";
import Button from "@mui/material/Button";

const PrimaryButton = ({
  type,
  children,
  fullWidth,
  handleclick,
  ...props
}) => {
  return (
    <Button
      fullWidth={fullWidth}
      type={type}
      variant="contained"
      sx={{
        fontWeight: 500,
        fontSize: "1.125rem",
        fontFamily:"Mulish",
        color: "#FFFFFF",
        backgroundColor: "#174FB6",
        textTransform: "capitalize",
        lineHeight: "1.375rem",
        border: "2px solid #174FB6",
        borderRadius: "0px",
        px: "1.75rem",
        py: "0.625rem",
        "&:hover": {
          backgroundColor: "#174FB6",
        },
      }}
      onClick={handleclick}
      {...props}
    >
      {children}
    </Button>
  );
};

export default PrimaryButton;
