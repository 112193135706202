import axios from "axios";
import store from "../store/store";
import { Auth } from "aws-amplify";
import jwt_decode from "jwt-decode";

let BASE_URL = process.env.REACT_APP_BASE_URL;

const createNodeEP = `${BASE_URL}/createNewNode`;
const getAllNodesEP = `${BASE_URL}/getAllNodes`;
const updateNodeEP = `${BASE_URL}/updateNode`;
const checkIfNodeExistsEP = `${BASE_URL}/checkIfNodeExists`;
const getNodeByCategoryEP = `${BASE_URL}/getNodeByCategory`;
const getNodeByNomenclatureEP = `${BASE_URL}/getNodeByNomenclature`;
const deleteNodeEP = `${BASE_URL}/deleteNode`;
const deleteNodeWithLinkEP = `${BASE_URL}/deleteNodeWithLink`;
const createLinkEP = `${BASE_URL}/createLink`;
const getLinksBetweenNodesEP = `${BASE_URL}/getLinksBetweenNodes`;
const getAllLinksEP = `${BASE_URL}/getAllLinks`;
const updateLinkEP = `${BASE_URL}/updateLink`;
const checkIfLinkExistsEP = `${BASE_URL}/checkIfLinkExists`;
const deleteLinkEP = `${BASE_URL}/deleteLink`;
const createOtherUserEP = `${BASE_URL}/createOtherUser`;
const checkUserExistenceEP = `${BASE_URL}/checkOtherUserExistByEmail`;
const getOtherUserByEmailEP = `${BASE_URL}/getOtherUserByEmail`;

export const refreshAccessToken = async (props) => {
  axios.interceptors.request.use(
    async (request) => {
      let accTok = store.getState().accessToken;
      if (
        jwt_decode(accTok)?.exp <
        Math.floor((new Date().getTime() + 5 * 60000) / 1000)
      ) {
        const currentUser = await Auth.currentAuthenticatedUser();
        const currentSession = await Auth.currentSession();
        currentUser.refreshSession(
          currentSession.refreshToken,
          (err, session) => {
            store.dispatch({
              type: "REFRESH_ACCESS_TOKEN",
              accessToken: session?.idToken?.jwtToken,
            });
          }
        );
        try {
          await Auth.currentUserInfo();
        } catch (error) {
          props?.navigate("/");
        }
      }
      return request;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
};
export const createNode = async (
  selectedCategory,
  selectedType,
  titleValue,
  labelValue,
  whatValue,
  whyValue,
  gradientValues,
  impactGradientData,
  severityGradientData,
  ageGradientData,
  maleUnitValue,
  maleHighValue,
  femaleHighValue,
  maleLowValue,
  femaleLowValue,
  lowValueImpactData,
  highValueImpactData,
  lowValueSeverityData,
  highValueSeverityData,
  lowValueAgeData,
  highValueAgeData
) => {
  let labelPrefix = labelValue.slice(0, -4);
  let labelSuffix = titleValue.includes("High") ? "HVX1" : "LVX1";
  maleHighValue = parseFloat(maleHighValue);
  femaleHighValue = parseFloat(femaleHighValue);
  maleLowValue = parseFloat(maleLowValue);
  femaleLowValue = parseFloat(femaleLowValue);
  let value = {
    male: titleValue.includes("High") ? maleHighValue : maleLowValue,
    female: titleValue.includes("High") ? femaleHighValue : femaleLowValue,
  };
  let valueString = JSON.stringify(value);
  let impact = titleValue.includes("High")
    ? highValueImpactData
    : lowValueImpactData;
  let impactString = JSON.stringify(impact);
  let severity = titleValue.includes("High")
    ? highValueSeverityData
    : lowValueSeverityData;
  let severityString = JSON.stringify(severity);
  let age = titleValue.includes("High") ? highValueAgeData : lowValueAgeData;
  let ageString = JSON.stringify(age);
  let body = {
    nodeCategory: selectedCategory,
    title: titleValue,
    nodeType: selectedType,
    what: whatValue,
    why: whyValue,
    label: labelValue,
  };
  if (selectedCategory === "DiagnosticReading") {
    if (
      gradientValues &&
      Object.values(gradientValues).some((value) => value.trim() !== "")
    ) {
      const gradientString = `[${Object.entries(gradientValues)
        .filter(([key, value]) => value.trim() !== "")
        .map(([key, value]) => `${value}`)
        .join(",")}]`;
      body.gradient = gradientString;
      body.impact = JSON.stringify(impactGradientData);
      body.severity = JSON.stringify(severityGradientData);
      body.ageVsImportance = JSON.stringify(ageGradientData);
    } else {
      body.unit = maleUnitValue;
      body.value = valueString;
      body.label = labelPrefix + labelSuffix;
      body.impact = impactString;
      body.severity = severityString;
      body.ageVsImportance = ageString;
    }
  }
  return axios.post(createNodeEP, body, { headers: store.getState().header });
};
export const getAllNodes = () => {
  return axios.get(getAllNodesEP, { headers: store.getState().header });
};

export const checkIfNodeExists = async (value, fieldName) => {
  const response = await axios.get(checkIfNodeExistsEP, {
    params: {
      value: value,
      fieldName: fieldName,
    },
    headers: store.getState().header,
  });
  return response.data;
};

export const getNodeByCategory = async (nodeCategory) => {
  const response = await axios.get(getNodeByCategoryEP, {
    params: {
      nodecategory: nodeCategory,
    },
    headers: store.getState().header,
  });
  return response.data;
};
export const getNodeByNomenclature = async (title) => {
  const response = await axios.get(getNodeByNomenclatureEP, {
    params: {
      name: title,
    },
    headers: store.getState().header,
  });
  return response.data;
};

export const deleteNode = async (category, label) => {
  try {
    const response = await axios.delete(deleteNodeEP, {
      params: {
        category: category,
        nodeName: label,
      },
      headers: store.getState().header,
    });
    if (response.data === true) {
      return true;
    } else if (response.data === false) {
      return false;
    } else {
      throw new Error("Failed to delete node");
    }
  } catch (error) {
    throw new Error("An unknown error occurred");
  }
};

export const deleteNodeWithLink = async (category, label) => {
  const response = await axios.delete(deleteNodeWithLinkEP, {
    params: {
      category: category,
      nodeName: label,
    },
    headers: store.getState().header,
  });
  return response.data;
};

export const checkIfLinkExists = async (
  selectedStartCategory,
  selectedEndCategory,
  selectedStartNode,
  selectedEndNode
) => {
  const response = await axios.get(checkIfLinkExistsEP, {
    params: {
      startNodeCategory: selectedStartCategory,
      endNodeCategory: selectedEndCategory,
      startNodeName: selectedStartNode,
      endNodeName: selectedEndNode,
      relationshipType: "HAS_RELATED",
    },
    headers: store.getState().header,
  });
  return response.data;
};

export const createLink = async (
  startNodeCategory,
  endNodeCategory,
  startNode,
  endNode,
  properties
) => {
  let race;
  const body = {
    startNodeCategory,
    endNodeCategory,
    startNode,
    endNode,
    relationshipType: "HAS_RELATED",
    race: 1,
    properties,
  };
  return axios.post(createLinkEP, body, { headers: store.getState().header });
};

export const getLinksBetweenNodes = async (
  selectedStartNode,
  selectedEndNode
) => {
  const response = await axios.get(getLinksBetweenNodesEP, {
    params: {
      startNode: selectedStartNode,
      endNode: selectedEndNode,
    },
    headers: store.getState().header,
  });
  return response.data;
};

export const getAllLinks = () => {
  return axios.get(getAllLinksEP, { headers: store.getState().header });
};

export const updateLink = async (
  startNodeCategory,
  endNodeCategory,
  startNode,
  endNode,
  properties
) => {
  let race;
  const body = {
    startNodeCategory,
    endNodeCategory,
    startNode,
    endNode,
    relationshipType: "HAS_RELATED",
    race: 1,
    properties,
  };
  return axios.put(updateLinkEP, body, { headers: store.getState().header });
};

export const updateNode = async (formData) => {
  let {
    selectedCategory,
    selectedType,
    titleValue,
    labelValue,
    whatValue,
    whyValue,
    maleUnitValue,
    maleHighValue,
    femaleHighValue,
    maleLowValue,
    femaleLowValue,
    lowValueImpactData,
    highValueImpactData,
    lowValueSeverityData,
    highValueSeverityData,
    lowValueAgeData,
    highValueAgeData,
    impactGradientData,
    severityGradientData,
    ageGradientData,
    gradientValues,
  } = formData;
  maleHighValue = parseFloat(maleHighValue);
  femaleHighValue = parseFloat(femaleHighValue);
  maleLowValue = parseFloat(maleLowValue);
  femaleLowValue = parseFloat(femaleLowValue);
  let value = {
    male: titleValue.includes("High") ? maleHighValue : maleLowValue,
    female: titleValue.includes("High") ? femaleHighValue : femaleLowValue,
  };
  let valueString = JSON.stringify(value);
  let impact = titleValue.includes("High")
    ? highValueImpactData
    : lowValueImpactData;
  let impactString = JSON.stringify(impact);
  let severity = titleValue.includes("High")
    ? highValueSeverityData
    : lowValueSeverityData;
  let severityString = JSON.stringify(severity);
  let age = titleValue.includes("High") ? highValueAgeData : lowValueAgeData;
  let ageString = JSON.stringify(age);
  let body = {
    nodeCategory: selectedCategory,
    nodeType: selectedType,
    title: titleValue,
    what: whatValue,
    why: whyValue,
    label: labelValue,
  };
  if (selectedCategory === "DiagnosticReading") {
    if (
      gradientValues &&
      Object.values(gradientValues).some((value) => value.trim() !== "")
    ) {
      let gradientString = `[${Object.entries(gradientValues)
        .filter(([key, value]) => value.trim() !== "")
        .map(([key, value]) => `${value}`)
        .join(",")}]`;
      body.gradient = gradientString;
      body.impact = JSON.stringify(impactGradientData);
      body.severity = JSON.stringify(severityGradientData);
      body.ageVsImportance = JSON.stringify(ageGradientData);
    } else {
      body.unit = maleUnitValue;
      body.value = valueString;
      body.label = labelValue;
      body.impact = impactString;
      body.severity = severityString;
      body.ageVsImportance = ageString;
    }
  }
  const response = await axios.put(updateNodeEP, body, {
    headers: store.getState().header,
  });
  return response.data;
};

export const createOtherUser = async (email, guid, groupName) => {
  const body = {  email, guid, groupName };
  try {
    const response = await axios.post(createOtherUserEP, body, {
      headers: store.getState().header, 
    });
    return response.data;
  } catch (error) {
    console.error("Error creating user:", error);
    throw error;  
  }
};

export const checkUserExistence = async (email,groupName) => {
  try {
    const response = await axios.get(checkUserExistenceEP, {
      params: { userEmail: email,
        groupName:groupName
       },
      headers: store.getState().header,
    });
    return response.data.userExistance; 
} catch (error) {
  if (error.response) {
    if (error.response.status === 404) {
      return false; 
    } 
  }
  console.error('Error checking user existence:', error);
  throw error; 
}
};


export const getOtherUserByEmail= async (email,groupName) => {
  try {
    const response = await axios.get(getOtherUserByEmailEP, {
      params: { userEmail: email,
        groupName:groupName},
      headers: store.getState().header,
    });
    return response.data;
  } catch (error) {
    console.error('Error checking user existence:', error);
    throw error; 
  }
};
export const deleteLink = async (startNode, endNode) => {
  const response = await axios.delete(deleteLinkEP, {
    params: {
      startNodeName: startNode,
      endNodeName: endNode,
      relationshipType: "HAS_RELATED",
    },
    headers: store.getState().header,
  });
  return response.data;
};
