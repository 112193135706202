import React, { useState, useEffect } from "react";
import { Box, Grid, Paper, TextField, IconButton } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import TitleBar from "../components/Titlebar";
import PrimaryButton from "../components/primaryTextButton";
import SecondaryButton from "../components/secondaryTextButton";
import TextLabel from "../components/textLabel";
import { getAllLinks, deleteLink } from "../api/api";
import editIcon from "../assets/editIcon.svg";
import "../App.css";
import deleteIcon from "../assets/deleteIcon.svg";
import loadingIcon from "../assets/loadingIcon.gif";
import DeleteModal from "../components/deleteModal";

const HomePageLink = () => {
  const navigate = useNavigate();
  const [nodes, setNodes] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [categorySortDirection, setCategorySortDirection] = useState("asc");
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [linkToDelete, setLinkToDelete] = useState(null);

  const email = useSelector((state) => state.email);
  const username = useSelector((state) => state.username);
  const dispatch = useDispatch();
  useEffect(() => {
    fetchNodes();
  }, []);

  const fetchNodes = async () => {
    setIsLoading(true);
    try {
      const response = await getAllLinks();
      setNodes(response.data.data);
    } catch (error) {
      console.error("Error fetching nodes:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleEditLink = (startNodeName, endNodeName) => {
    navigate("/createlink", {
      state: { startNodeName, endNodeName, isEditing: true },
    });
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleDeleteLink = (startNode, endNode) => {
    setLinkToDelete({ startNode, endNode });
    setIsModalOpen(true);
  };

  const confirmDeleteLink = async () => {
    if (linkToDelete) {
      try {
        await deleteLink(linkToDelete.startNode, linkToDelete.endNode);
        setIsModalOpen(false);
        setLinkToDelete(null);

        navigate("/successpage", {
          state: {
            successTitle: "Successfully Deleted!",
            successContent:
              "The clinical relationship has been deleted successfully.",
          },
        });
        fetchNodes();
      } catch (error) {
        console.error("Error deleting link:", error);
      }
    }
  };

  const filteredNodes = nodes.filter((node) => {
    const query = searchQuery.toLowerCase();
    const startNodeMatch =
      node.startNode.name.toLowerCase().includes(query) ||
      node.startNode.nodeCategory.some((category) =>
        category.toLowerCase().includes(query)
      );
    const endNodeMatch =
      node.endNode.name.toLowerCase().includes(query) ||
      node.endNode.nodeCategory.some((category) =>
        category.toLowerCase().includes(query)
      );

    return startNodeMatch || endNodeMatch;
  });

  const sortedNodes = filteredNodes.sort((a, b) => {
    const categoryA = a.startNode.nodeCategory[0].toLowerCase();
    const categoryB = b.startNode.nodeCategory[0].toLowerCase();
    if (categoryA < categoryB) {
      return categorySortDirection === "asc" ? -1 : 1;
    }
    if (categoryA > categoryB) {
      return categorySortDirection === "asc" ? 1 : -1;
    }
    return 0;
  });

  const columns = [
    {
      field: "id",
      headerName: "S.No",
      headerAlign: "left",
      align: "left",
      flex: 1,
      sortable: false,
      headerClassName: "super-app-theme--header",
      disableColumnMenu: true,
      renderCell: (params) => (
        <div style={{ marginLeft: "20px" }}>{params.value}</div>
      ),
    },
    {
      field: "startNode",
      headerName: "Start Node",
      headerAlign: "left",
      align: "left",
      sortable: false,
      flex: 1,
      headerClassName: "super-app-theme--header",
      disableColumnMenu: true,
    },
    {
      field: "endNode",
      headerName: "End Node",
      headerAlign: "left",
      align: "left",
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "edit",
      headerName: "Edit",
      headerAlign: "left",
      disableColumnMenu: true,
      sortable: false,
      flex: 1,
      renderCell: (params) => (
        <img
          src={editIcon}
          alt="Edit"
          onClick={() =>
            handleEditLink(params.row.startNode, params.row.endNode)
          }
          style={{ cursor: "pointer" }}
        />
      ),
    },
    {
      field: "delete",
      headerName: "Delete",
      headerAlign: "left",
      disableColumnMenu: true,
      sortable: false,
      flex: 1,
      width: 40,
      renderCell: (params) => (
        <img
          src={deleteIcon}
          alt="Delete"
          onClick={() =>
            handleDeleteLink(params.row.startNode, params.row.endNode)
          }
          style={{ cursor: "pointer" }}
        />
      ),
    },
  ];

  const rows = sortedNodes.map((node, index) => ({
    id: index + 1,
    startNode: node.startNode.name,
    endNode: node.endNode.name,
  }));

  const getEmailName = (email) => {
    return email.split("@")[0];
  };

  const capitalizeFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const displayName = username ? username : getEmailName(email);
  const UserName = capitalizeFirstLetter(displayName);

  return (
    <Grid container direction="column" style={{ minHeight: "100vh" }}>
      <TitleBar />
      <Grid
        container
        sx={{ pt: "7rem", pl: "4.25rem", pr: "4.25rem" }}
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item>
          <TextLabel fontWeight={400} fontSize="1.175rem">
            Hi
            <span style={{ paddingLeft: "0.525rem", fontWeight: 700 }}>
              {UserName},
            </span>
          </TextLabel>
        </Grid>
      </Grid>
      <Grid
        justifyContent={{ md: "flex-end" }}
        pr={{ xs: "4.25rem", md: "4.25rem" }}
        pl={{ xs: "4.25rem", md: 0 }}
        pt={{ xs: "1rem" }}
        pb={{ xs: "1rem", md: 0 }}
        display="flex"
        flexDirection={{ xs: "column", md: "row" }}
      >
        <Grid item sx={{ pt: "0.12rem", pr: "1.625rem", position: "relative" }}>
          <TextField
            variant="outlined"
            placeholder="Search here..."
            onChange={handleSearchChange}
            InputProps={{
              startAdornment: (
                <IconButton>
                  <SearchIcon />
                </IconButton>
              ),
            }}
            sx={{
              width: "350px",
              height: "23px",
              borderRadius: "0px",
              cursor: "text",
              fontSize: "18px",
              "& .MuiOutlinedInput-root": {
                height: "46px",
                borderRadius: 0,
                "& fieldset": {
                  borderRadius: 0,
                  border: "2px solid #999999",
                },
                "&:hover fieldset": {
                  borderColor: "#999999",
                  borderRadius: 0,
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#999999",
                  borderRadius: 0,
                },
              },
              "& .MuiOutlinedInput-input": {
                borderColor: "#999999",
                borderRadius: 0,
              },
            }}
          />
        </Grid>
        <Grid item pr={{ xs: "1.625rem" }} pt={{ xs: "1.5rem", md: "0.12rem" }}>
          <PrimaryButton onClick={() => navigate("/createnode")}>
            Create Clinical Component
          </PrimaryButton>
        </Grid>
        <Grid item pt={{ xs: "1.5rem", md: "0.12rem" }}>
          <SecondaryButton onClick={() => navigate("/createlink")}>
            Create Clinical Relationship
          </SecondaryButton>
        </Grid>
      </Grid>

      <Grid
        container
        sx={{ pt: "2rem", pl: "4.25rem", pr: "4.25rem" }}
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item>
          <TextLabel fontSize="1.25rem" fontWeight={600}>
            List of created clinical relationship
          </TextLabel>
        </Grid>
      </Grid>
      <Box sx={{ mt: "1.5rem", mb: "2.5rem", px: "4.25rem" }}>
        <div
          style={{
            maxHeight: "900px",
            overflowY: "auto",
            borderRadius: "6px",
            border: "1px solid #ccc",
          }}
          className="inner-content-scroll"
        >
          <Paper sx={{ height: 600, width: "100%" }}>
            {isLoading ? (
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                style={{ minHeight: "400px" }}
              >
                <img
                  src={loadingIcon}
                  alt="Loading"
                  style={{ width: "100px", height: "100px" }}
                />
              </Grid>
            ) : (
              <DataGrid
                rows={rows}
                columns={columns}
                pageSize={10}
                rowsPerPageOptions={[10]}
                disableSelectionOnClick
                localeText={{ noRowsLabel: "Data not found." }}
                sx={{
                  "&.MuiDataGrid-root": {
                    border: "none",
                  },
                  "& .MuiDataGrid-columnHeader": {
                    backgroundColor: "#EEEBEB",
                    color: "#333333",
                    fontWeight: 700,
                    fontSize: "18px",
                  },
                  "& ::-webkit-scrollbar": {
                    width: "6px",
                  },
                  "& ::-webkit-scrollbar-track": {
                    backgroundColor: "#f5f5f5",
                  },
                  "& ::-webkit-scrollbar-thumb": {
                    borderRadius: "10px",
                    boxShadow: "inset 0 0 6px rgba(0,0,0,.3)",
                    backgroundColor: "#f5f5f5",
                  },
                  "& .MuiDataGrid-columnHeader:focus": {
                    outline: "none !important",
                  },
                  "& .MuiDataGrid-cell:focus-within": {
                    outline: "none !important",
                  },
                  "& .MuiDataGrid-cell": {
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "left",
                    fontSize: "16px",
                    color: "#333333",
                  },
                  "& .MuiDataGrid-iconButtonContainer": {
                    visibility: "visible",
                  },
                  "& .MuiDataGrid-sortIcon": {
                    opacity: "inherit !important",
                    color: "#333333",
                    fontWeight: "bold",
                  },
                  "& .MuiDataGrid-row:hover": {
                    backgroundColor: "transparent",
                  },
                  "& .MuiDataGrid-row.Mui-selected": {
                    backgroundColor: "transparent",
                  },
                  "& .MuiDataGrid-row.Mui-hovered": {
                    backgroundColor: "transparent",
                  },
                  "& .MuiDataGrid-columnHeaderTitle": {
                    fontWeight: "bold",
                  },
                  "& .MuiDataGrid-overlay": {
                    fontSize: "16px",
                  },
                  "& .MuiDataGrid-columnHeader:focus-within, .MuiDataGrid-cell:focus-within":
                    {
                      outline: "none",
                    },
                }}
              />
            )}
          </Paper>
        </div>
      </Box>
      <DeleteModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onDelete={confirmDeleteLink}
        modalText="Are you sure you want to delete the clinical relationship?"
      />
    </Grid>
  );
};

export default HomePageLink;
