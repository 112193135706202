import { createStore, applyMiddleware } from "redux";
import sessionReducer from "./Reducer";
import thunk from "redux-thunk";
function saveTosessionStorage(state) {
  try {
    const serialisedState = JSON.stringify(state);
    sessionStorage.setItem("persistantState", serialisedState);
  } catch (e) {
    console.warn(e);
  }
}
function loadFromsessionStorage() {
  try {
    const serialisedState = sessionStorage.getItem("persistantState");
    if (serialisedState === null) return undefined;
    return JSON.parse(serialisedState);
  } catch (e) {
    console.warn(e);
    return undefined;
  }
}
const store = createStore(
  sessionReducer,
  loadFromsessionStorage(),
  applyMiddleware(thunk)
);
store.subscribe(() => saveTosessionStorage(store.getState()));

export default store;
