import React from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

const FooterLabel = ({ children, sx, color, fontSize, fontWeight }) => {
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      alignContent="center"
      sx={{ color: color || "#333333", ...sx }}
    >
      <Grid item>
        <Typography
          variant="body2"
          sx={{
            fontWeight: fontWeight || 700,
            fontSize: fontSize || "1.75rem",
            margin: 0,
          }}
        >
          {children}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default FooterLabel;
